import {
  useState,
  useEffect
} from 'react';
import Fuse from 'fuse.js';
import { useAddMessage, useChoice, CircularProgress } from 'react-md';
import { Modal, SearchInput, ErrorToast } from '../';
import { FUSE_OPTIONS, isNotEmpty, centsToCurrency, numberWithCommas } from '../../utils';
import { useOverviewSectionsContext, useLayoutContext, useDebounce } from '../../hooks';
import sortBy from 'lodash/sortBy';
import MobileList from '../MobileList/MobileList';
import LotsViewOptions from './LotsViewOptions';
import LotsChart from './LotsChart';
import { useLazyQuery } from '@apollo/client';
import {
  METRICS_LOT_FOCUS_QUERY,
  METRICS_LOT_FOCUS_SUBSCRIPTION
} from '../../graphql/lotOverviews.operation';

const FUSE_CONFIG = {
  ...FUSE_OPTIONS,
  keys: [
    'name',
    'lotFocus.eventNames',
    'lotFocus.lotTransactionTypes.transactionTypes.name',
    'lotFocus.lotTransactionTypes.transactionTypes.subsegment.name',
    'lotFocus.lotPricings.pricings.name',
    'lotFocus.lotPricings.pricings.subsegment.name'
  ]
};

const ExpandedView = ({ visible, setVisible }) => {
  const addMessage = useAddMessage();
  const { selectedFilters } = useOverviewSectionsContext();
  const [searchTerm, setSearchTerm] = useState('');
  const { windowSize } = useLayoutContext();
  const [secondaryColumn, setSecondaryColumn] = useState('totalRevenue');
  const [currentSegmentBy, handleSegmentByChange] = useChoice('transactionType');
  const [currentDataType, handleDataTypeChange] = useChoice('parked');
  const mobile = windowSize < 600;
  const [currentStep, setCurrentStep] = useState(mobile ? 'table' : 'charts');
  const [selectedLot, setSelectedLot] = useState();
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);

  const [
    getMetricsLotFocus,
    {
      called: lotOverviewsCalled,
      data: metricsLotFocusData,
      loading: lotOverviewsLoading,
      subscribeToMore: subscribeToMoreMetricsLotOverviews
    }
  ] = useLazyQuery(METRICS_LOT_FOCUS_QUERY, {
    variables: selectedFilters,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: () => {
      addMessage({
        twoLines: true,
        children: <ErrorToast message="Unable to get lot details" />,
        action: 'dismiss'
      });
    }
  });

  const lotOverviews = metricsLotFocusData?.metrics?.lotOverviews || [];

  useEffect(() => {
    if (visible && isNotEmpty(selectedFilters)) {
      getMetricsLotFocus({
        variables: selectedFilters
      });
    }
  }, [getMetricsLotFocus, selectedFilters, visible]);

  useEffect(() => {
    let unsubscribe;

    if (visible && isNotEmpty(selectedFilters) && lotOverviewsCalled && lotOverviewsLoading === false) {
      unsubscribe = subscribeToMoreMetricsLotOverviews({
        document: METRICS_LOT_FOCUS_SUBSCRIPTION,
        variables: selectedFilters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.metrics?.lotOverviews) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: {
              lotOverviews: subscriptionData?.data?.metrics?.lotOverviews
            }
          });
        }
      });
    }

    if (!visible && unsubscribe) {
      unsubscribe();
    }

    return () => unsubscribe && unsubscribe();
  }, [subscribeToMoreMetricsLotOverviews, selectedFilters, lotOverviewsLoading, lotOverviewsCalled, visible]);

  function handleOnClose(e) {
    e.stopPropagation();
    setCurrentStep('table');
    setSelectedLot();
    setVisible(false);
    setSearchTerm('');
  }

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  function formatGraphBarValue(value) {
    return currentDataType === 'revenue' ? centsToCurrency(value) : numberWithCommas(value);
  }

  function createLotList() {
    const fuse = new Fuse(lotOverviews, FUSE_CONFIG);
    const results = searchTerm.length > 0 ? fuse.search(searchTerm).map(result => result.item) : lotOverviews;

    return sortBy(results, 'name', 'asc');
  }

  const lots = selectedLot && mobile ? lotOverviews?.filter(({ id }) => id === selectedLot.id) : createLotList();

  const body = lots?.map((lot) => {
    const { name, totalRevenue, totalSpots } = lot;

    return {
      name: {
        displayValue: name || '',
        sortValue: name || ''
      },
      totalRevenue: {
        displayValue: centsToCurrency(totalRevenue) || '',
        sortValue: totalRevenue || 0
      },
      totalSpots: {
        displayValue: totalSpots || '',
        sortValue: totalSpots || ''
      },
      rowData: lot
    };
  });

  const head = [
    {
      id: 'name',
      label: 'Lot name',
      sortable: true
    }, {
      id: 'totalRevenue',
      label: 'Total revenue',
      sortable: true
    }, {
      id: 'totalSpots',
      label: 'Total spots',
      sortable: true
    }
  ];

  const menuItems = [
    { value: 'totalRevenue', label: 'Total revenue' },
    { value: 'totalSpots', label: 'Total spots' }
  ];

  function handleRowClick(lot) {
    setCurrentStep('charts');
    setSelectedLot(lot);
  }

  function handleGoBack() {
    setSelectedLot();
    setCurrentStep('table');
  }

  const allowGoBack = mobile && currentStep === 'charts' ? handleGoBack : null;
  const headline = mobile && selectedLot ? selectedLot?.name : 'Lots/Garages';

  return (
    <Modal
      id={currentStep === 'table' ? 'expanded-lots-view-table' : 'expanded-lots-view-modal'}
      visible={visible}
      closeModal={handleOnClose}
      headline={headline}
      goBack={allowGoBack}
      subhead="Metrics of all lot/garage assigned to selected event(s). You can click on lot/garage to view details."
    >
      <SearchInput
        placeholder="Search lots / garages"
        onChange={handleSearch}
      />
      <LotsViewOptions
        handleSegmentByChange={handleSegmentByChange}
        currentSegmentBy={currentSegmentBy}
        handleDataTypeChange={handleDataTypeChange}
        currentDataType={currentDataType}
      />
      {lotOverviewsLoading &&
        <div className="centered-progress-container">
          <CircularProgress
            className="widget-loading-spinner"
            id="transactions-list-loading-spinner"
          />
        </div>
      }
      {isNotEmpty(lots) && mobile && currentStep === 'table' && (
        <MobileList
          className="mobile-lots-table"
          columns={{ mainColumn: 'name', secondaryColumn }}
          data={{ body, head }}
          hasNextStep
          onRowClick={handleRowClick}
          menuItems={menuItems}
          setColumn={setSecondaryColumn}
        />
      )}
      {isNotEmpty(lots) && (currentStep === 'charts' || !mobile) && (
        <LotsChart
          lots={lots}
          handleSegmentByChange={handleSegmentByChange}
          currentSegmentBy={currentSegmentBy}
          formatGraphBarValue={formatGraphBarValue}
          currentDataType={currentDataType}
          handleDataTypeChange={handleDataTypeChange}
        />
      )}
    </Modal>
  );
};

export default ExpandedView;
