import { useState, useCallback, useMemo, useEffect } from 'react';
import Map, { Marker, Popup } from 'react-map-gl';
import CircleMarker from './CircleMarker';
import './Mapbox.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_KEY;

const circleColors = {
  innerCircle: {
    danger: 'rgba(255, 115, 115, 1)',
    normal: 'rgba(90, 203, 88, 1)',
    warning: 'rgba(243, 210, 94, 1)'
  },
  outerCircle: {
    danger: 'rgba(255, 115, 115, .15)',
    normal: 'rgba(90, 203, 88, .15)',
    warning: 'rgba(243, 210, 94, .15)'
  }
};


export default function MapBox({ lots = [], selectedLot }) {
  const [viewState, setViewState] = useState();
  const [popupInfo, setPopupInfo] = useState(null);

  useEffect(() => {
    if (selectedLot?.lotFocus?.lon && selectedLot?.lotFocus?.lat) {
      setViewState({ longitude: selectedLot?.lotFocus?.lon, latitude: selectedLot?.lotFocus?.lat });
    }
  }, [selectedLot]);

  const pins = useMemo(
    () =>
      lots.map((item) => {
        const { name, id, parked, utilization, lotFocus: { lat, lon } } = item;
        const level = getLevel(utilization);

        return (
          <Marker
            key={`marker-${id}`}
            longitude={lon}
            latitude={lat}
            anchor="bottom"
            onClick={e => {
              e.originalEvent.stopPropagation();
              setPopupInfo(item);
            }}
          >
            <CircleMarker
              count={parked}
              level={level}
              text={name}
              size={24}
              innerColor={circleColors.innerCircle[level]}
              outerColor={circleColors.outerCircle[level]}
            />
          </Marker>
        );
      }),
    [lots]
  );

  function getLevel(num) {
    if (num > 90) {
      return 'danger';
    }

    if (num > 70) {
      return 'warning';
    }

    return 'normal';
  }

  const onMove = useCallback(evt => setViewState(evt.viewState), [setViewState]);

  return (
    <div className="map-container">
      <Map
        initialViewState={{
          latitude: lots[0]?.lotFocus?.lat,
          longitude: lots[0]?.lotFocus?.lon,
          zoom: 15
        }}
        {...viewState}
        onMove={onMove}
        reuseMaps
        mapStyle="mapbox://styles/mapbox/navigation-day-v1"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {pins}
        {popupInfo && (
          <Popup
            closeButton={false}
            className="map-popup"
            anchor="top"
            longitude={Number(popupInfo?.lotFocus?.lon)}
            latitude={Number(popupInfo?.lotFocus?.lat)}
            onClose={() => setPopupInfo(null)}
          >
            <div>
              <h3 className="popup-title">{popupInfo?.name}</h3>
              {popupInfo?.updated && (
                <div>Parked</div>
              )}
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
}
