import { createContext, useContext, useState } from 'react';

const CashiersReprintsContext = createContext([{}, () => { }]);

export const CashiersReprintsContextProvider = ({ children }) => {
  const [cashierId, setCashierId] = useState('');
  const [cashierName, setCashierName] = useState('');
  const [cashierReprints, setCashierReprints] = useState([]);


  // Possible current views: 'card', 'expanded' or 'cashier selected'
  const [currentView, setCurrentView] = useState('card');

  const openModal = e => {
    e.stopPropagation();
    setCurrentView('expanded');
  };

  const closeModal = () => {
    setCashierId('');
    setCashierName('');
    setCurrentView('card');
    setCashierReprints([]);
  };


  const goBack = () => {
    setCashierId('');
    setCashierName('');
    setCashierReprints([]);
    setCurrentView('expanded');
  };

  return (
    <CashiersReprintsContext.Provider
      value={{
        closeModal,
        currentView,
        setCurrentView,
        openModal,
        cashierId,
        setCashierId,
        cashierName,
        setCashierName,
        cashierReprints,
        setCashierReprints,
        goBack
      }}
    >
      {children}
    </CashiersReprintsContext.Provider>
  );
};

export const useCashiersReprintsContext = () => useContext(CashiersReprintsContext);
