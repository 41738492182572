import { DropdownMenu, MenuItem, BELOW_CENTER_ANCHOR, Tooltipped, FontIcon } from 'react-md';
import { useCashiersContext } from '../../hooks';

const CashManagementModalDropdown = () => {
  const {
    cashLogs: { cashOut, countCashTransactions, eventApproved = false } = {},
    setCurrentDialog
  } = useCashiersContext();

  const dropDownItems = [
    {
      id: 'cashout',
      disabled: cashOut || !countCashTransactions,
      tooltip: 'Cash out closes out the cashier for their current active event.',
      onClick: () => setCurrentDialog('cash out'),
      label: 'Cash out'
    },
    {
      id: 'recount',
      disabled: !cashOut || eventApproved,
      tooltip: 'Recounts are miscellaneous adjustments to the final count and can be added after the final cash out is completed.',
      onClick: () => setCurrentDialog('recount'),
      label: 'Recount'
    }
  ];

  const items = dropDownItems.map((item) => (
    <MenuItem
      key={item.id}
      disabled={item.disabled}
      onClick={item.onClick}
      rightAddon={
        <Tooltipped
          id={item.id}
          tooltip={item.tooltip}
          position="above"
        >
          <FontIcon>help_outline</FontIcon>
        </Tooltipped>
      }
    >
      {item.label}
    </MenuItem>
  ));

  return (
    <div className="cash-management-dropdown">
      <DropdownMenu
        className="button-blue-light"
        id="cash-management-dropdown-button"
        buttonChildren="New Record"
        themeType="contained"
        fixedPositionOptions={{
          width: 'equal'
        }}
        anchor={BELOW_CENTER_ANCHOR}
      >
        {items}
      </DropdownMenu>
    </div>
  );
};

export default CashManagementModalDropdown;
