import Odometer from 'react-odometerjs';
import { DirectionsCarIcon } from '../../assets/react-icons';
import { Activity, ChartCard, TopMetric, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';

const ParkedChartCard = () => {
  const { metrics = {}, setFeatureInDevelopmentModalConfig, metricsLoading } = useOverviewSectionsContext();

  return (
    <ChartCard
      className="parked__pendo"
      data-id="parked__pendo"
      loading={metricsLoading}
      type="metric"
      onClick={() => setFeatureInDevelopmentModalConfig({
        title: 'Parked',
        visible: true,
        description: 'Total cars parked/admitted by transaction type.'
      })}
    >
      {isNotEmpty(metrics?.parkedCount) ? (
        <>
          <TopMetric
            className="large"
            value={<Odometer duration={100} value={metrics?.parkedCount?.value} format="(,ddd)" />}
            label="Parked"
            icon={<DirectionsCarIcon width="25" color="#FF8354" />}
          />
          <Activity data={metrics?.parkedCount} />
        </>
      ) :
        !metricsLoading && <EmptyChart center />
      }
    </ChartCard>
  );
};

export default ParkedChartCard;
