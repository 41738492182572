import { ErrorOutlineFontIcon } from 'react-md';
import styles from './Toast.module.scss';

export const ErrorToast = ({ message }) => {
  return (
    <div className={styles.toast}>
      <ErrorOutlineFontIcon style={{ color: '#ff4f4f' }} />
      <span>{message}</span>
    </div>
  );
};

export default ErrorToast;
