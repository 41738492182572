import { useState } from 'react';
import { FontIcon, Button, TextIconSpacing, CircularProgress } from 'react-md';
import { Modal, Table, SearchInput } from '../';
import { centsToCurrency, isNotEmpty } from '../../utils';
import { useDebounce, useOverviewSectionsContext, useLayoutContext } from '../../hooks';
import SingleTransactionButton from './SingleTransactionButton';
import { RefundIcon } from '../../assets/react-icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { capitalize } from 'lodash';
import MobileList from '../MobileList/MobileList';

const ExpandedView = ({ visible, setVisible, transactions = [], events }) => {
  const [searchKeyword, setSearchKeyWord] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondaryColumn, setSecondaryColumn] = useState('time');
  const { refetchTransactions, selectedFilters } = useOverviewSectionsContext();
  const applyChange = useDebounce((nextValue) => handleSearchDebounced(nextValue), 800);
  const { windowSize } = useLayoutContext();

  async function getTransactions(value) {
    setLoading(true);

    setSearchKeyWord(value);
    await refetchTransactions({
      ...selectedFilters,
      keyword: value
    });

    setLoading(false);
  }

  async function handleSearchDebounced(value) {
    getTransactions(value);
  }

  function handleSearch(event) {
    event.stopPropagation();
    setSearchKeyWord(event.target.value);
    applyChange(event.target.value);
  }

  function getLandmarkByEventById(eventId) {
    if (eventId && events.length) {
      const event = events.find(item => item.id === eventId);

      return event.landmarkName;
    }

    return '';
  }

  function handleOnClose() {
    if (searchKeyword) {
      getTransactions('');
    }

    setVisible(false);
  }

  const possibleStates = {
    failed: {
      indicator: 'danger',
      display: <FontIcon style={{ color: '#ef5350' }}>highlight_off</FontIcon>
    },
    pending: {
      indicator: 'warning',
      display: <FontIcon style={{ color: '#fdd835' }}>error_outline</FontIcon>
    },
    successful: {
      indicator: 'success',
      display: <FontIcon style={{ color: '#00c853' }}>check_circle_outline</FontIcon>
    },
    refunded: {
      indicator: 'info',
      display: <RefundIcon color="#0c07ef" width={24} />
    },
    default: {
      indicator: 'normal',
      display: '-'
    }
  };

  const body = transactions?.map((item) => {
    const { eventId = '', status, total = 0, transactionType, transactionTime, lotName, userName, eventName, refund, passSource } = item;
    const icon = refund ? possibleStates.refunded.display : (possibleStates[status]?.display || possibleStates.default.display);
    const statusIndicator = refund ? possibleStates.refunded.indicator : (possibleStates[status]?.indicator || possibleStates.default.indicator);

    const displayTime = transactionTime ? moment(transactionTime).format('M/D/YY h:mm a') : '—';
    const timestamp = new Date(transactionTime).getTime() || 0;
    const price = centsToCurrency(total) || '—';
    const event = eventName || '—';
    const location = getLandmarkByEventById(eventId) || '—';
    const lot = lotName || '—';
    const cashier = userName || '—';
    const type = capitalize(transactionType === 'pass' ? 'prepaid' : transactionType) || '—';
    const cashierDisplay = transactionType === 'pass' ? passSource : cashier;

    return {
      status: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={statusIndicator} transaction={item}>
            {icon}
          </SingleTransactionButton>
        ),
        sortValue: statusIndicator
      },
      price: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={price} transaction={item}>
            {price}
          </SingleTransactionButton>
        ),
        sortValue: total || 0,
        icon: icon
      },
      type: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={type} transaction={item}>
            {type}
          </SingleTransactionButton>
        ),
        sortValue: transactionType || '—'
      },
      cashier: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={cashier} transaction={item}>
            {cashierDisplay}
          </SingleTransactionButton>
        ),
        sortValue: cashier
      },
      lot: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={lot} transaction={item}>
            {lot}
          </SingleTransactionButton>
        ),
        sortValue: lot
      },
      location: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={location} transaction={item}>
            {location}
          </SingleTransactionButton>
        ),
        sortValue: location
      },
      event: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={event} transaction={item}>
            {event}
          </SingleTransactionButton>
        ),
        sortValue: event
      },
      time: {
        clickable: true,
        displayValue: (
          <SingleTransactionButton title={displayTime} transaction={item}>
            {displayTime}
          </SingleTransactionButton>
        ),
        sortValue: timestamp
      }
    };
  })
    .sort((a, b) => (a.time.sortValue - b.time.sortValue ? 1 : -1));

  const head = [
    {
      id: 'status',
      label: 'Status',
      sortable: true
    },
    {
      id: 'price',
      label: 'Price',
      sortable: true
    },
    {
      id: 'type',
      label: 'Type',
      sortable: true
    },
    {
      id: 'cashier',
      label: 'Cashier',
      sortable: true
    },
    {
      id: 'lot',
      label: 'Lot',
      sortable: true
    },
    {
      id: 'location',
      label: 'Location',
      sortable: true
    },
    {
      id: 'event',
      label: 'Event',
      sortable: true
    },
    {
      id: 'time',
      label: 'Transaction time',
      sortable: true
    }
  ];

  const menuItems = [
    { label: 'Type', value: 'type' },
    { label: 'Lot', value: 'lot' },
    { label: 'Location', value: 'location' },
    { label: 'Event', value: 'event' },
    { label: 'Time', value: 'time' }
  ];

  return (
    <Modal
      id="expanded-transaction-view-modal"
      visible={visible}
      closeModal={handleOnClose}
      headline="Transactions"
      subhead="List and details of all recent transactions performed for selected event(s) or lot(s). You can click on single transaction to view the transactional level details."
    >
      <SearchInput
        placeholder="Search transactions"
        onChange={handleSearch}
        value={searchKeyword}
      />
      {!loading && searchKeyword && isEmpty(transactions) &&
        <div className="no-results-container">
          <p>No results for "{searchKeyword}"</p>
          <Button
            theme="primary"
            onClick={() => getTransactions('')}
            themeType="contained"
          >
            <TextIconSpacing icon={<FontIcon>clear</FontIcon>}>
              Clear Search
            </TextIconSpacing>
          </Button>
        </div>
      }
      {loading ? (
        <div className="centered-progress-container">
          <CircularProgress
            className="widget-loading-spinner"
            id="transactions-list-loading-spinner"
          />
        </div>
      ) : isNotEmpty(transactions) && windowSize > 800 ? (
        <Table body={body} head={head} className="transactions-card" />
      ) : isNotEmpty(transactions) && windowSize < 800 ? (
        <MobileList
          className="mobile-transactions-modal"
          columns={{ mainColumn: 'price', secondaryColumn }}
          data={{ body, head }}
          hasNextStep
          menuItems={menuItems}
          setColumn={setSecondaryColumn}
        />
      ) : null}
    </Modal>
  );
};

export default ExpandedView;
