import { EmptyChart, LineChart } from '../';
import { userTimezoneOffsetMilli, ISOtoMilli } from '../../utils';

const Activity = ({ data = {} }) => {
  const { timeSeriesData = [], value: total = 0 } = data;

  // 1. Convert ISO to epoch and subtract timezone value
  // 2. Sort by date in ascending order
  const formatTimeSeries = series =>
    series
      .map(({ key: timestamp, value }) => [ISOtoMilli(timestamp) - userTimezoneOffsetMilli, value]) // 1
      .sort((a, b) => (a[0] > b[0] ? 1 : -1)); // 2

  return (
    <>
      {timeSeriesData.length ? (
        <LineChart
          data={[{ name: 'Parked', data: formatTimeSeries(timeSeriesData), color: '#FF8287' }]}
          total={total}
        />
      ) : (
        <EmptyChart />
      )}
    </>
  );
};

export default Activity;
