import { OverviewSectionsContextProvider } from './OverviewSectionsContext';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import { FeatureInDevelopmentModal } from '../../';

const OverviewSections = ({
  metrics = {},
  cashiers = [],
  lotOverviews = [],
  redeemedPrepaid = {},
  transactions = [],
  filters = {},
  filtersLoading,
  metricsLoading,
  cashiersLoading,
  lotOverviewsLoading,
  redeemedPrepaidLoading,
  transactionsLoading,
  selectedFilters,
  refetchTransactions
}) => {
  return (
    <OverviewSectionsContextProvider
      metrics={metrics}
      cashiers={cashiers}
      lotOverviews={lotOverviews}
      redeemedPrepaid={redeemedPrepaid}
      transactions={transactions}
      filters={filters}
      transactionsLoading={transactionsLoading}
      metricsLoading={metricsLoading}
      cashiersLoading={cashiersLoading}
      lotOverviewsLoading={lotOverviewsLoading}
      redeemedPrepaidLoading={redeemedPrepaidLoading}
      filtersLoading={filtersLoading}
      selectedFilters={selectedFilters}
      refetchTransactions={refetchTransactions}
    >
      <section className="context-container">
        <Section1 />
        <Section2 />
        <Section3 />
        <FeatureInDevelopmentModal />
      </section>
    </OverviewSectionsContextProvider>
  );
};

export default OverviewSections;
