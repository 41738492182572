import './DropdownButtonList.scss';

const DropdownButtonList = ({ buttons = [], visible = false }) => {
  const className = `dropdown-button-list${visible ? ' visible' : ''}`;

  return (
    <ul className={className}>
      {buttons.map(({ action, icon, text = '' }) => {
        const key = text.replace(' ', '-').toLowerCase();

        return (
          <li className="dropdown-button-list-item" key={key}>
            <button className="dropdown-button-list-button" onClick={action} type="button">
              {icon && <span className="dropdown-button-list-icon">{icon}</span>}
              <span className="dropdown-button-list-text">{text}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownButtonList;
