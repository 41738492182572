import React from 'react';

const RefundIcon = props => (
  <svg fill="none" viewBox="0 0 17 18" {...props}>
    <g fill="currentColor" clipPath="url(#clip0)">
      <path
        d="M8.5 1.917V.815L5.28 2.627 8.5 4.438V3.334A5.667 5.667 0 112.833 9H1.416A7.083 7.083 0 108.5 1.917z"
      />
      <path
        d="M8.68 5.538h-.457v.554c-.906.057-1.77.644-1.77 1.614 0 1.043.78 1.418 1.77 1.68v1.809c-.431-.008-.94-.261-1.244-.604l-.745.685c.533.497 1.244.734 1.99.758v.587h.456v-.587c.94-.024 1.804-.603 1.804-1.736 0-1.1-.94-1.483-1.804-1.744V6.907c.39 0 .813.155 1.076.432l.685-.685c-.457-.383-1.142-.57-1.76-.578v-.538zM8.223 8.44c-.431-.123-.753-.335-.753-.783 0-.473.355-.71.753-.75V8.44zm.457 1.076c.45.146.788.358.788.84 0 .48-.313.814-.788.839v-1.68z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h17v17H0z" transform="translate(0 .5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RefundIcon;
