import { createContext, useContext, useState } from 'react';
import { ResizeListener } from 'react-md';

const LayoutContext = createContext([{}, () => { }]);

export const LayoutContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }

    return 0;
  });

  return (
    <LayoutContext.Provider
      value={{
        isLoading,
        setIsLoading,
        windowSize
      }}
    >
      <ResizeListener
        immediate
        onResize={() => setWindowSize(window.innerWidth)}
      />
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
