import React, { useEffect, useState } from 'react';

const svgStyle = {
  cursor: 'pointer',
  fill: '#d00',
  stroke: 'none',
  transition: 'all 100ms linear'
};

const svgActive = {
  transform: 'scale(2)'
};

const circleStyle = {
  stroke: '#fff',
  transition: 'all 100ms linear',
  strokeWidth: 2
};

const circleActive = {
  strokeWidth: 4
};

function CircleMarker({ size = 12, innerColor, outerColor, count }) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(true);
  }, [count]);

  useEffect(() => {
    if (active) {
      setTimeout(() => setActive(false), 500);
    }
  }, [active]);

  return (
    <svg height={size} viewBox="0 0 48 48" style={!active ? svgStyle : { ...svgStyle, ...svgActive }}>
      <circle className="active" cx={size} cy={size} r={size} fill={outerColor} />
      <circle className="active" cx={size} cy={size} r={size / 2} fill={innerColor} style={!active ? circleStyle : { ...circleStyle, ...circleActive }} />
    </svg>
  );
}

export default React.memo(CircleMarker);
