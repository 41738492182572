import Highcharts from 'highcharts';
import { Chart, HighchartsChart, LineSeries, Tooltip, HighchartsProvider, XAxis, YAxis } from 'react-jsx-highcharts';
import { userTimezoneName } from '../../utils/';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, TextIconSpacing, RefreshSVGIcon } from 'react-md';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="error-fallback" role="alert">
      <Button
        onClick={resetErrorBoundary}
        className="reset-chart-button"
        iconChildren="refresh"
      >
        <TextIconSpacing icon={<RefreshSVGIcon />}>
          Reload Chart
        </TextIconSpacing>
      </Button>
    </div>
  );
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const LineChart = ({ data: chartData = [], height = 120 }) => {
  const markerOptions = {
    fillColor: '#FFFFFF',
    lineWidth: 2,
    lineColor: null,
    symbol: 'circle'
  };

  const chartLines = () =>
    chartData.map(series => {
      const { color = '', data: seriesData = [], name = '' } = series;
      const key = `${name}-series`;

      return (
        <LineSeries key={key} name={name} color={color} marker={markerOptions} data={seriesData} lineWidth={2} />
      );
    });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart
          chart={{ height }}
          className="line-chart"
        >
          <Chart />
          <Tooltip backgroundColor="white" xDateFormat={`%a., %b %e, %l:%M%P ${userTimezoneName}`} />
          <XAxis
            type="datetime"
            gridLineWidth="1px"
            labels={{
              style: {
                color: '#666666',
                'font-size': '14px'
              }
            }}
          />
          <YAxis
            gridLineWidth="0px"
            labels={{
              enabled: false
            }}
          >
            {chartLines()}
          </YAxis>
        </HighchartsChart>
      </HighchartsProvider>
    </ErrorBoundary>
  );
};
export default LineChart;
