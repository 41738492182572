import React, { useState } from 'react';
import moment from 'moment';
import Fuse from 'fuse.js';
import { Modal, Table, SearchInput } from '../';
import { isNotEmpty, FUSE_OPTIONS } from '../../utils';
import MobileList from '../MobileList/MobileList';
import { useDebounce, useLayoutContext } from '../../hooks';

const ExpandedView = ({ visible, setVisible, data }) => {
  const { windowSize } = useLayoutContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [secondaryColumn, setSecondaryColumn] = useState('status');
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);

  function handleOnClose(e) {
    e.stopPropagation();
    setVisible(false);
  }

  function search(keyword, list) {
    const options = { ...FUSE_OPTIONS, keys: ['firstName', 'eventName', 'lotName', 'status'] };
    const fuse = new Fuse(list, options);
    const results = keyword.length > 0 ? fuse.search(keyword).map(result => result.item) : list;

    return results;
  }

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  const body = search(searchTerm, data)
    .map(item => {
      const { firstName, lastName, eventName, lotName, status, checkInTime } = item;
      const formatStatus = status === 'claimed' ? 'Checked-in' : status === 'suspended' ? 'Suspended' : 'Outstanding';
      const timestamp = moment(checkInTime).format('M/D/YY h:mm A');

      return {
        vipName: {
          displayValue: `${firstName} ${lastName}` || '',
          sortValue: firstName || '—'
        },
        event: {
          displayValue: eventName || '',
          sortValue: eventName || '-'
        },
        arrivalLot: {
          displayValue: lotName || '',
          sortValue: lotName || '—'
        },
        status: {
          displayValue: formatStatus || '',
          sortValue: formatStatus || '-'
        },
        checkInTime: {
          displayValue: timestamp === 'Invalid date' ? '' : timestamp,
          sortValue: timestamp || 0
        }
      };
    })
    .sort((a, b) => (a.vipName.sortValue.toLowerCase() > b.vipName.sortValue.toLowerCase() ? 1 : -1));

  const head = [
    {
      id: 'vipName',
      label: 'VIP name',
      sortable: true
    },
    {
      id: 'event',
      label: 'Event',
      sortable: true
    },
    {
      id: 'arrivalLot',
      label: 'Arrival lot',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true
    },
    {
      id: 'checkInTime',
      label: 'Check-in-time',
      sortable: true
    }
  ];

  const menuItems = [
    { label: 'Event', value: 'event' },
    { label: 'Arrival lot', value: 'arrivalLot' },
    { label: 'Status', value: 'status' },
    { label: 'Check-in-time', value: 'checkInTime' }
  ];

  return (
    <Modal
      id="vips-expanded-view-modal"
      visible={visible}
      closeModal={handleOnClose}
      headline="VIPs"
    >
      <SearchInput placeholder="Search VIPs" onChange={handleSearch} />
      {isNotEmpty(body) && windowSize > 700 ? (
        <Table body={body} head={head} className="cashier-card" />
      ) : isNotEmpty(body) && windowSize < 700 ? (
        <MobileList
          className="mobile-vip-modal"
          columns={{ mainColumn: 'vipName', secondaryColumn }}
          data={{ body, head }}
          menuItems={menuItems}
          setColumn={setSecondaryColumn}
        />
      ) : null}
    </Modal>
  );
};

export default ExpandedView;
