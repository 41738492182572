import { centsToCurrency, getBarColor, numberWithCommas } from '../../utils';
import cx from 'classnames';

const PricingLegend = ({ data, expanded, display }) => (
  data?.map((item, index) => {
    return (
      <div key={index}>
        <div className="content legend-name">
          <dt>
            <div className="graph-bar-details-legend" style={{ backgroundColor: getBarColor('', index).backgroundColor }} />
            {item.name}
          </dt>
          <dd>{display === 'revenue' ? centsToCurrency(item.value) : numberWithCommas(item.count)}</dd>
        </div>
        <div className={cx('content sub-legend', expanded ? 'legend-expanded' : 'legend-hidden')}>
          <dt>
            <div
              className="graph-bar-details-legend"
              style={{ backgroundColor: getBarColor('', index).backgroundColor }}
            />
            cash
          </dt>
          <dd>{display === 'revenue' ? centsToCurrency(item.cash.value) : numberWithCommas(item.cash.count)}</dd>
        </div>
        <div className={cx('content sub-legend', expanded ? 'legend-expanded' : 'legend-hidden')}>
          <dt>
            <div
              className="graph-bar-details-legend"
              style={{ backgroundColor: getBarColor('', index).backgroundColor, opacity: .5 }}
            />
            credit
          </dt>
          <dd>{display === 'revenue' ? centsToCurrency(item.credit.value) : numberWithCommas(item.credit.count)}</dd>
        </div>
        <div className={cx('content sub-legend', expanded ? 'legend-expanded' : 'legend-hidden')}>
          <dt>
            <div
              className="graph-bar-details-legend"
              style={{ backgroundColor: getBarColor('', index).backgroundColor, opacity: .25 }}
            />
            payment
          </dt>
          <dd>{display === 'revenue' ? centsToCurrency(item.payment.value) : numberWithCommas(item.payment.count)}</dd>
        </div>
      </div>
    );
  })
);

export default PricingLegend;
