import { Fragment } from 'react';
import { DonutChart, EmptyChart, ChartLegend } from '../';
import { titleCase, percentFormat } from '../../utils/';
import _ from 'lodash';

const TotalTransactions = ({ data }) => {
  const dataAvailable = data.value > 0;
  const colors = {
    cash: '#8BC34A',
    credit: '#59D0B8',
    prepaid: '#27A9E4',
    exempt: '#417ED2',
    permit: '#01579B',
    payment: '#673AB7'
  };

  let formattedData = [];
  let name = '';
  let centerValue = 0;
  let legendData = [];

  if (dataAvailable) {
    const dataCopy = { ...data };

    if (dataCopy?.pass > 0) {
      dataCopy.prepaid += dataCopy.pass;
    }

    formattedData = (() => {
      let formattedArray = [];

      for (let x in colors) {
        if (dataCopy.hasOwnProperty(x)) {
          formattedArray.push({
            name: titleCase(x),
            y: dataCopy[x],
            color: colors[x],
            selected: true
          });
        };
      };

      return formattedArray;
    })();

    const highestValue = getHighestValue(formattedData);
    name = highestValue.name;
    centerValue = percentFormat((highestValue.y / data.value) * 100, 0);

    legendData = [
      { name: 'Cash', value: dataCopy.cash, color: colors.cash },
      { name: 'Credit', value: dataCopy.credit, color: colors.credit },
      { name: 'Prepaid', value: dataCopy.prepaid, color: colors.prepaid },
      { name: 'Exempt', value: dataCopy.exempt, color: colors.exempt },
      { name: 'Permit', value: dataCopy.permit, color: colors.permit },
      { name: 'Payment', value: dataCopy.payment, color: colors.payment }
    ];
  }

  function getHighestValue(list) {
    return _.maxBy(list, (o) => o.y);
  }

  return (
    <Fragment>
      {dataAvailable ?
        <>
          <DonutChart total={centerValue} subtitle={name} data={formattedData} name="Transactions" legend="off" />
          <ChartLegend data={legendData} />
        </>
        :
        <EmptyChart />
      }
    </Fragment>
  );
};

export default TotalTransactions;
