import Odometer from 'react-odometerjs';
import { TransactionIcon } from '../../assets/react-icons';
import { ChartCard, TopMetric, TotalTransactions, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';

const TransactionsChartCard = () => {
  const { metrics = {}, setFeatureInDevelopmentModalConfig, metricsLoading } = useOverviewSectionsContext();

  return (
    <ChartCard
      className="transactions__pendo"
      data-id="transactions__pendo"
      loading={metricsLoading}
      type="metric"
      onClick={() => setFeatureInDevelopmentModalConfig({
        title: 'Transactions',
        visible: true,
        description: 'Total transactions by Transaction types (Cash, Credit, Prepaids, Exempt (includes VIP Parker), Permits).'
      })}
    >
      {isNotEmpty(metrics?.transactionTypeCounts) ? (
        <>
          <TopMetric
            value={<Odometer duration={100} value={metrics?.transactionTypeCounts?.value} format="(,ddd)" />}
            label="Transactions"
            icon={<TransactionIcon width="25" color="#2d3843" />}
          />
          <TotalTransactions data={metrics?.transactionTypeCounts} />
        </>
      ) : (
        !metricsLoading && <EmptyChart center />
      )}
    </ChartCard>
  );
};

export default TransactionsChartCard;
