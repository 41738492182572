import { DonutChart, EmptyChart } from '../';
import { isNotEmpty, percentFormat } from '../../utils';

const Prepaids = ({ data = {} }) => {
  const dataAvailable = isNotEmpty(data) && data.value > 0;
  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      if (data.redeemed) {
        formattedArray.push({
          name: 'redeemed',
          y: data.redeemed,
          color: '#75c',
          selected: true
        });
      }

      if (data.sold > data.redeemed) {
        formattedArray.push({
          name: 'Not redeemed',
          y: data.unredeemed,
          color: '#eaebec',
          selected: true
        });
      }

      return formattedArray;
    })();
  }

  return dataAvailable ? (
    <DonutChart
      data={formattedData}
      name="Prepaids"
      subtitle="Redeemed"
      total={percentFormat(data.value, 0)}
    />
  ) : (
    <EmptyChart />
  );
};

export default Prepaids;
