import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import { MessageQueue, Configuration } from 'react-md';
import {
  ApolloClient,
  ApolloProvider,
  split,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import pkg from '../package.json';
import 'odometer/themes/odometer-theme-default.css';
import './index.scss';

const LIVE_SERVICE_API = `${process.env.REACT_APP_BASE_API_URL}/api/v1/live-graphql-api`;

const webSocketOptions = {
  uri: `wss://${LIVE_SERVICE_API.replace('https://', '')}/subscriptions`,
  credentials: 'include',
  options: {
    reconnect: true,
    lazy: true,
    timeout: 300000
  }
};

const wsLink = new WebSocketLink(webSocketOptions);

const httpLink = new HttpLink({
  uri: LIVE_SERVICE_API,
  credentials: 'include'
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          metrics: {
            merge: true
          }
        }
      },
      Subscription: {
        fields: {
          metrics: {
            merge: true
          }
        }
      }
    }
  })
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Configuration>
      <BrowserRouter>
        <MessageQueue id="live-snackbar">
          <App />
        </MessageQueue>
      </BrowserRouter>
    </Configuration>
  </ApolloProvider>
);

if (window) {
  window.PH_APP_VERSION = pkg.version;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
