import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useOverviewSectionsContext, useMetricListContext } from '../../hooks';
import { currencyFormat, limitListSize } from '../../utils';

const MetricListLots = ({ listLimit }) => {
  const { lotOverviews = [] } = useOverviewSectionsContext();
  const { animationTimeout } = useMetricListContext();

  return (
    <div className="metric-list">
      <table>
        <thead>
          <tr>
            <td />
            <td>Total Revenue</td>
            <td>Spots Parked</td>
          </tr>
        </thead>
        <TransitionGroup component="tbody">
          {limitListSize(lotOverviews, listLimit ?? lotOverviews.length).map((item, index) => (
            <CSSTransition key={`${item.id}-${index}`} timeout={animationTimeout} classNames="fade">
              <tr key={index} className="metric-list-row">
                <td className="metric-list-column truncate lot-name">
                  <span title={item.name} className="metric-list-cell-top">
                    {item.name}
                  </span>
                </td>
                <td className="metric-list-column total-revenue">
                  <span
                    title={currencyFormat(item.totalRevenue)}
                    className="metric-list-cell-top"
                  >
                    {currencyFormat(item.totalRevenue)}
                  </span>
                </td>
                <td className="metric-list-column  spots-parked">
                  <span
                    title={`${item.parked} / ${item.totalSpots}`}
                    className="metric-list-cell-top no-cap"
                  >
                    {`${item.parked}/${item.totalSpots}`}
                  </span>
                </td>
              </tr>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListLots;
