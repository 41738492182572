import { Radio } from 'react-md';

const LotsViewOptions = ({ currentDataType, handleDataTypeChange }) => (
  <form
    name="lot-segmentation-form"
    className="segmentation-form"
  >
    <div className="segmentation-controls">
      <span>Data type</span>
      <Radio
        id="revenue"
        name="segment-radios"
        label="Revenue"
        value="revenue"
        onChange={handleDataTypeChange}
        checked={currentDataType === 'revenue'}
      />
      <Radio
        id="transaction-count"
        name="segment-radios"
        label="Transaction Count"
        value="transactionCount"
        onChange={handleDataTypeChange}
        checked={currentDataType === 'transactionCount'}
      />
    </div>
  </form>
);

export default LotsViewOptions;
