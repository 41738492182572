import _ from 'lodash';
import { DonutChart, EmptyChart } from '../';
import { centsToCurrency, percentFormat, titleCase } from '../../utils/';

function pointFormatter() {
  return `<span style="color: ${this.color}">•</span> Revenue: <strong>${centsToCurrency(this.y)}</strong>`;
}

const tooltip = {
  useHTML: true,
  pointFormatter
};

const CreditRevenue = ({ data }) => {
  const dataAvailable = data.value > 0;

  const colors = {
    visa: '#6543bb',
    mastercard: '#bd4dab',
    amex: '#fd549f',
    discover: '#ff8187',
    other: '#ffc565'
  };

  let formattedData = [];
  let name = '';
  let centerValue = 0;

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      for (let x in colors) {
        if (data.hasOwnProperty(x)) {
          formattedArray.push({
            name: titleCase(x),
            y: data[x],
            color: colors[x],
            selected: true
          });
        }
      }

      return formattedArray;
    })();

    const highestValue = getHighestValue(formattedData);
    name = highestValue.name;
    centerValue = percentFormat((highestValue.y / getTotal(formattedData)) * 100, 0);
  }

  function getHighestValue(list) {
    return _.maxBy(list, o => o.y);
  }

  function getTotal(list) {
    return list.map(o => o.y).reduce((a, b) => a + b, 0);
  }

  return dataAvailable ? (
    <DonutChart
      total={centerValue}
      name="transactions"
      subtitle={titleCase(name)}
      data={formattedData}
      tooltip={tooltip}
    />
  ) : (
    <EmptyChart />
  );
};

export default CreditRevenue;
