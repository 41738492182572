import { Fragment } from 'react';
import { useCashiersContext } from '../../hooks';
import { camelCaseString, centsToCurrency } from '../../utils';

const CashCountDetails = () => {
  const { currentFormData = {} } = useCashiersContext();
  const {
    bagId = '',
    bankCollected = 0,
    cashFromDrops = 0,
    cashToTake = 0,
    comments = '',
    expectedCashOnHand,
    initialBank = 0,
    totalCashCollected = 0
  } = currentFormData;

  const expectedTotalCash = totalCashCollected + initialBank;

  const cashDiscrepancy = cashToTake - expectedTotalCash;

  const detailFields = [
    {
      label: 'Initial Bank Amount',
      value: centsToCurrency(initialBank)
    },
    {
      label: 'Expected Total Cash Revenue',
      value: centsToCurrency(totalCashCollected)
    },
    {
      label: 'Expected Cash on Hand',
      value: centsToCurrency(expectedCashOnHand)
    },
    {
      label: 'Cash Counted from Drops',
      value: centsToCurrency(cashFromDrops)
    },
    {
      label: 'Cash Revenue Collected Amount',
      value: centsToCurrency(cashToTake - bankCollected)
    },
    {
      label: 'Bank Collected Amount',
      value: centsToCurrency(bankCollected)
    },
    {
      label: 'Cash Discrepancy',
      status: cashDiscrepancy ? 'warning' : 'normal',
      value: centsToCurrency(cashDiscrepancy)
    },
    {
      label: 'Bag ID',
      value: bagId || 'No bag ID entered'
    },
    {
      label: 'Comments',
      value: comments
    }
  ];

  return detailFields.map(({ label, status, value }) => (
    <Fragment key={camelCaseString(label)}>
      <dt className="modal-form-review-term">{label}</dt>
      <dd className="modal-form-review-details" data-status={status || 'normal'}>
        {value}
      </dd>
    </Fragment>
  ));
};

export default CashCountDetails;
