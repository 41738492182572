import './SearchInput.scss';

const SearchInput = props => (
  <div className="search-input">
    <span className="material-icons">search</span>
    <input type="text" {...props} />
  </div>
);

export default SearchInput;
