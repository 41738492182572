import { useState } from 'react';
import { Modal } from '../';
import { useChoice, FontIcon } from 'react-md';
import ViewOptions from './ViewOptions';
import { centsToCurrency, numberWithCommas } from '../../utils';
import PricingLegend from './PricingLegend';
import OnSiteRevenueGraphBar from './OnSiteRevenueGraphBar';
import './ExpandedView.scss';

const ExpandedView = ({ data, setVisible, visible }) => {
  const { revenue = {} } = data;
  const [currentDataType, handleDataTypeChange] = useChoice('revenue');
  const [expandPricings, setExpandPricings] = useState(false);

  function handleOnClose(e) {
    e.stopPropagation();
    setVisible(false);
  }

  const displayTotal = currentDataType === 'revenue' ? centsToCurrency(revenue?.value) : numberWithCommas(revenue?.count);

  return (
    <Modal
      id="revenue-expanded-modal"
      visible={visible}
      closeModal={handleOnClose}
      headline="On-site Revenue"
      subhead="Sum of successful cash, credit and payment transactions."
    >
      <ViewOptions
        currentDataType={currentDataType}
        handleDataTypeChange={handleDataTypeChange}
      />
      <div className="totals-header">
        <dl className="data-row">
          <dt>
            {currentDataType === 'revenue' ? 'Total Revenue' : 'Successful Transactions'}
          </dt>
          <dd>
            {displayTotal}
          </dd>
        </dl>
      </div>
      <div className="pricing-details-container">
        <h2>
          On-site {currentDataType === 'revenue' ? 'Revenue' : 'Transactions'} by Pricings
          <button onClick={() => setExpandPricings(!expandPricings)}>
            <FontIcon>{expandPricings ? 'arrow_drop_down' : 'arrow_drop_up'}</FontIcon>
          </button>
        </h2>
        <OnSiteRevenueGraphBar
          data={revenue}
          display={currentDataType}
        />
        <dl className="data-row">
          <dt>Total</dt>
          <dd>{displayTotal}</dd>
        </dl>
        <PricingLegend
          data={revenue?.revenueByPricing}
          expanded={expandPricings}
          display={currentDataType}
        />
      </div>
    </Modal>
  );
};

export default ExpandedView;
