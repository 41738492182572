import { lazy, Suspense } from 'react';
import { Header } from '@parkhub/parkhub-ui';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ParkhubLogo from '../../assets/parkhub-live-logo-black.svg';
import { signOut, createFeatureAccess, getAuthUser } from '../../utils';
import { LayoutContextProvider } from './LayoutContext';
import Overview from '../Overview/Overview';

const LotsMap = lazy(() => import('../LotsMap/LotsMap'));

const Layout = () => {
  const access = createFeatureAccess();
  const authUser = getAuthUser();
  const navigate = useNavigate();

  return (
    <LayoutContextProvider>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={152}
        appMenuActive
        logoClick={() => navigate('/')}
      />
      <main id="layout">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Overview />
              </Suspense>
            }
          />
          <Route
            path="/lots"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <LotsMap />
              </Suspense>
            }
          />
        </Routes>
      </main>
      <div id="dialog-portal" />
    </LayoutContextProvider>
  );
};

export default Layout;
