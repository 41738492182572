import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function InPortal({ id, children }) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      children,
      document.querySelector(`#${id}`)
    )
  );
};

InPortal.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};

export default InPortal;
