import { useState } from 'react';
import Odometer from 'react-odometerjs';
import { Star } from '../../assets/react-icons';
import { ChartCard, TopMetric, VipsParked, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import ExpandedView from './ExpandedView';
import { isNotEmpty } from '../../utils';
import './VipsChartCard.scss';

const VipsChartCard = ({ compact }) => {
  const { metrics, metricsLoading } = useOverviewSectionsContext();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ChartCard
        loading={metricsLoading}
        onClick={() => setExpanded(true)}
        type={compact ? 'compact' : 'metric'}
        className="indicate-focus-state vips-parked__pendo"
        data-id="vips-parked__pendo"
      >
        {isNotEmpty(metrics?.vipCount) ? (
          <>
            <TopMetric
              className={compact ? 'top-metric-compact' : ''}
              value={
                <>
                  <Odometer duration={100} value={metrics.vipCount?.parked} format="(,ddd)" />
                  <span className="top-metric-prefix">/</span>
                  <Odometer duration={100} value={metrics.vipCount?.expected} format="(,ddd)" />
                </>
              }
              label="VIPs Parked"
              icon={<Star width="25" color="#FBD648" />}
            />
            {!compact && <VipsParked data={metrics?.vipCount} />}
            {metrics?.vipCount?.vips &&
              <ExpandedView
                setVisible={setExpanded}
                visible={expanded}
                data={metrics.vipCount.vips}
              />
            }
          </>
        ) : (
          !metricsLoading && <EmptyChart center />
        )}
      </ChartCard>
    </>
  );
};

export default VipsChartCard;
