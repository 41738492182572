import { Button, MoreVertSVGIcon } from 'react-md';
import './KebabButton.scss';

const KebabButton = ({ actions = {}, label }) => (
  <Button
    {...actions}
    className="kebab-button icon-button"
    buttonType="icon"
    aria-label={label}
  >
    <MoreVertSVGIcon />
  </Button>
);

export default KebabButton;
