import { useState } from 'react';
import moment from 'moment';
import Fuse from 'fuse.js';
import { Table, SearchInput } from '../';
import MobileList from '../MobileList/MobileList';
import { NavigateNextFontIcon } from 'react-md';
import { useMetricListContext, useDebounce, useCashiersContext, useLayoutContext } from '../../hooks';
import { centsToCurrency, isValidDate, isNotEmpty, FUSE_OPTIONS } from '../../utils';

const FUSE_CONFIG = {
  ...FUSE_OPTIONS,
  keys: [
    'name',
    'lotNames'
  ]
};

const ExpandedView = () => {
  const { cashiers = [] } = useMetricListContext();
  const { setCurrentDialog, setSelectedCashier } = useCashiersContext();
  const { windowSize } = useLayoutContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [secondaryColumn, setSecondaryColumn] = useState('cash');
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);

  function search(keyword, list) {
    const fuse = new Fuse(list, FUSE_CONFIG);
    const results = keyword.length > 0 ? fuse.search(keyword).map(result => result.item) : list;

    return results;
  }

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  function handleRowClick(item) {
    setCurrentDialog('cashier details');
    setSelectedCashier(item);
  }

  const body = search(searchTerm, cashiers)
    .map(item => {
      const { name, battery, sledBattery, lotNames, cashRevenue, latestTransaction, transactionCount } = item;
      const timestamp = isValidDate(latestTransaction?.transactionTime) ? moment(latestTransaction?.transactionTime).format('M/D/YY h:mm a') : '-';

      return {
        cashier: {
          displayValue: name || '—',
          sortValue: name || '—',
          truncateValue: windowSize < 960 && 12
        },
        battery: {
          displayValue: `${battery}%` || '—',
          sortValue: battery || 0
        },
        sledBattery: {
          displayValue: `${sledBattery}%` || '—',
          sortValue: sledBattery || 0
        },
        lots: {
          displayValue: lotNames.join(', ') || '—',
          sortValue: lotNames.join(', ') || '—'
        },
        cash: {
          displayValue: centsToCurrency(cashRevenue) || '—',
          sortValue: cashRevenue || 0
        },
        transactions: {
          displayValue: transactionCount.toLocaleString() || '—',
          sortValue: transactionCount || 0
        },
        latestTransaction: {
          displayValue: timestamp,
          sortValue: timestamp ?? 0,
          truncateValue: windowSize < 960 && 8
        },
        action: {
          displayValue: <NavigateNextFontIcon />,
          sortValue: ''
        },
        rowData: item,
        onRowClick: () => handleRowClick(item)
      };
    });

  const head = [
    {
      id: 'cashier',
      label: 'Cashier',
      sortable: true
    },
    {
      id: 'battery',
      label: 'iPhone battery',
      sortable: true
    },
    {
      id: 'sledBattery',
      label: 'Reader battery',
      sortable: true
    },
    {
      id: 'lots',
      label: 'Lot(s)',
      sortable: true
    },
    {
      id: 'cash',
      label: 'Total cash',
      sortable: true
    },
    {
      id: 'transactions',
      label: 'Total transactions',
      sortable: true
    },
    {
      id: 'latestTransaction',
      label: 'Time of last transaction',
      sortable: true
    },
    {
      id: 'action',
      label: '',
      sortable: false
    }
  ];

  const menuItems = [
    { label: 'Cash on hand', value: 'cash' },
    { label: 'iPhone Battery', value: 'battery' },
    { label: 'Reader Battery', value: 'sledBattery' },
    { label: 'Number of transactions', value: 'transactions' },
    { label: 'Most recent transaction', value: 'latestTransaction' },
    { label: 'Lots', value: 'lots' }
  ];

  return (
    <>
      <SearchInput placeholder="Search cashiers" onChange={handleSearch} />
      {isNotEmpty(body) && windowSize > 850 ? (
        <Table body={body} head={head} className="cashier-card" />
      ) : isNotEmpty(body) && windowSize < 850 ? (
        <MobileList
          className="mobile-cashier-modal"
          columns={{ mainColumn: 'cashier', secondaryColumn }}
          data={{ body, head }}
          hasNextStep
          onRowClick={handleRowClick}
          menuItems={menuItems}
          setColumn={setSecondaryColumn}
        />
      ) : null}
    </>
  );
};

export default ExpandedView;
