import { useState } from 'react';
import { DollarSign } from '../../assets/react-icons';
import { DropdownButtonList, KebabButton } from '../';
import { useCashiersContext } from './CashiersContext';

const CashierCardDropdown = ({ cashier }) => {
  const { setSelectedCashier, setCurrentDialog, setExpandedVisible } = useCashiersContext();
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const hideDropdown = () => setIsDropdownActive(false);

  const showDropdown = () => setIsDropdownActive(true);

  const selectCashier = () => {
    setSelectedCashier(cashier);
    setCurrentDialog('select event');
    setExpandedVisible(true);
    hideDropdown();
    document.activeElement.blur();
  };

  const toggleButtonActions = {
    onBlur: hideDropdown,
    onClick: showDropdown,
    onFocus: showDropdown
  };

  const dropdownButtons = [
    {
      action: selectCashier,
      icon: <DollarSign />,
      text: 'Manage cash'
    }
  ];

  return (
    <div className="kebab-dropdown__container">
      <KebabButton label="Show cashier options" actions={toggleButtonActions} />
      <DropdownButtonList buttons={dropdownButtons} visible={isDropdownActive} />
    </div>
  );
};

export default CashierCardDropdown;
