import { useState } from 'react';
import Odometer from 'react-odometerjs';
import { QRcodeIcon } from '../../assets/react-icons';
import { ChartCard, TopMetric, Prepaids, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';
import ExpandedView from './ExpandedView';

const PrepaidsChartCard = () => {
  const { redeemedPrepaid = {}, redeemedPrepaidLoading } = useOverviewSectionsContext();
  const [expanded, setExpanded] = useState(false);

  function handleCloseModal(e) {
    e.stopPropagation();
    setExpanded(false);
  }

  return (
    <>
      <ChartCard
        loading={redeemedPrepaidLoading}
        type="metric"
        onClick={() => setExpanded(true)}
        className="indicate-focus-state prepaids-validated__pendo"
        data-id="prepaids-validated__pendo"
      >
        {isNotEmpty(redeemedPrepaid) ? (
          <>
            <TopMetric
              label="Prepaids Validated"
              value={
                <Odometer duration={100} value={redeemedPrepaid?.redeemed} format="(,ddd)" />
              }
              icon={
                <QRcodeIcon width="25" color="#75c" />
              }
            />
            <Prepaids data={redeemedPrepaid} />
            <ExpandedView
              onClose={handleCloseModal}
              visible={expanded}
              data={redeemedPrepaid}
            />
          </>
        ) : (
          !redeemedPrepaidLoading && <EmptyChart center />
        )}
      </ChartCard>
    </>
  );
};

export default PrepaidsChartCard;
