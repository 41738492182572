import { useEffect } from 'react';

const useClickOutside = (ref, callback, condition) => {
  const handleClick = e => {
    if (e.target.hasAttribute('class') && e.target.getAttribute('class').includes('overlay')) {
      callback();
    }
  };

  useEffect(() => {
    if (condition) {
      document.addEventListener('click', handleClick);
    }

    return () => document.removeEventListener('click', handleClick);
  });
};

export default useClickOutside;
