import { useState } from 'react';
import './Tooltip.scss';

const Tooltip = ({ icon = '?', id = '', text = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const close = () => setIsExpanded(false);
  const open = () => setIsExpanded(true);
  const toggle = () => setIsExpanded(!isExpanded);

  const buttonId = `tooltip-button-${id}`;
  const textId = `tooltip-text-${id}`;

  return (
    <div className="tooltip" onMouseOut={close}>
      <button
        aria-describedby={textId}
        aria-expanded={isExpanded}
        aria-label="More information"
        className="tooltip-button"
        id={buttonId}
        onBlur={close}
        onClick={toggle}
        onFocus={open}
        onMouseOver={open}
        type="button"
      >
        {icon}
      </button>
      <p className="tooltip-text" id={textId} onBlur={close} onFocus={open} role="tooltip" tabIndex="0">
        {text}
      </p>
    </div>
  );
};

export default Tooltip;
