import { useEffect } from 'react';
import { useCashiersContext, useGetCashLogs, useLayoutContext } from '../../hooks';
import CashManagementAuditList from './CashManagementAuditList';
import CashManagementModalDropdown from './CashManagementModalDropdown';
import './CashManagementModal.scss';

const CashManagementModal = () => {
  const {
    cashLogs = {},
    setCashLogs,
    cashLogsState,
    setCashLogsState,
    selectedCashier = {},
    selectedEvent = {}
  } = useCashiersContext();
  const { setIsLoading } = useLayoutContext();
  const { id: cashierId = '' } = selectedCashier;
  const { eventId = '' } = selectedEvent;
  const { cashDrops, cashOut, countCashTransactions } = cashLogs;
  const noRecords = !cashDrops && !cashOut && cashLogsState === 'loaded';
  const dropdownVisible = !!countCashTransactions;

  const { errorResponse, fetchState, logs } = useGetCashLogs(cashierId, eventId);

  useEffect(() => (fetchState === 'fetching' ? setIsLoading(true) : setIsLoading(false)), [fetchState, setIsLoading]);

  useEffect(() => {
    setCashLogsState(fetchState);

    if (fetchState === 'success') {
      setCashLogsState('loaded');
    }
  }, [fetchState, setCashLogsState]);

  useEffect(() => {
    if (cashLogsState === 'loaded' && logs) {
      const { cashDrops: cashDropsLogs } = logs;
      const cashFromDrops = cashDropsLogs ? cashDropsLogs.reduce((a, b) => a + b.amount, 0) : 0;

      setCashLogs({ ...logs, cashFromDrops });
    }
  }, [cashLogsState, logs, setCashLogs]);

  return (
    <>
      <div className="cash-management-modal-body">
        {noRecords ? <p className="cash-management-no-data">No records to show</p> : <CashManagementAuditList />}
        {fetchState === 'error' && (
          <>
            <p>There was an error getting data for data for the cashier on this event.</p>
            <p>
              <b>
                `${errorResponse.status || 'Error'} | ${errorResponse.statusText || ''}:' '`
              </b>
              {errorResponse.message || 'Error getting data'}
            </p>
          </>
        )}
      </div>
      {dropdownVisible && <CashManagementModalDropdown />}
    </>
  );
};
export default CashManagementModal;
