import { EmptyChart, LineChart } from '../';
import { ISOtoMilli } from '../../utils';

const ReprintsChart = ({ data = [] }) => {
  const formatPrintTime = reprints => {
    return reprints
      .map(reprint => [ISOtoMilli(reprint['printTime']), 1])
      .sort((a, b) => (a[0] > b[0] ? 1 : -1));
  };

  return (
    <>
      {data.length ? (
        <LineChart
          data={[{ name: 'Reprints', data: formatPrintTime(data), color: '#FF8287' }]}
          total={data.length}
        />
      ) : (
        <EmptyChart />
      )}
    </>
  );
};

export default ReprintsChart;
