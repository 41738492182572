import { Fragment } from 'react';
import { useCashiersContext } from '../../hooks';
import { camelCaseString, centsToCurrency } from '../../utils';

const CashRecountDetails = () => {
  const { cashLogs = {}, currentFormData = {} } = useCashiersContext();
  const { totalCashCollected = 0 } = cashLogs;
  const { bagId = '', newTotal = 0, total = 0 } = currentFormData;
  const discrepancy = newTotal - totalCashCollected;

  const detailFields = [
    {
      label: 'Cash Out Amount (Excluding Bank)',
      value: centsToCurrency(total)
    },
    {
      label: 'New Total',
      value: centsToCurrency(newTotal)
    },
    {
      label: 'Difference',
      status: discrepancy ? 'warning' : 'normal',
      value: centsToCurrency(discrepancy)
    },
    {
      label: 'Bag ID',
      value: bagId
    }
  ];

  return detailFields.map(({ id, label, status, value }) => (
    <Fragment key={id || camelCaseString(label)}>
      <dt className="modal-form-review-term">{label}</dt>
      <dd className="modal-form-review-details" data-status={status || 'normal'}>
        {value}
      </dd>
    </Fragment>
  ));
};

export default CashRecountDetails;
