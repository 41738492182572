import PropTypes from 'prop-types';
import './Card.scss';
import cx from 'classnames';

const Card = ({
  children,
  className
}) => {
  return (
    <div className={cx('card', className)}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Card;
