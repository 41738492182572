import { gql } from '@apollo/client';

export const FILTERS_QUERY = gql`
  query FiltersQuery {
    filters {
      events {
        name
        id
        selected
        landmarkName
      }
      landmarks {
        name
        id
        selected
      }
      lots {
        name
        id
        selected
      }
    }
  }
`;
