import Odometer from 'react-odometerjs';
import { DataUsage } from '../../assets/react-icons';
import { ChartCard, TopMetric, Utilization, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';

const UtilizationChartCard = () => {
  const { metrics = {}, setFeatureInDevelopmentModalConfig, metricsLoading } = useOverviewSectionsContext();

  return (
    <ChartCard
      className="spots-parked__pendo"
      data-id="spots-parked__pendo"
      loading={metricsLoading}
      type="metric"
      onClick={() => setFeatureInDevelopmentModalConfig({
        title: 'Utilization',
        visible: true,
        description: 'Total number of spots parked by total available spots.'
      })}
    >
      {isNotEmpty(metrics?.utilization) ? (
        <>
          <TopMetric
            value={
              <>
                <Odometer duration={100} value={metrics?.utilization?.sold} format="(,ddd)" />
                <span className="top-metric-prefix">/</span>
                <Odometer duration={100} value={metrics?.utilization?.total} format="(,ddd)" />
              </>
            }
            label="Spots Parked"
            icon={<DataUsage width="25" color="#FF8354" />}
          />
          <Utilization data={metrics?.utilization} />
        </>
      ) : (
        !metricsLoading && <EmptyChart center />
      )}
    </ChartCard>
  );
};

export default UtilizationChartCard;
