import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment CreditBreakdownFragment on CreditBreakdown {
    amex
    discover
    mastercard
    other
    value
    visa
  }

  fragment ParkedCountFragment on ParkedCount {
    timeSeriesData {
      key
      value
    }
    value
  }

  fragment TransactionTypeCountsFragment on TransactionTypeCounts {
    cash
    credit
    exempt
    payment
    permit
    prepaid
    value
  }

  fragment UtilizationFragment on Utilization {
    available
    sold
    total
    value
  }

  fragment VipCountFragment on VIPsParked {
    expected
    parked
    value
    vips {
      checkInTime
      eventID
      eventName
      firstName
      lastName
      lotID
      lotName
      status
    }
  }

  fragment RevenueFragment on Revenue {
    count
    cash {
      timeSeriesData {
        count
        key
        value
      }
      value
    }
    credit {
    timeSeriesData {
        count
        key
        value
      }
      value
    }
    payment {
      timeSeriesData {
        count
        key
        value
      }
      value
    }
    timeSeriesData {
      count
      key
      value
    }
    revenueByPricing {
      cash {
        count
        value
      }
      credit {
        count
        value
      }
      payment {
        count
        value
      }
      count
      name
      value
    }
    value
  }

  fragment ReprintFragment on Reprint {
    amount
    appBuild
    createdAt
    deviceName
    eventId
    eventName
    id
    lotId
    lotName
    printTime
    printerName
    transactionEventId
    transactionEventName
    transactionId
    transactionTime
    updatedAt
    userId
    userName
  }
`;

export const METRICS_QUERY = gql`
  ${FRAGMENTS}

  query MetricsQuery(
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
    ) {
      creditBreakdown {
        ...CreditBreakdownFragment
      }
      parkedCount {
        ...ParkedCountFragment
      }
      revenue {
        ...RevenueFragment
      }
      transactionTypeCounts {
        ...TransactionTypeCountsFragment
      }
      utilization {
        ...UtilizationFragment
      }
      vipCount {
        ...VipCountFragment
      }
      exemptions {
        value
        exemptionCounts {
          eventName
          totalExemptionCount
          exemptionTypes {
            type
            count
          }
        }
      }
      reprintCount {
        cashiers {
          count
          id
          name
          reprints {
            ...ReprintFragment
          }
        }
        reprints {
          ...ReprintFragment
        }
        resolveTime
        updateTime
        value
      }
    }
  }
`;

export const METRICS_SUBSCRIPTION = gql`
  ${FRAGMENTS}

  subscription MetricsSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      creditBreakdown {
        ...CreditBreakdownFragment
      }
      parkedCount {
        ...ParkedCountFragment
      }
      revenue {
        ...RevenueFragment
      }
      transactionTypeCounts {
        ...TransactionTypeCountsFragment
      }
      utilization {
        ...UtilizationFragment
      }
      vipCount {
        ...VipCountFragment
      }
      exemptions {
        value
        exemptionCounts {
          eventName
          totalExemptionCount
          exemptionTypes {
            type
            count
          }
        }
      }
      reprintCount {
        cashiers {
          count
          id
          name
          reprints {
            ...ReprintFragment
          }
        }
        reprints {
          ...ReprintFragment
        }
        resolveTime
        updateTime
        value
      }
    }
  }
`;
