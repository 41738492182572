import { useState } from 'react';
import { Modal, Table, SearchInput } from '../';
import { isNotEmpty, FUSE_OPTIONS } from '../../utils';
import { useOverviewSectionsContext, useDebounce } from '../../hooks';
import Fuse from 'fuse.js';
import CashierSelect from './CashierSelect';

const ExpandedView = ({ visible, setVisible }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { metrics = {} } = useOverviewSectionsContext();
  const { reprintCount: { cashiers = [] } } = metrics;
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);

  function handleOnClose(e) {
    e.stopPropagation();

    applyChange('');
    setVisible(false);
  }

  function search(keyword, list) {
    const fuse = new Fuse(list, FUSE_OPTIONS);
    const results = keyword.length > 0 ? fuse.search(keyword).map(result => result.item) : list;

    return results;
  }

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  const body = search(searchTerm, cashiers).map(item => {
    const { name, reprints, id } = item;

    return {
      name: {
        displayValue: name || '-',
        sortValue: name || '-'
      },
      reprints: {
        displayValue: reprints.length,
        sortValue: reprints
      },
      action: {
        displayValue: <CashierSelect id={id} name={name} reprints={reprints} />,
        sortValue: ''
      }
    };
  }).sort((a, b) => (b.reprints.displayValue - a.reprints.displayValue));

  const head = [{
    id: 'name',
    label: 'Cashier',
    sortable: true
  }, {
    id: 'reprints',
    label: 'Reprints',
    sortable: true
  }, {
    id: 'action',
    label: '',
    sortable: false
  }];

  return (
    <Modal
      id="expanded-reprints-modal"
      className="reprints-modal"
      closeModal={handleOnClose}
      headline="Reprints by Cashier"
      subhead="Total number of receipts reprinted for selected event. Detailed view provides reprint info by cashiers."
      visible={visible}
    >
      <SearchInput placeholder="Search reprints" onChange={handleSearch} />
      {isNotEmpty(body) ? (
        <Table
          className="cashiers-table"
          body={body}
          head={head}
        />
      ) : (
        <p className="cashier-reprints-no-data">No records to show</p>
      )}
    </Modal>
  );
};

export default ExpandedView;
