import { useEffect, useState } from 'react';
import { useCashiersContext, useOverviewSectionsContext } from '../../hooks';
import './SelectedEventModal.scss';

const SelectEventModal = ({ visible }) => {
  const { events = [] } = useOverviewSectionsContext();
  const { selectedCashier, setSelectedEvent, setCurrentDialog } = useCashiersContext();
  const [cashierEvents, setCashierEvents] = useState([]);
  const cashierRevenue = selectedCashier?.cashRevenue || 0;

  useEffect(() => {
    if (events.length && selectedCashier) {
      const { events: eventList = [] } = selectedCashier;

      const eventNames = events.reduce((acc, { id, name }) => {
        acc[id] = name;

        return acc;
      }, {});

      const formattedEvents = eventList.map(item => ({ ...item, name: eventNames[item.eventId] }));

      setCashierEvents(formattedEvents);
    }
  }, [events, selectedCashier]);

  const selectEvent = event => {
    setSelectedEvent(event);
    setCurrentDialog('cash management');
  };

  return (
    <>
      {cashierRevenue === 0 && (
        <h2 className="no-cashier-revenue">Cashier did not collect any cash.</h2>
      )}
      <ul className="cashier-event-list">
        {cashierEvents.map(event => {
          const { eventId = '', name = '' } = event;

          return (
            <li className="cashier-event-item" key={eventId}>
              <button className="cashier-event-button" onClick={() => selectEvent(event)} type="button">
                {name}
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SelectEventModal;
