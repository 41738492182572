import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { FontIcon, CircularProgress } from 'react-md';
import { reject } from 'lodash';
import { Modal } from '../../../components';
import { RefundIcon } from '../../../assets/react-icons';
import Refunds from './Refunds';
import { useOverviewSectionsContext, useTransactionContext } from '../../../hooks';
import { centsToCurrency, dateTimeShort, isNotEmpty } from '../../../utils';
import { TRANSACTION_BY_ID } from '../../../graphql/transactions.operation';
import './SingleTransactionView.scss';

export default function SingleTransactionView({ visible }) {
  const { closeModal, setCurrentView, selectedTransaction = {} } = useTransactionContext();
  const transactionId = selectedTransaction.id;

  const {
    data,
    loading,
    error: transactionError,
    refetch: refetchTransactions
  } = useQuery(TRANSACTION_BY_ID, {
    variables: {
      transactionId
    },
    skip: !visible
  });

  return (
    <Modal
      id="single-transaction-view-modal"
      visible={visible}
      closeModal={closeModal}
      goBack={() => setCurrentView('expanded')}
      headline="Transaction Details"
    >
      {loading ?
        <SingleTransactionLoading />
        :
        !isNotEmpty(data?.transactions[0]) ?
          <div className="error-message">
            Oops! Something went wrong!
          </div>
          :
          <SingleTransaction
            transaction={data?.transactions[0]}
            transactionError={transactionError}
            refetchTransactions={refetchTransactions}
          />
      }
    </Modal>
  );
};

function SingleTransactionLoading() {
  return (
    <CircularProgress
      className="widget-loading-spinner"
      id="single-transaction-loading-spinner"
    />
  );
}

function SingleTransaction({
  transaction,
  transactionError,
  refetchTransactions
}) {
  const { events = [] } = useOverviewSectionsContext();
  const getEventById = id => events.find(item => item.id === id);

  const {
    admitted,
    cardholderName,
    confirmationCode,
    eventId,
    eventName,
    expiry,
    externalTransaction,
    failureMessage,
    lastFour,
    lotName,
    offlineFailureMessage,
    offlineStatus,
    passSource,
    permit,
    permitId,
    permitName,
    pricingName,
    qrCode,
    exemptionReason,
    status,
    total,
    refund,
    refunds,
    subtype,
    transactionTime,
    transactionType,
    userName,
    vipParkerDisplayName
  } = transaction;
  const event = getEventById(eventId) || {};
  const { landmarkName } = event;
  const _failureMessage = failureMessage || offlineFailureMessage;
  const price = total ? centsToCurrency(total) : '-';
  const time = transactionTime && dateTimeShort(new Date(transactionTime));
  const transactionTypeFormatted = transactionType && transactionType.toLowerCase();

  function formatExpiry(str) {
    return str.length === 4 ? `${str.substring(0, 2)}/${str.substring(2)}` : str;
  }

  const statusIcons = {
    successful: <FontIcon className="icon-success">check_circle_outline</FontIcon>,
    failed: <FontIcon className="icon-failed">highlight_off</FontIcon>,
    default: <FontIcon className="icon-default">error_outline</FontIcon>,
    refunded: <RefundIcon color="#0c07ef" width={24} />
  };

  const defaultItems = [
    {
      id: 'status',
      label: 'Status',
      display: refund ? 'Refunded' : status || '-',
      icon: refund ? statusIcons.refunded : statusIcons[status] || statusIcons['default']
    },
    {
      id: 'failure-message',
      label: 'Reason for failure',
      display: _failureMessage || '-',
      hidden: !_failureMessage,
      icon: <FontIcon>assessment</FontIcon>
    },
    {
      id: 'method',
      label: 'Method',
      display: transactionType === 'pass' ? 'prepaid' : transactionType || '-',
      icon: <FontIcon>payment</FontIcon>
    },
    {
      id: 'admitted',
      label: 'Admission',
      display: admitted ? 'Admitted' : 'Not admitted',
      icon: <FontIcon>directions_car</FontIcon>
    },
    {
      id: 'confirmation-code',
      label: 'Confirmation code',
      display: confirmationCode || '-',
      icon: <FontIcon>assessment</FontIcon>
    },
    {
      id: 'event',
      label: 'Event',
      display: eventName || '-',
      icon: <FontIcon>event</FontIcon>
    },
    {
      id: 'location',
      label: 'Location',
      display: landmarkName || '-',
      icon: <FontIcon>place</FontIcon>
    },
    {
      id: 'lot',
      label: 'Lot',
      display: lotName || '-',
      icon: <FontIcon>place</FontIcon>
    },
    {
      id: 'cashier',
      label: 'Cashier',
      display: userName || '-',
      icon: <FontIcon>account_circle</FontIcon>,
      hidden: transactionType === 'pass'
    },
    {
      id: 'network-status',
      label: 'Network status',
      display: offlineStatus ? 'Offline' : 'Online',
      icon: <FontIcon>smartphone</FontIcon>
    },
    {
      id: 'time',
      label: 'Time',
      display: time || '-',
      icon: <FontIcon>event</FontIcon>
    },
    {
      id: 'price',
      label: 'Price',
      display: price,
      hidden: !['cash', 'credit'].includes(transactionTypeFormatted),
      icon: <FontIcon>label</FontIcon>
    },
    {
      id: 'pricing-name',
      label: 'Pricing name',
      display: pricingName || '-',
      hidden: !['cash', 'credit'].includes(transactionTypeFormatted),
      icon: <FontIcon>label</FontIcon>
    },
    {
      id: 'source',
      label: 'Source',
      display: passSource || externalTransaction?.source || '-',
      icon: <FontIcon>assessment</FontIcon>,
      hidden: !['prepaid', 'pass'].includes(transactionType)
    }
  ];

  const transactionTypeData = {
    credit: [
      {
        id: 'card-number',
        label: 'Card number',
        display: lastFour ? `**** **** **** ${lastFour}` : '-',
        icon: <FontIcon>payment</FontIcon>
      },
      {
        id: 'cardholder',
        label: 'Cardholder',
        display: cardholderName || '-',
        icon: <FontIcon>account_circle</FontIcon>
      },
      {
        id: 'expiry',
        label: 'Expiration date',
        display: expiry ? formatExpiry(expiry) : '-',
        icon: <FontIcon>payment</FontIcon>
      }
    ],
    exempt: [
      {
        id: 'exempt-reason',
        label: 'Reason for exemption',
        display: exemptionReason || '-',
        icon: <FontIcon>assessment</FontIcon>
      },
      subtype === 'vip parker' &&
      {
        id: 'vip-parker-name',
        label: 'VIP parker name',
        display: vipParkerDisplayName || '-',
        icon: <FontIcon>account_circle</FontIcon>
      },
      permitId &&
      {
        id: 'permit-barcode',
        label: 'Permit barcode',
        display: qrCode || '-',
        icon: <FontIcon>qr_code_scanner</FontIcon>
      },
      permitId &&
      {
        id: 'permit-name',
        label: 'Permit name',
        display: permitName || '-',
        icon: <FontIcon>account_circle</FontIcon>
      },
      permitId &&
      {
        id: 'affiliation',
        label: 'Permit affiliation',
        display: permit?.affiliation || '-',
        icon: <FontIcon>account_circle</FontIcon>
      },
      permitId &&
      {
        id: 'classification',
        label: 'Permit classification',
        display: permit?.classification || '-',
        icon: <FontIcon>account_circle</FontIcon>
      },
      permitId &&
      {
        id: 'classificationSub',
        label: 'Permit sub-classification',
        display: permit?.classificationSub || '-',
        icon: <FontIcon>account_circle</FontIcon>
      }
    ],
    prepaid: [
      {
        id: 'prepaid-barcode',
        label: 'Prepaid barcode',
        display: qrCode || '-',
        icon: <FontIcon>qr_code_scanner</FontIcon>
      },
      {
        id: 'prepaid-price',
        label: 'Prepaid price',
        display: price || '-',
        icon: <FontIcon>label</FontIcon>
      },
      {
        id: 'prepaid-account',
        label: 'Account number',
        display: externalTransaction?.accountId || '-',
        icon: <FontIcon>assessment</FontIcon>
      },
      {
        id: 'prepaid-customer',
        label: 'Customer name',
        display: externalTransaction?.customerName || '-',
        icon: <FontIcon>account_circle</FontIcon>
      }
    ]
  };

  const allItems = reject(
    [defaultItems, transactionTypeData[transactionTypeFormatted] || []].flat(),
    (item) => [false, null, undefined].includes(item)
  );

  return (
    <>
      {isNotEmpty(transaction) &&
        <>
          <dl className="transaction__list">
            {allItems.map(({ id = '', label = '', display = '', hidden = false, icon }, idx) => (
              <Fragment key={id || idx}>
                <dt className="transaction__term" hidden={hidden}>
                  {icon}{label}
                </dt>
                <dd className="transaction__detail" hidden={hidden}>
                  {label === 'Status' && icon}{display}
                </dd>
              </Fragment>
            ))}
          </dl>
          {(transactionType === 'credit' && status === 'successful') &&
            <Refunds
              transactionError={transactionError}
              refunds={refunds}
              transaction={transaction}
              refetchTransactions={refetchTransactions}
            />
          }
        </>
      }
    </>
  );
}
