import { useTransactionContext } from '../../hooks';

const SingleTransactionButton = ({ children, title = '', transaction }) => {
  const { setCurrentView, setSelectedTransaction } = useTransactionContext();

  const handleClick = () => {
    setSelectedTransaction(transaction);
    setCurrentView('single transaction');
  };

  return (
    <button
      aria-label="Show more details for this event"
      className="table__button--body"
      onClick={handleClick}
      title={title}
      type="button"
    >
      {children}
    </button>
  );
};

export default SingleTransactionButton;
