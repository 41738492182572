import SingleTransactionView from './SingleTransactionView/SingleTransactionView';
import { useTransactionContext } from '../../hooks';

const TransactionDialogs = () => {
  const { currentView } = useTransactionContext();

  const dialogToShow = {
    'single transaction': <SingleTransactionView visible />,
    default: null
  };

  return dialogToShow[currentView] || dialogToShow['default'];
};

export default TransactionDialogs;
