import { ISOtoMilli, userTimezoneOffsetMilli, currencyFormat } from '../../utils';
import { EmptyChart, LineChart, ChartLegend } from '../';
import _ from 'lodash';

const RevenueByType = ({ data = {} }) => {
  const dataAvailable = Object.keys(data).length
    && (data?.cash?.value > 0 || data?.credit?.value > 0 || data?.payment?.value > 0);

  let formatData = [];
  let chartData = [];
  let legendData = [];

  if (dataAvailable) {
    formatData = stat => {
      if (stat.timeSeriesData === null) {
        stat.timeSeriesData = [];
      }

      return _.orderBy(stat.timeSeriesData, [o => o.key], 'asc').map(x => {
        let entry = [];
        const val = parseFloat(x.value);
        const formattedDate = ISOtoMilli(x.key) - userTimezoneOffsetMilli;
        const formattedVal = val === 0 ? val : val / 100;
        entry.push(formattedDate, formattedVal);

        return entry;
      });
    };

    chartData = [
      { name: 'Cash', data: formatData(data.cash), color: '#B5E77C' },
      { name: 'Credit', data: formatData(data.credit), color: '#5FE0C6' },
      { name: 'Payment', data: formatData(data.payment), color: '#673AB7' }
    ];

    legendData = [
      { name: 'Cash', value: data?.cash?.value, color: '#B5E77C' },
      { name: 'Credit', value: data?.credit?.value, color: '#5FE0C6' },
      { name: 'Payment', value: data?.payment?.value, color: '#673AB7' }
    ];
  }

  return (
    <>
      {dataAvailable ?
        <>
          <LineChart data={chartData} />
          <ChartLegend data={legendData} formatter={currencyFormat} />
        </>
        :
        <EmptyChart />
      }
    </>
  );
};

export default RevenueByType;
