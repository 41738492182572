import { useState } from 'react';
import Odometer from 'react-odometerjs';
import { CashIcon } from '../../assets/react-icons';
import { ChartCard, RevenueByType, TopMetric, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import ExpandedView from './ExpandedView';
import { isNotEmpty } from '../../utils';

const OnsiteRevenueCard = () => {
  const { metrics, metricsLoading } = useOverviewSectionsContext();
  const [expanded, setExpanded] = useState(false);

  return (
    <ChartCard
      className="indicate-focus-state on-site-revenue__pendo"
      data-id="on-site-revenue__pendo"
      loading={metricsLoading}
      type="metric"
      onClick={() => setExpanded(true)}
    >
      {isNotEmpty(metrics?.revenue) ? (
        <>
          <TopMetric
            className="large"
            value={
              <>
                <span className="top-metric-prefix">$</span>
                <Odometer duration={100} value={metrics.revenue.value / 100} format="(,ddd)" />
              </>
            }
            label="On-site Revenue"
            icon={<CashIcon width="25" color="#8bc34a" />}
          />
          <RevenueByType data={metrics.revenue} />
          {metrics &&
            <ExpandedView
              setVisible={setExpanded}
              visible={expanded}
              data={metrics}
            />
          }
        </>
      ) :
        !metricsLoading && <EmptyChart center />
      }
    </ChartCard>
  );
};

export default OnsiteRevenueCard;
