import { useScrollFreeze } from '../../hooks';

const Overlay = ({ visible }) => {
  useScrollFreeze(visible);

  return (
    <div className="overlay" />
  );
};

export default Overlay;
