import { useCallback } from 'react';
import { isNotEmpty, centsToCurrency, numberWithCommas } from '../../utils';
import {
  AppBar,
  AppBarTitle,
  AppBarAction,
  CloseSVGIcon,
  DropdownMenu,
  FontIcon,
  MenuConfigurationProvider,
  Radio,
  WarningFontIcon,
  useMenuVisibility
} from 'react-md';
import { Card, GraphBar } from '../';
import moment from 'moment';
import cx from 'classnames';

const LotsChart = ({
  currentDataType,
  currentSegmentBy,
  formatGraphBarValue,
  handleDataTypeChange,
  handleSegmentByChange,
  lots
}) => {
  function formatDate(date) {
    return moment(date).format('h:mm a');
  }

  function Header() {
    const { setVisible } = useMenuVisibility();

    return (
      <AppBar theme="clear">
        <AppBarTitle className="mobile-menu-title">View options:</AppBarTitle>
        <AppBarAction first last onClick={() => setVisible(false)}>
          <CloseSVGIcon />
        </AppBarAction>
      </AppBar>
    );
  }

  function makeUnique(array) {
    return [...new Set(array)];
  }

  const getCurrentSegment = useCallback((lot) => {
    switch (currentSegmentBy) {
      case 'transactionType':
        return lot?.lotFocus?.lotTransactionTypes?.transactionTypes;
      case 'pricings':
        return lot?.lotFocus?.lotPricings?.pricings;
      default:
        return null;
    }
  }, [currentSegmentBy]);

  return (
    <div className="segmentation-card-container">
      {lots.map((lot, index) => {
        const currentSegment = getCurrentSegment(lot);

        return (
          <Card
            className={cx('segmentation-card', { 'warning-status': lot?.totalSpots < lot?.parked })}
            key={`${lot?.id}-${lot?.name}-${index}`}
          >
            <div className="segmentation-card-header segmentation-card-section">
              {lot?.name &&
                <div className="segmentation-card-header-name">
                  {lot?.name}
                </div>
              }
              {lot?.lotFocus?.eventNames &&
                <div className="segmentation-card-header-content">
                  {makeUnique(lot?.lotFocus?.eventNames).join(', ')}
                </div>
              }
            </div>
            <div className="segmentation-graph-container segmentation-card-section">
              {currentSegment && (
                <GraphBar
                  data={currentSegment}
                  formatter={formatGraphBarValue}
                  valueKey={currentDataType}
                  valueLabel="name"
                  indexKey={`${currentSegmentBy}.${currentDataType}`}
                  defaultSubsegmentLabel="Spot Type Unavailable"
                  defaultLabel="No Pricing Available"
                >
                  <MenuConfigurationProvider
                    sheetHeader={<Header />}
                    sheetFooter={<div className="mobile-sheet-footer" />}
                    renderAsSheet
                    label="renderAsSheet"
                    sheetPosition="top"
                    sheetVerticalSize="recommended"
                  >
                    <DropdownMenu
                      id="mobile-action-sheet"
                      icon={<FontIcon>more_vert</FontIcon>}
                    >
                      <form
                        name="segmentation-form-dropdown"
                        className="segmentation-form-dropdown"
                      >
                        <div className="segmentation-dropdown-controls">
                          <span>Segmented by</span>
                          <Radio
                            className={cx(currentSegmentBy === 'transactionType' && 'dropdown-radio-checked')}
                            id="transaction-type"
                            name="segment-radios"
                            label="Transaction type"
                            value="transactionType"
                            onChange={handleSegmentByChange}
                            checked={currentSegmentBy === 'transactionType'}
                          />
                          <Radio
                            className={cx(currentSegmentBy === 'pricing' && 'dropdown-radio-checked')}
                            id="pricing"
                            name="segment-radios"
                            label="Pricing"
                            value="pricing"
                            onChange={handleSegmentByChange}
                            checked={currentSegmentBy === 'pricing'}
                          />
                        </div>
                        <div className="segmentation-dropdown-controls">
                          <span>Data type</span>
                          <Radio
                            className={cx(currentDataType === 'parked' && 'dropdown-radio-checked')}
                            id="cars-parked"
                            name="data-radios"
                            label="Cars parked"
                            value="parked"
                            onChange={handleDataTypeChange}
                            checked={currentDataType === 'parked'}
                          />
                          <Radio
                            className={cx(currentDataType === 'revenue' && 'dropdown-radio-checked')}
                            id="revenue"
                            name="data-radios"
                            label="Revenue"
                            value="revenue"
                            onChange={handleDataTypeChange}
                            checked={currentDataType === 'revenue'}
                          />
                        </div>
                      </form>
                    </DropdownMenu>
                  </MenuConfigurationProvider>
                </GraphBar>
              )}
            </div>
            <div className="segmentation-details segmentation-card-section">
              <dl>
                <>
                  <dt className="location-details">
                    Location
                  </dt>
                  <dd className="location-details">
                    {lot?.lotFocus?.landmarkNames}
                  </dd>
                </>
                {isNotEmpty(lot?.lotFocus?.eventNames)}
                <>
                  <dt className="event-details">
                    Events
                  </dt>
                  <dd className="event-details">
                    {makeUnique(lot?.lotFocus?.eventNames).join(', ')}
                  </dd>
                </>
                {currentDataType === 'parked' &&
                  <>
                    <dt className="utilization-details">
                      Utilized Inventory / Total Inventory
                      {lot?.totalSpots < lot?.parked &&
                        <WarningFontIcon
                          title={`${numberWithCommas(lot?.parked - lot?.totalSpots)} oversold`}
                          className="warning-icon"
                        />
                      }
                    </dt>
                    <dd className="warning-value utilization-details">
                      {lot?.parked || '-'} / {lot?.totalSpots || '-'}
                    </dd>
                  </>
                }
                {currentDataType === 'revenue' &&
                  <>
                    <dt>
                      Total Revenue
                    </dt>
                    <dd>
                      {centsToCurrency(lot?.totalRevenue)}
                    </dd>
                  </>
                }
                {currentDataType === 'revenue' &&
                  <>
                    <dt>
                      Onsite Revenue
                    </dt>
                    <dd>
                      {centsToCurrency(lot?.lotFocus?.onSiteRevenue)}
                    </dd>
                  </>
                }
                {isNotEmpty(lot?.lotFocus?.cashiers) &&
                  <>
                    <dt>
                      Cashiers
                    </dt>
                    <dd>
                      {lot?.lotFocus?.cashiers.length}
                    </dd>
                  </>
                }
                <>
                  <dt>
                    Parking times
                  </dt>
                  <dd>
                    {lot?.lotFocus?.parkingFrom ? formatDate(lot?.lotFocus?.parkingFrom) : '?'} - {lot?.lotFocus?.parkingTo ? formatDate(lot?.lotFocus?.parkingTo) : '?'}
                  </dd>
                </>
                <>
                  <dt className="event-time-details">
                    Event times
                  </dt>
                  <dd className="event-time-details">
                    {lot?.lotFocus?.eventsFrom ? formatDate(lot?.lotFocus?.eventsFrom) : '?'} - {lot?.lotFocus?.eventsTo ? formatDate(lot?.lotFocus?.eventsTo) : '?'}
                  </dd>
                </>
              </dl>
            </div>
          </Card>
        );
      }
      )}
    </div>
  );
};

export default LotsChart;
