import OnSiteRevenueCard from '../../../components/OnSiteRevenueCard/OnSiteRevenueCard';
import UtilizationChartCard from '../../../components/UtilizationChartCard/UtilizationChartCard';
import ParkedChartCard from '../../../components/ParkedChartCard/ParkedChartCard';
import TransactionsChartCard from '../../../components/TransactionsChartCard/TransactionsChartCard';

const Section1 = () => (
  <div className="chart-section-1">
    <OnSiteRevenueCard />
    <ParkedChartCard />
    <TransactionsChartCard />
    <UtilizationChartCard />
  </div>
);

export default Section1;
