import { SearchIcon } from '../../assets/react-icons';
import cx from 'classnames';
import './EmptyChart.scss';

const EmptyChart = ({ small, center }) => (
  <div className={cx('empty-chart-container', { center })}>
    <SearchIcon color="#bbb" height="50px" />
    <div className={cx('empty-chart-text', { small })}>
      {small ?
        <>No data</>
        :
        <>
          No data matches <br /> these parameters
        </>
      }
    </div>
  </div>
);

export default EmptyChart;
