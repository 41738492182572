import './ChartCardLoading.scss';

const ChartCardLoading = ({ type }) => {
  if (type === 'compact') {
    return (
      <>
        <div className="loading-metric" />
        <div className="loading-title" />
      </>
    );
  }

  if (type === 'metric') {
    return (
      <>
        <div className="loading-metric" />
        <div className="loading-title" />
        <div className="loading-content" />
      </>
    );
  }

  if (type === 'list') {
    return (
      <>
        <div className="loading-title" />
        <div className="loading-list" />
        <div className="loading-list" />
        <div className="loading-list" />
        <div className="loading-list" />
        <div className="loading-list" />
      </>
    );
  }
};

export default ChartCardLoading;
