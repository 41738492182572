import { Fragment } from 'react';
import { useCashiersContext } from '../../hooks';
import { camelCaseString, centsToCurrency } from '../../utils';

const CashCountDetails = () => {
  const { currentFormData = {} } = useCashiersContext();
  const { bagId = '', expectedCashOnHand, remainder = 0, cashCollected = 0 } = currentFormData;

  const cashDiscrepancy = expectedCashOnHand - remainder - cashCollected;

  const detailFields = [
    {
      label: 'Expected Cash on Hand',
      value: centsToCurrency(expectedCashOnHand)
    },
    {
      label: 'Cash Left with Cashier',
      value: centsToCurrency(remainder)
    },
    {
      label: 'Cash Collected Amount',
      value: centsToCurrency(cashCollected)
    },
    {
      label: 'Bag ID',
      value: bagId || 'No bag ID entered'
    },
    {
      label: 'Cash Discrepancy',
      status: cashDiscrepancy ? 'warning' : 'normal',
      value: centsToCurrency(cashDiscrepancy)
    }
  ];

  return detailFields.map(({ label, status, value }) => (
    <Fragment key={camelCaseString(label)}>
      <dt className="modal-form-review-term">{label}</dt>
      <dd className="modal-form-review-details" data-status={status || 'normal'}>
        {value}
      </dd>
    </Fragment>
  ));
};

export default CashCountDetails;
