import { useState } from 'react';
import { Lock } from '../../assets/react-icons';
import { Tooltip } from '../';
import { centsToDollars, stringToKebabCase } from '../../utils';
import CurrencyInput from 'react-currency-input-field';

const CurrencyInputField = ({
  cents = 0,
  handleChange,
  id = '',
  label = 'Label required',
  placeholder = '',
  readonly = false,
  required = false,
  tooltipText = ''
}) => {
  const initialValue = centsToDollars(cents) || '';
  const [displayValue, setDisplayValue] = useState(initialValue);
  const [fieldState, setFieldState] = useState('');

  const handleCurrencyChange = value => {
    handleChange(Math.round(parseFloat(value) * 100));
    setDisplayValue(value);
  };

  const handleFocus = () => {
    setFieldState('focused');
  };

  const uniqueId = id || stringToKebabCase(label);

  return (
    <fieldset className={`text-input-fieldset ${fieldState}`}>
      <label className="text-input-label" htmlFor={uniqueId}>
        <span className="text-input-label-text">
          {label}
          {readonly && <Lock />}
          {required && <sup aria-label="This input is required.">*</sup>}
          {tooltipText && <Tooltip id={id} text={tooltipText} />}
        </span>
        <CurrencyInput
          className="text-input"
          decimalsLimit={2}
          disabled={readonly}
          id={uniqueId}
          name={uniqueId}
          onValueChange={handleCurrencyChange}
          onFocus={handleFocus}
          placeholder={displayValue === undefined ? '' : placeholder}
          prefix="$"
          required={required}
          type="text"
          value={displayValue}
        />
      </label>
    </fieldset>
  );
};

export default CurrencyInputField;
