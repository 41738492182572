import { DonutChart, EmptyChart } from '../';
import { percentFormat } from '../../utils';

const Utilization = ({ data }) => {
  const dataAvailable = data.value > 0;
  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      if (data.sold) {
        formattedArray.push({
          name: 'Parked',
          y: parseInt(data.sold, 10),
          color: '#FF8287',
          selected: true
        });
      }

      if (data.total > data.sold) {
        const available = data.total - data.sold;

        formattedArray.push({
          name: 'Available',
          y: parseInt(available, 10),
          color: '#EAEBEC',
          selected: true
        });
      }

      return formattedArray;
    })();
  }

  return (
    <>
      {
        dataAvailable ?
          <DonutChart
            data={formattedData}
            name="Spots"
            subtitle="Utilization"
            total={percentFormat(data.value, 0)}
          />
          :
          <EmptyChart />
      }
    </>
  );
};

export default Utilization;
