import { gql } from '@apollo/client';

export const CREATE_REFUND = gql`
mutation CreateRefund(
	$refund: RefundWithTransactionInput
) {
	postRefund(
		refund: $refund
	) {
    id
    action
    comments
    reason
  }
}
`;
