import cx from 'classnames';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../../utils';
import './ChartLegend.scss';

const ChartLegend = ({
  className,
  data,
  formatter = numberWithCommas
}) => {
  return (
    <div className={cx('chart-legend', className)}>
      {data?.filter(item => item.value)?.map(({ value, name, color }) => (
        <div key={name} className="legend-item">
          <div className="legend-label">
            <div className="legend-color" style={{ backgroundColor: color }} />
            <div className="legend-name">{name}</div>
          </div>
          <div className="legend-value">{formatter(value)}</div>
        </div>
      ))}
    </div>
  );
};

ChartLegend.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string
  })).isRequired,
  formatter: PropTypes.func
};

export default ChartLegend;
