export const CHART_COLORS = [
  '#8BC34A', //light green
  '#66BB6A', //green
  '#29B6F6', //blue
  '#673AB7', //purple
  '#FF5722', //orange
  '#78909C', //grey
  '#E53935', //red
  '#ECEFF1', //light grey
  '#EFC74B', //yellow
  '#EC407A', //pink
  '#01579B', //navy blue
  '#E53935', //same red
  '#1E88E5', //similar but different blue
  '#FF8A65', //peach
  '#F89AD8' //pink
];

export const GRAPH_COLORS_BY_TYPE = {
  Cash: {
    backgroundColor: '#b5E77c', // yellow-green
    borderColor: '#b5E77c' // yellow-green
  },
  Credit: {
    backgroundColor: '#5fdfc6', // teal
    borderColor: '#5fdfc6' // teal
  },
  Prepaid: {
    backgroundColor: '#673ab7', // deep-purple
    borderColor: '#673ab7' // deep-purple
  },
  VIP: {
    backgroundColor: '#fbd648', // yellow
    borderColor: '#fbd648' // yellow
  },
  Permit: {
    backgroundColor: '#4587E3', // dark-blue
    borderColor: '#4587E3' // dark-blue
  },
  Exempt: {
    backgroundColor: '#29b6f6', // light-blue
    borderColor: '#29b6f6' // light-blue
  },
  Available: {
    backgroundColor: '#fff', // white
    borderColor: '#efefef' // off-white
  },
  'No Revenue': {
    backgroundColor: '#e2e2e2', // gray
    borderColor: '#e2e2e2' // gray
  }
};

export const GRAPH_COLORS_LIST = [
  {
    backgroundColor: '#fbd648', // yellow
    borderColor: '#fbd648' // yellow
  },
  {
    backgroundColor: '#b5E77c', // yellow-green
    borderColor: '#b5E77c' // yellow-green
  },
  {
    backgroundColor: '#5fdfc6', // teal
    borderColor: '#5fdfc6' // teal
  },
  {
    backgroundColor: '#29b6f6', // light-blue
    borderColor: '#29b6f6' // light-blue
  },
  {
    backgroundColor: '#673ab7', // deep-purple
    borderColor: '#673ab7' // deep-purple
  },
  {
    backgroundColor: '#4587E3', // dark-blue
    borderColor: '#4587E3' // dark-blue
  },
  {
    backgroundColor: '#d13d84', // maroon
    borderColor: '#d13d84' // maroon
  },
  {
    backgroundColor: '#ec994d', // orange
    borderColor: '#ec994d' // orange
  },
  {
    backgroundColor: '#ec407A', // pink
    borderColor: '#ec407A' // pink
  },
  {
    backgroundColor: '#ff8A65', // peach
    borderColor: '#ff8A65' // peach
  },
  {
    backgroundColor: '#01579b', // navy-blue
    borderColor: '#01579b' // navy-blue
  },
  {
    backgroundColor: '#01ac53', // green
    borderColor: '#01ac53' // green
  },
  {
    backgroundColor: '#0176ac', // sky-blue
    borderColor: '#0176ac' // sky-blue
  },
  {
    backgroundColor: '#8100fa', // purple
    borderColor: '#8100fa' // purple
  },
  {
    backgroundColor: '#b84000', // deep-orange
    borderColor: '#b84000' // deep-orange
  },
  {
    backgroundColor: 'rgba(251, 214, 72, .21)', // yellow-transparent
    borderColor: '#fbd648' // yellow
  },
  {
    backgroundColor: 'rgba(181, 231, 124, .21)', // yellow-green-transparent
    borderColor: '#b5E77c' // yellow-green
  },
  {
    backgroundColor: 'rgba(95, 223, 198, .21)', // teal-transparent
    borderColor: '#5fdfc6' // teal
  },
  {
    backgroundColor: 'rgba(41, 182, 246, .21)', // light-blue-transparent
    borderColor: '#29b6f6' // light-blue
  },
  {
    backgroundColor: 'rgba(103, 58, 183, .21)', // deep-purple-transparent
    borderColor: '#673ab7' // deep-purple
  },
  {
    backgroundColor: 'rgba(69, 135, 227, .21)', // dark-blue
    borderColor: '#4587E3' // dark-blue
  },
  {
    backgroundColor: 'rgba(209, 61, 132, .21)', // maroon-transparent
    borderColor: '#d13d84' // maroon
  },
  {
    backgroundColor: 'rgba(236, 153, 77, .21)', // orange-transparent
    borderColor: '#ec994d' // orange
  },
  {
    backgroundColor: 'rgba(236, 64, 122, .21)', // pink-transparent
    borderColor: '#ec407A' // pink
  },
  {
    backgroundColor: 'rgba(255, 138, 101, .21)', // peach-transparent
    borderColor: '#ff8A65' // peach
  },
  {
    backgroundColor: 'rgba(1, 87, 155, .21)', // navy-blue-transparent
    borderColor: '#01579b' // navy-blue
  },
  {
    backgroundColor: 'rgba(1, 172, 83, .21)', // green-transparent
    borderColor: '#01ac53' // green
  },
  {
    backgroundColor: 'rgba(1, 118, 172, .21)', // sky-blue-transparent
    borderColor: '#0176ac' // sky-blue
  },
  {
    backgroundColor: 'rgba(129, 0, 250, .21)', // purple-transparent
    borderColor: '#8100fa' // purple
  },
  {
    backgroundColor: 'rgba(184, 64, 0, .21)', // deep-orange-transparent
    borderColor: '#b84000' // deep-orange
  }
];

export function getBarColor(key, idx) {
  const color = GRAPH_COLORS_BY_TYPE[key];

  return color ? color : GRAPH_COLORS_LIST[idx % GRAPH_COLORS_LIST.length];
};

export function hexToRGB(hex, opacity) {
  const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16));

  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
};
