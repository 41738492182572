import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { currencyFormat, limitListSize, truncate } from '../../utils';
import { useMetricListContext } from '../MetricList/MetricListContext';
import CashierCardDropdown from './CashiersCardDropdown';
import cx from 'classnames';

const MetricListCashiers = ({ listLimit = 0, textLength }) => {
  const { animationTimeout, cashiers } = useMetricListContext();

  function highlightCell(value) {
    if (value > 0 && value <= 30) {
      return 'highlight-cell';
    }
  }

  return (
    <div className="metric-list">
      <table className="cashiers-metric-table">
        <thead>
          <tr>
            <td />
            <td>Total Cash</td>
            <td>iPhone</td>
            <td className="reader-battery-column">Reader</td>
          </tr>
        </thead>
        <TransitionGroup component="tbody">
          {limitListSize(cashiers, listLimit).map(cashier => {
            const { battery, id = '', lotNames = [], name = '', sledBattery, cashRevenue = 0 } = cashier;
            const formattedRevenue = currencyFormat(cashRevenue);
            
            return (
              <CSSTransition key={id} timeout={animationTimeout} classNames="fade">
                <tr key={id} className="metric-list-row">
                  <td className="metric-list-column truncate cashier-lot">
                    <span title={name} className="metric-list-cell-top">
                      {name}
                    </span>
                    <span title={lotNames.join(', ')} className="metric-list-cell-bottom">
                      {truncate(lotNames.join(', '), textLength)}
                    </span>
                  </td>
                  <td className="metric-list-column truncate cashier-total">
                    <span title={formattedRevenue} className="metric-list-cell-top">{formattedRevenue}</span>
                  </td>
                  <td className="metric-list-column">
                    <span
                      className={cx('metric-list-cell-top metric-highlight-cell', highlightCell(battery))}
                    >
                      {battery ? `${battery}%` : '—'}
                    </span>
                  </td>
                  <td className="metric-list-column">
                    <span
                      className={cx('metric-list-cell-top metric-highlight-cell reader-battery-column', highlightCell(sledBattery))}
                    >
                      {sledBattery ? `${sledBattery}%` : '—'}
                    </span>
                  </td>
                  <td className="metric-list-column button-cell">
                    <CashierCardDropdown cashier={cashier} />
                  </td>
                </tr>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListCashiers;
