import { Modal } from '../';
import { useCashiersContext, useMetricListContext } from '../../hooks';
import CashCountModal from '../CashCountModal/CashCountModal';
import CashierFormReview from '../CashierFormReview/CashierFormReview';
import SuccessModal from '../CashierFormReview/SuccessModal';
import CashManagementModal from '../CashManagementModal/CashManagementModal';
import CashOutModal from '../CashOutModal/CashOutModal';
import CashRecountModal from '../CashRecountModal/CashRecountModal';
import SelectEventModal from '../SelectEventModal/SelectEventModal';
import CashierDetailsModal from '../CashierDetailsModal/CashierDetailsModal';
import ExpandedView from './ExpandedView';
import { titleCaseString, capitalizeString } from '../../utils';

const CashiersDialogs = () => {
  const {
    expandedVisible,
    setExpandedVisible,
    currentDialog,
    setCurrentDialog,
    selectedCashier,
    closeDialog,
    selectedEvent,
    activeForm
  } = useCashiersContext();
  const { cashiers = [] } = useMetricListContext();
  const cashier = cashiers.find(c => c.id === selectedCashier.id);
  const cashierName = cashier?.name || 'cashier';
  const { name: eventName = '' } = selectedEvent;

  function handleOnClose(e) {
    e.stopPropagation();
    closeDialog();
    setExpandedVisible(false);
  }

  const dialogs = {
    'expanded view': {
      id: 'cashier-expanded-view-modal',
      modalWidth: '73rem',
      modalContent: <ExpandedView />,
      headline: 'Cashiers',
      subhead: 'List of cashiers who have performed a transaction in selected event(s) or lot(s). It also provides ability for users to perform cash management transactions.',
      closeButtonVisible: true,
      goBack: null
    },
    'cashier details': {
      id: 'cashier-details-view-modal',
      modalWidth: '55rem',
      modalContent: <CashierDetailsModal />,
      headline: cashier?.name?.trim() === '' ? 'Cashier' : cashier?.name,
      subhead: '',
      closeButtonVisible: true,
      goBack: () => setCurrentDialog('expanded view')
    },
    'cash count': {
      id: 'cash-count-modal',
      modalWidth: '55rem',
      modalContent: <CashCountModal />,
      headline: 'Cash Count',
      subhead: 'Add a count to the cash pickup',
      closeButtonVisible: false,
      goBack: () => setCurrentDialog('cash management')
    },
    'cash out': {
      id: 'cash-out-modal',
      modalWidth: '55rem',
      modalContent: <CashOutModal />,
      headline: 'New Cash Out',
      subhead: `This is the final count at the end of <b>${cashierName}’s</b> shift.`,
      closeButtonVisible: false,
      goBack: () => setCurrentDialog('cash management')
    },
    'cash management': {
      id: 'cash-management-modal',
      modalWidth: '55rem',
      modalContent: <CashManagementModal />,
      headline: 'Cash Management',
      subhead: `Editing <b>${cashierName}’s </b> cash amounts for <b>${eventName}</b>`,
      closeButtonVisible: true,
      goBack: () => setCurrentDialog('select event')
    },
    recount: {
      id: 'cash-recount-modal',
      modalWidth: '55rem',
      modalContent: <CashRecountModal />,
      headline: 'Add Recount',
      subhead: `Add or subtract amounts from your final count to account for discrepancies`,
      closeButtonVisible: true,
      goBack: () => setCurrentDialog('cash management')
    },
    'form review': {
      id: 'cashier-form-review',
      modalWidth: '55rem',
      modalContent: <CashierFormReview />,
      headline: `Review ${titleCaseString(activeForm) || 'Transaction'}`,
      subhead: 'Make sure everything looks correct. Click the back arrow to edit.',
      closeButtonVisible: true,
      goBack: () => setCurrentDialog(activeForm)
    },
    'select event': {
      id: 'select-event-modal',
      modalWidth: '55rem',
      modalContent: <SelectEventModal />,
      headline: 'Select Event',
      subhead: 'Cash outs and cash drops are tied to specific events',
      closeButtonVisible: true,
      goBack: () => setCurrentDialog('cashier details')
    },
    success: {
      id: 'success-modal',
      modalWidth: '55rem',
      modalContent: <SuccessModal />,
      headline: 'Success!',
      subhead: `${capitalizeString(activeForm) || 'Form'} submission was successful.`,
      closeButtonVisible: true,
      goBack: null
    },
    default: null
  };

  const dialog = dialogs[currentDialog];

  return (
    <Modal
      id={dialog?.id}
      closeModal={handleOnClose}
      headline={dialog?.headline}
      maxWidth={dialog?.modalWidth}
      subhead={dialog?.subhead}
      visible={expandedVisible}
      goBack={dialog?.goBack}
      closeButtonVisible={dialog?.closeButtonVisible}
    >
      {dialog?.modalContent || <div />}
    </Modal>
  );
};

export default CashiersDialogs;
