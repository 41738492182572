import React from 'react';

const CheckIcon = props => (
  <svg viewBox="0 0 25 25" {...props}>
    <circle cx="12.3335" cy="12.5" r="12" fill="currentColor"/>
    <path d="M17.5135 8.35808L9.9535 15.6252L9.3335 15.1929L7.1535 13.046L9.9535 15.8035L17.5135 8.35808ZM17.5135 6.5L9.9535 13.9455L7.1535 11.1879L4.3335 13.9652L9.9535 19.5L20.3335 9.27727L17.5135 6.5Z" fill="#8A8A8A"/>
  </svg>
);

export default CheckIcon;
