import PropTypes from 'prop-types';
import { getBarColor, hexToRGB } from '../../utils';
import cx from 'classnames';
import './GraphBarCell.scss';

function GraphBarCell({ item, index, title, width, opacity, group }) {
  const color = getBarColor(item.key, index);

  return (
    <div
      title={title}
      className={
        cx('graph-bar-chart-cell', {
          'graph-bar-chart-cell-group': group
        })
      }
      style={{
        borderColor: color.borderColor,
        backgroundColor: opacity ? hexToRGB(color.backgroundColor, opacity) : color.backgroundColor,
        flexBasis: group ? `${width}%` : '100%',
        ...(group && width < 1 && { borderWidth: 0 })
      }}
    />
  );
}

GraphBarCell.propTypes = {
  group: PropTypes.bool, // if true, this cell is part of a group
  index: PropTypes.number, // index to determine the color
  item: PropTypes.object, // item with key to determine the color
  opacity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),  // opacity of the background color
  title: PropTypes.string,  // title of the cell on hover
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) // width of the cell
};

export default GraphBarCell;
