import { ChartCardLoading } from '..';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ChartCard.scss';

const ChartCard = ({ ref, children, label, className, onClick, loading, type = 'metric', icon }) => {
  return (
    <div
      ref={ref}
      tabIndex={onClick && '0'}
      onClick={onClick}
      role={onClick ? 'button' : 'card'}
      className={cn('chart-card', className, type, { clickable: !!onClick, loading })}
    >
      {loading ? (
        <ChartCardLoading type={type} />
      ) : (
        <>
          {label && (
            <div className="chart-card-label">
              {icon}
              <h3>{label}</h3>
            </div>
          )}
          {children}
        </>
      )}
    </div>
  );
};

ChartCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['metric', 'list', 'compact'])
};

ChartCard.defaultProps = {
  type: 'metric'
};

export default ChartCard;
