import { useCashiersContext } from '../../hooks';
import { centsToCurrency, localTimeFromDate } from '../../utils';

const CashOutItems = () => {
  const { cashLogs = {} } = useCashiersContext();
  const { cashOutItem = {}, initialBank = 0, totalCashCollected = 0 } = cashLogs;
  const { amount, bagId, comments, counterDisplayName, created } = cashOutItem;
  const cashExpected = initialBank + totalCashCollected;

  const details = [
    `${centsToCurrency(amount)} cash revenue collected amount.`,
    `${centsToCurrency(initialBank)} bank collected amount.`,
    `${centsToCurrency(cashExpected)} was expected.`,
    `${centsToCurrency(amount - totalCashCollected)} difference`,
    `Bag ID: ${bagId || 'No bag ID provided'}`,
    `Comment: ${comments || 'No comment provided'}`
  ];

  return (
    <li className="cash-management-item">
      <p className="cash-management-action">
        <b>{counterDisplayName}</b> performed a cash out.
      </p>
      <time className="cash-management-time" dateTime={created}>
        {localTimeFromDate(created)}
      </time>
      <ul className="cash-management-details">
        {details.map((detail, i) => (
          <li className="cash-management-details-item" key={`cashout-detail-${i + 1}}`}>
            {detail}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default CashOutItems;
