import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment CashierOverviewsFragment on CashierOverview {
    id
    battery
    currentDevice
    devices
    events {
      cashOnHand
      cashedOut
      eventId
    }
    latestTransaction {
      transactionTime
    }
    name
    reprints
    cashRevenue
    transactionCount
    value
    cashTransactionCount
    creditRevenue
    creditTransactionCount
    exemptTransactionCount
    lotNames
    paymentRevenue
    paymentTransactionCount
    permitTransactionCount
    prepaidRevenue
    prepaidTransactionCount
    revenue
    sledBattery
    vipTransactionCount
  }
`;

export const METRICS_CASHIER_OVERVIEWS_QUERY = gql`
  ${FRAGMENTS}

  query MetricsCashierOverviewsQuery(
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
    ) {
      cashierOverviews {
        ...CashierOverviewsFragment
      }
    }
  }
`;

export const METRICS_CASHIER_OVERVIEWS_SUBSCRIPTION = gql`
  ${FRAGMENTS}

  subscription MetricCashierOverviewsSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      cashierOverviews {
        ...CashierOverviewsFragment
      }
    }
  }
`;
