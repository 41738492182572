import { Button } from 'react-md';
import { useCashiersContext } from '../../hooks';

const SuccessModal = ({ visible }) => {
  const { closeDialog } = useCashiersContext();

  return (
    <div className="x-center">
      <Button
        theme="primary"
        themeType="contained"
        className="button-blue modal-success-button"
        onClick={closeDialog}
      >
        OK
      </Button>
    </div>
  );
};

export default SuccessModal;
