import { useCashiersContext } from '../../hooks';
import CashDropItems from './CashDropItems';
import CashOutItems from './CashOutItems';
import CashRecountItems from './CashRecountItems';

const CashManagementAuditList = () => {
  const { cashLogs } = useCashiersContext();
  const cashDrops = cashLogs?.cashDrops || [];
  const cashOut = cashLogs?.cashOut || {};
  const cashCounts = cashOut?.counts || [];

  return (
    <ul className="cash-management-list">
      {cashDrops && <CashDropItems />}
      {cashCounts.length ? <CashOutItems /> : ''}
      {cashCounts.length > 1 ? <CashRecountItems /> : ''}
    </ul>
  );
};

export default CashManagementAuditList;
