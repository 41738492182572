import React from 'react';

const QuadIconCircled = props => (
  <svg fill="none" viewBox="0 0 32 32" {...props}>
  <circle
    cx="16"
    cy="16"
    r="16"
    fill="currentColor"
    fillOpacity=".2"
  />
  <path
    fill="currentColor"
    fillRule="evenodd"
    d="M11.9113 10.1197l4.0591-4.05904 4.3362 4.33614-4.0591 4.0591-4.3362-4.3362zm-1.2374 1.2375l-4.61324 4.6132 4.33614 4.3362 4.6133-4.6133-4.3362-4.3361zm.9603 10.1868l4.3362 4.3362 4.6133-4.6133-4.3362-4.3361-4.6133 4.6132zm10.1869-1.5145l4.0591-4.0591-4.3362-4.3362-4.0591 4.0591 4.3362 4.3362zM15.2633 4.29289c.3905-.39052 1.0237-.39052 1.4142 0L27.648 15.2633c.3905.3905.3905 1.0237 0 1.4142L16.6775 27.648c-.3905.3905-1.0237.3905-1.4142 0L4.29289 16.6775c-.39052-.3905-.39052-1.0237 0-1.4142L15.2633 4.29289z"
    clipRule="evenodd"
  />
</svg>
);

export default QuadIconCircled;