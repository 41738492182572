import { Fragment } from 'react';
import { useCashiersContext } from '../../hooks';
import { camelCaseString, dateTimeCompact } from '../../utils';
import CashCountDetails from './CashCountDetails';
import CashOutDetails from './CashOutDetails';
import CashRecountDetails from './CashRecountDetails';
import ReviewSubmitButton from './ReviewSubmitButton';

const CashierFormReview = () => {
  const {
    activeForm = '',
    selectedCashier = {},
    selectedEvent = {}
  } = useCashiersContext();
  const cashierName = selectedCashier?.name || 'cashier';
  const eventName = selectedEvent?.name || 'event';

  const transactionDetails = [
    {
      label: 'Cashier Name',
      value: cashierName
    },
    {
      label: 'Event Name',
      value: eventName
    },
    {
      displayValue: dateTimeCompact(),
      id: 'date',
      label: 'Date / Time',
      value: new Date().toJSON()
    }
  ];

  const formDetails = {
    'cash count': <CashCountDetails />,
    'cash out': <CashOutDetails />,
    recount: <CashRecountDetails />
  };

  return (
    <div className="modal-form-review">
      <dl className="modal-form-review-list">
        <div className="modal-form-review-header">
          {transactionDetails.map(({ displayValue, id, label, value }) => {
            const key = id ? id : camelCaseString(label);

            return (
              <Fragment key={key}>
                <dt className="modal-form-review-term">{label}</dt>
                <dd className="modal-form-review-details">
                  {id === 'date' ? <time dateTime={value}>{displayValue}</time> : displayValue || value}
                </dd>
              </Fragment>
            );
          })}
        </div>
        <div className="modal-form-review-body">{formDetails[activeForm]}</div>
      </dl>
      <ReviewSubmitButton />
    </div>
  );
};

export default CashierFormReview;
