import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getBarColor } from '../../utils';
import './GraphBarDetails.scss';

const GraphBarDetails = ({
  data,
  formatter,
  showRightColumn,
  valueLabel,
  valueLeftKey,
  valueRightKey,
  header,
  defaultSubsegmentLabel = 'Unavailable',
  defaultLabel = 'Unavailable',
  hideUnknownSegment = false
}) => {
  const isUnknownOrEmpty = (value) => ['', 'unknown']?.includes(value?.toLowerCase());
  const getDefaultLabel = (value, label) => isUnknownOrEmpty(value) ? label : value;
  const getRightColumnValue = (value) => !value ? '—' : formatter(value);

  const SegmentItem = ({ hide, item, index, label, asSubsegment }) => {
    if (hide) {
      return;
    }

    return (
      <>
        <dt className={cx('graph-bar-details-label', { 'graph-bar-details-label-subsegment': asSubsegment })}>
          <div
            className="graph-bar-details-legend"
            style={{ backgroundColor: getBarColor(item.name, index).backgroundColor }}
          />
          {label}
        </dt>
        <dd className={cx('graph-bar-details-value', { 'double-col': showRightColumn })}>
          <div className={cx({ 'graph-bar-details-sub-value': asSubsegment })}>
            {formatter(item[valueLeftKey])}
          </div>
          {showRightColumn &&
            <div className={cx({ 'graph-bar-details-sub-value': asSubsegment })}>
              {getRightColumnValue(item[valueRightKey])}
            </div>
          }
        </dd>
      </>
    );
  };

  return (
    <dl className="graph-bar-details">
      {header}
      {data?.map((item, index) => ((
        <Fragment key={`${item.name}-${index}`}>
          <SegmentItem
            item={item}
            index={index}
            hide={hideUnknownSegment && isUnknownOrEmpty(item[valueLabel])}
            label={getDefaultLabel(item[valueLabel], defaultLabel)}
          />
          {item?.subsegment?.map((subsegment, subIndex) => (
            <SegmentItem
              key={`${subsegment.name}-${index}-${subIndex}`}
              item={subsegment}
              index={index}
              asSubsegment={!isUnknownOrEmpty(item[valueLabel])}
              label={getDefaultLabel(subsegment[valueLabel], defaultSubsegmentLabel)}
            />
          ))}
        </Fragment>
      )))}
    </dl>
  );
};

GraphBarDetails.propTypes = {
  data: PropTypes.array, // array of objects
  defaultLabel: PropTypes.string, // default label for segment
  defaultSubsegmentLabel: PropTypes.string, // default label for subsegment
  formatter: PropTypes.func, // optional formatting function
  header: PropTypes.node, // optional header
  hideUnknownSegment: PropTypes.bool, // hide the segment if it's unknown or empty
  showRightColumn: PropTypes.bool, // show the right column
  valueLabel: PropTypes.string, // key for the label column
  valueLeftKey: PropTypes.string, // key for the left value column
  valueRightKey: PropTypes.string // key for the right value column,
};

export default GraphBarDetails;
