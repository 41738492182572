import CreditRevenueCard from '../../CreditRevenueCard/CreditRevenueCard';
import ReprintsChartCard from '../../ReprintsChartCard/ReprintsChartCard';
import ExemptionsChartCard from '../../ExemptionsChartCard/ExemptionsChartCard';
import VipsChartCard from '../../VipsChartCard/VipsChartCard';
import { CashiersReprintsContextProvider } from '../../ReprintsChartCard/CashiersReprintsContext';
import { PrepaidsChartCard } from '../..';

const Section2 = () => (
  <div className="chart-section-2">
    <CreditRevenueCard />
    <VipsChartCard />
    <PrepaidsChartCard />
    <div className="chart-section-compact">
      <CashiersReprintsContextProvider>
        <ReprintsChartCard compact />
        <ExemptionsChartCard compact />
      </CashiersReprintsContextProvider>
    </div>
  </div>
);

export default Section2;
