import ExpandedReprints from './ExpandedReprints';
import { useCashiersReprintsContext } from '../../hooks';

const ReprintsDialogs = () => {
  const { currentView } = useCashiersReprintsContext();

  const dialogToShow = {
    'cashier selected': <ExpandedReprints visible={currentView === 'cashier selected'} />,
    default: null
  };

  return dialogToShow[currentView] || dialogToShow['default'];
};

export default ReprintsDialogs;
