import Odometer from 'react-odometerjs';
import { CreditCardIcon } from '../../assets/react-icons';
import { ChartCard, CreditRevenue, TopMetric, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';

const CreditRevenueCard = () => {
  const { metrics = {}, setFeatureInDevelopmentModalConfig, metricsLoading } = useOverviewSectionsContext();

  return (
    <ChartCard
      className="credit-revenue__pendo"
      data-id="credit-revenue__pendo"
      loading={metricsLoading}
      type="metric"
      onClick={() => setFeatureInDevelopmentModalConfig({
        title: 'Credit Revenue',
        visible: true,
        description: 'Total successful credit transaction revenue by credit card type.'
      })}
    >
      {isNotEmpty(metrics?.creditBreakdown) ? (
        <>
          <TopMetric
            value={
              <>
                <span className="top-metric-prefix">$</span>
                <Odometer duration={100} value={metrics?.creditBreakdown?.value / 100} format="(,ddd)" />
              </>
            }
            label="Credit Revenue"
            icon={<CreditCardIcon width="25" color="#29B6F6" />}
          />
          <CreditRevenue data={metrics?.creditBreakdown} />
        </>
      ) : (
        !metricsLoading && <EmptyChart center />
      )}
    </ChartCard>
  );
};

export default CreditRevenueCard;
