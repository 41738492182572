import { Button } from 'react-md';
import { useCashiersContext } from '../../hooks';

const CashOutNotice = () => {
  const {
    setCashOutNoticeConfirmed,
    selectedCashier: { name = '' } = {},
    selectedEvent: { name: eventName = '' } = {},
    setCurrentDialog
  } = useCashiersContext();

  return (
    <div className="modal-body-message">
      <p>
        {name || 'The cashier'} will not be able to perform any transactions for {eventName} after the cash out is
        performed.
      </p>
      <ul className="modal-body-button-list">
        <li className="modal-body-button-item">
          <Button
            onClick={() => setCashOutNoticeConfirmed(true)}
            theme="primary"
            themeType="contained"
            className="modal-form-submit-button"
            type="submit"
          >
            Confirm
          </Button>
        </li>
        <li className="modal-body-button-item">
          <Button
            onClick={() => setCurrentDialog('cash management')}
            theme="secondary"
            themeType="contained"
            className="modal-form-submit-button"
            type="submit"
          >
            Go Back
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default CashOutNotice;
