import { useState } from 'react';
import Odometer from 'react-odometerjs';
import { ChartCard, TopMetric, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import { FontIcon } from 'react-md';
import ExpandedView from './ExpandedView';
import { isNotEmpty } from '../../utils';

const ExemptionsChartCard = ({ compact }) => {
  const [expanded, setExpanded] = useState(false);
  const { metricsLoading, metrics = {} } = useOverviewSectionsContext();

  return (
    <>
      <ChartCard
        loading={metricsLoading}
        type={compact ? 'compact' : 'metric'}
        onClick={() => setExpanded(true)}
        className="indicate-focus-state exemptions__pendo"
        data-id="exemptions__pendo"
      >
        {!metricsLoading && metrics?.exemptions ? (
          <>
            <TopMetric
              className={compact ? 'top-metric-compact' : ''}
              value={<Odometer duration={100} value={metrics?.exemptions?.value} format="(,ddd)" />}
              label="Exemptions"
              icon={<FontIcon style={{ color: '#FF8354' }}>block</FontIcon>}
            />
            {isNotEmpty(metrics?.exemptions.exemptionCounts) && <ExpandedView visible={expanded} setVisible={setExpanded} data={metrics?.exemptions.exemptionCounts} />}
          </>
        ) : (
          !metricsLoading && <EmptyChart small center />
        )}
      </ChartCard>
    </>
  );
};

export default ExemptionsChartCard;
