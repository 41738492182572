import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { FILTERS_QUERY } from '../../graphql/filters.query';
import { METRICS_SUBSCRIPTION, METRICS_QUERY } from '../../graphql/metrics.operation';
import { TRANSACTIONS_SUBSCRIPTION, TRANSACTIONS_QUERY } from '../../graphql/transactions.operation';
import { METRICS_CASHIER_OVERVIEWS_QUERY, METRICS_CASHIER_OVERVIEWS_SUBSCRIPTION } from '../../graphql/cashierOverviews.operation';
import { METRICS_LOT_OVERVIEWS_QUERY, METRICS_LOT_OVERVIEWS_SUBSCRIPTION } from '../../graphql/lotOverviews.operation';
import {
  METRICS_REDEEMED_PREPAID_QUERY,
  METRICS_REDEEMED_PREPAID_SUBSCRIPTION,
  METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_QUERY,
  METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_SUBSCRIPTION
} from '../../graphql/redeemedPrepaid.operation';
import { useAddMessage, FontIcon, LinearProgress } from 'react-md';
import { FilterBar, FilterChip, ChipLoading } from '../';
import { useLayoutContext } from '../../hooks';
import { isNotEmpty, checkRole, getAuthUser } from '../../utils';
import OverviewSections from './OverviewSections/OverviewSections';
import './Overview.scss';

const Overview = () => {
  const authUser = getAuthUser();
  const addMessage = useAddMessage();
  const { isLoading, setIsLoading } = useLayoutContext();
  const [filters, setFilters] = useState({ events: [], lots: [], landmarks: [] });
  const [pageLoaded, setPageLoaded] = useState(false);
  const isGlobalAdmin = checkRole(authUser, ['GLOBAL_ADMIN']);
  const REDEEMED_PREPAID_DISABLED = isGlobalAdmin && process.env.REACT_APP_REDEEMED_PREPAID_ENABLED === 'false';
  const CASHIER_OVERVIEWS_DISABLED = isGlobalAdmin && process.env.REACT_APP_CASHIER_OVERVIEWS_ENABLED === 'false';
  const LOT_OVERVIEWS_DISABLED = isGlobalAdmin && process.env.REACT_APP_LOT_OVERVIEWS_ENABLED === 'false';
  const INCLUDE_EVENT_BREAKDOWN = process.env.REACT_APP_LIVE_INCLUDE_EVENT_BREAKDOWN === 'true';

  const {
    loading: filtersLoading,
    data: filtersData
  } = useQuery(FILTERS_QUERY, {
    onError: () => {
      handleError('Error getting filters');
    },
    onCompleted: (data) => {
      setFilters({
        events: formatFilterData(data.filters.events),
        lots: formatFilterData(data.filters.lots),
        landmarks: formatFilterData(data.filters.landmarks)
      });
      setPageLoaded(true);
    }
  });

  ///// METRICS /////
  const {
    data: metricsData,
    loading: metricsLoading,
    subscribeToMore: subscribeToMoreMetrics,
    refetch: refetchMetrics
  } = useQuery(METRICS_QUERY, {
    variables: filters,
    skip: !isNotEmpty(filters.events),
    errorPolicy: 'all',
    onError: (error) => {
      console.error(error);
      handleError('Error getting metrics');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (isNotEmpty(filters.events) && metricsLoading === false) {
      unsubscribe = subscribeToMoreMetrics({
        document: METRICS_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: subscriptionData.data.metrics
          });
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [subscribeToMoreMetrics, filters, metricsLoading]);
  ///// END METRICS /////

  ///// CASHIERS /////
  const {
    data: metricsCashiersData,
    loading: metricsCashiersLoading,
    subscribeToMore: subscribeToMoreMetricsCashiers,
    refetch: refetchMetricsCashiers
  } = useQuery(METRICS_CASHIER_OVERVIEWS_QUERY, {
    variables: filters,
    skip: CASHIER_OVERVIEWS_DISABLED || (!pageLoaded && !isNotEmpty(filters.events)),
    errorPolicy: 'all',
    onError: (error) => {
      console.error(error);
      handleError('Error getting cashiers');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (!CASHIER_OVERVIEWS_DISABLED && isNotEmpty(filters.events) && metricsCashiersLoading === false) {
      unsubscribe = subscribeToMoreMetricsCashiers({
        document: METRICS_CASHIER_OVERVIEWS_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.metrics?.cashierOverviews) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: {
              cashierOverviews: subscriptionData?.data?.metrics?.cashierOverviews
            }
          });
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [subscribeToMoreMetricsCashiers, filters, metricsCashiersLoading, CASHIER_OVERVIEWS_DISABLED]);
  ///// END CASHIERS /////

  ///// LOTS /////
  const {
    data: metricsLotOverviewsData,
    loading: metricsLotOverviewsLoading,
    subscribeToMore: subscribeToMoreMetricsLotOverviews,
    refetch: refetchMetricsLotOverviews
  } = useQuery(METRICS_LOT_OVERVIEWS_QUERY, {
    variables: filters,
    skip: LOT_OVERVIEWS_DISABLED || (!pageLoaded && !isNotEmpty(filters.events)),
    errorPolicy: 'all',
    onError: (error) => {
      console.error(error);
      handleError('Error getting lots');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (!LOT_OVERVIEWS_DISABLED && isNotEmpty(filters.events) && metricsLotOverviewsLoading === false) {
      unsubscribe = subscribeToMoreMetricsLotOverviews({
        document: METRICS_LOT_OVERVIEWS_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.metrics?.lotOverviews) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: {
              lotOverviews: subscriptionData?.data?.metrics?.lotOverviews
            }
          });
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [subscribeToMoreMetricsLotOverviews, filters, metricsLotOverviewsLoading, LOT_OVERVIEWS_DISABLED]);
  ///// END LOTS /////

  ///// REDEEMED PREPAIDS /////
  const {
    data: metricsRedeemedPrepaidData,
    loading: metricsRedeemedPrepaidLoading,
    subscribeToMore: subscribeToMoreMetricsRedeemedPrepaid,
    refetch: refetchMetricsRedeemedPrepaid
  } = useQuery(INCLUDE_EVENT_BREAKDOWN ? METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_QUERY : METRICS_REDEEMED_PREPAID_QUERY, {
    variables: filters,
    skip: REDEEMED_PREPAID_DISABLED || (!pageLoaded && !isNotEmpty(filters.events)),
    errorPolicy: 'all',
    onError: (error) => {
      console.error(error);
      handleError('Error getting prepaids');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (!REDEEMED_PREPAID_DISABLED && isNotEmpty(filters.events) && metricsRedeemedPrepaidLoading === false) {
      unsubscribe = subscribeToMoreMetricsRedeemedPrepaid({
        document: INCLUDE_EVENT_BREAKDOWN ? METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_SUBSCRIPTION : METRICS_REDEEMED_PREPAID_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.metrics?.redeemedPrepaid) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: {
              redeemedPrepaid: subscriptionData?.data?.metrics?.redeemedPrepaid
            }
          });
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [subscribeToMoreMetricsRedeemedPrepaid, filters, metricsRedeemedPrepaidLoading, REDEEMED_PREPAID_DISABLED, INCLUDE_EVENT_BREAKDOWN]);
  ///// END REDEEMED PREPAIDS /////

  ///// TRANSACTIONS /////
  const {
    data: transactionsData,
    loading: transactionsLoading,
    subscribeToMore: subscribeToMoreTransactions,
    refetch: refetchTransactions
  } = useQuery(TRANSACTIONS_QUERY, {
    variables: filters,
    skip: !isNotEmpty(filters.events),
    errorPolicy: 'all',
    onError: (error) => {
      console.error(error);
      handleError('Error getting transactions');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (isNotEmpty(filters.events) && transactionsLoading === false) {
      unsubscribe = subscribeToMoreTransactions({
        document: TRANSACTIONS_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data?.transactions) {
            return prev;
          }

          if (isNotEmpty(prev.transactions)) {
            return Object.assign({}, prev, {
              transactions: uniqBy([subscriptionData.data.transactions, ...prev.transactions], 'id')
            });

          } else {
            return {
              transactions: [subscriptionData.data.transactions]
            };
          }
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }

  }, [filters, subscribeToMoreTransactions, transactionsLoading]);
  ///// END TRANSACTIONS /////

  useEffect(() => {
    setIsLoading([
      filtersLoading,
      metricsLoading,
      metricsCashiersLoading,
      metricsLotOverviewsLoading,
      metricsRedeemedPrepaidLoading,
      transactionsLoading
    ].includes(true));
  }, [
    setIsLoading,
    filtersLoading,
    metricsLoading,
    metricsCashiersLoading,
    metricsLotOverviewsLoading,
    metricsRedeemedPrepaidLoading,
    transactionsLoading
  ]);

  useEffect(() => {
    if (isNotEmpty(filters) && pageLoaded) {
      refetchMetrics(filters);
      !CASHIER_OVERVIEWS_DISABLED && refetchMetricsCashiers(filters);
      !LOT_OVERVIEWS_DISABLED && refetchMetricsLotOverviews(filters);
      !REDEEMED_PREPAID_DISABLED && refetchMetricsRedeemedPrepaid(filters);
      refetchTransactions(filters);
    }
  }, [
    filters,
    refetchMetrics,
    refetchMetricsCashiers,
    refetchMetricsLotOverviews,
    refetchMetricsRedeemedPrepaid,
    refetchTransactions,
    pageLoaded,
    CASHIER_OVERVIEWS_DISABLED,
    LOT_OVERVIEWS_DISABLED,
    REDEEMED_PREPAID_DISABLED
  ]);

  function handleError(message) {
    addMessage({
      children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; {message}</>,
      action: 'dismiss'
    });
  }

  function formatFilterData(list) {
    return list.map(item => item.selected ? item.id : null)
      .filter(item => item !== null);
  }

  function handleApply(list, filterKey) {
    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: list
    }));
  }

  return (
    <div id="overview">
      <div className="linear-progress-container">
        {isLoading &&
          <LinearProgress id="progress-indicator" />
        }
      </div>
      <div className="wrapper">
        <section className="top-action-bar">
          {filtersLoading && (
            <ChipLoading />
          )}
          {!filtersLoading && isNotEmpty(filtersData) && (
            <FilterBar>
              {isNotEmpty(filtersData.filters.landmarks) && (
                <FilterChip
                  label="Locations"
                  id="overview-landmarks-filter"
                  data={filtersData.filters.landmarks}
                  filterKey="landmarks"
                  onApply={handleApply}
                />
              )}
              {isNotEmpty(filtersData.filters.events) && (
                <FilterChip
                  label="Events"
                  id="overview-event-filter"
                  data={filtersData.filters.events}
                  filterKey="events"
                  onApply={handleApply}
                />
              )}
              {isNotEmpty(filtersData.filters.lots) && (
                <FilterChip
                  label="Lots"
                  id="overview-lots-filter"
                  data={filtersData.filters.lots}
                  filterKey="lots"
                  onApply={handleApply}
                />
              )}
            </FilterBar>
          )}
        </section>
        <OverviewSections
          metrics={metricsData?.metrics}
          cashiers={metricsCashiersData?.metrics?.cashierOverviews}
          lotOverviews={metricsLotOverviewsData?.metrics?.lotOverviews}
          redeemedPrepaid={metricsRedeemedPrepaidData?.metrics?.redeemedPrepaid}
          transactions={transactionsData?.transactions}
          filters={filtersData?.filters}
          selectedFilters={filters}
          refetchTransactions={refetchTransactions}
          filtersLoading={filtersLoading}
          metricsLoading={metricsLoading}
          cashiersLoading={metricsCashiersLoading}
          lotOverviewsLoading={metricsLotOverviewsLoading}
          redeemedPrepaidLoading={metricsRedeemedPrepaidLoading}
          transactionsLoading={transactionsLoading}
        />
      </div>
    </div>
  );
};

export default Overview;
