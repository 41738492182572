import { useState, useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import { Radio, useChoice } from 'react-md';
import { Modal, SearchInput, Card } from '../';
import PrepaidsGraphBar from './PrepaidsGraphBar';
import { useDebounce } from '../../hooks';
import { isNotEmpty, numberWithCommas, FUSE_OPTIONS } from '../../utils';

const FUSE_CONFIG = {
  ...FUSE_OPTIONS,
  keys: [
    'lotSegmentation.name',
    'lotSegmentation.subsegment.name',
    'sourceSegmentation.name',
    'name'
  ]
};

const ExpandedView = ({ visible, onClose, data }) => {
  const { eventBreakdown = [] } = data;
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSegmentBy, handleSegmentByChange] = useChoice('sourceSegmentation');
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);

  useEffect(() => {
    if (!visible) {
      setSearchTerm('');
    }
  }, [visible]);

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  const eventBreakdowns = useMemo(() => {
    if (isNotEmpty(eventBreakdown)) {
      const fuse = new Fuse(eventBreakdown, FUSE_CONFIG);
      const results = searchTerm.length > 0 ? fuse.search(searchTerm).map(result => result.item) : eventBreakdown;

      return results;
    }

    return [];
  }, [eventBreakdown, searchTerm]);

  const segmentTotal = (list) => list.reduce((accumulator, current) => accumulator + current.sold, 0);

  return (
    <Modal
      id="expanded-prepaids-modal"
      visible={visible}
      closeModal={onClose}
      headline="Prepaids by Event"
    >
      {isNotEmpty(eventBreakdown) ? (
        <>
          <SearchInput
            placeholder="Search events"
            onChange={handleSearch}
          />
          <form name="prepaid-segmentation-form" className="segmentation-form">
            <div className="segmentation-controls">
              <div className="segmentation-form-label">
                Segment by
              </div>
              <Radio
                data-id="prepaid-fs-sources"
                id="source"
                name="segment-radios"
                label="Source"
                value="sourceSegmentation"
                onChange={handleSegmentByChange}
                checked={currentSegmentBy === 'sourceSegmentation'}
              />
              <Radio
                data-id="prepaid-fs-lots"
                id="lots"
                name="segment-radios"
                label="Lots"
                value="lotSegmentation"
                onChange={handleSegmentByChange}
                checked={currentSegmentBy === 'lotSegmentation'}
              />
            </div>
          </form>
          <div className="segmentation-card-container">
            {eventBreakdowns.map((breakdown, index) => {
              if (isNotEmpty(breakdown[currentSegmentBy])) {
                return (
                  <Card
                    className="segmentation-card"
                    key={`${breakdown.key}-${index}`}
                  >
                    <div className="segmentation-card-header segmentation-card-section">
                      {breakdown.name &&
                        <div className="segmentation-card-header-name">
                          {breakdown.name}
                        </div>
                      }
                    </div>
                    <div className="segmentation-graph-container segmentation-card-section">
                      {breakdown &&
                        <PrepaidsGraphBar
                          data={breakdown[currentSegmentBy]}
                          formatter={numberWithCommas}
                          valueLeftKey="redeemed"
                          valueRightKey="sold"
                          valueLabel="name"
                          segment={currentSegmentBy}
                          totalValue={segmentTotal(breakdown[currentSegmentBy])}
                          indexKey={`${breakdown.key}-${currentSegmentBy}-passes`}
                          hideUnknownSegment={currentSegmentBy === 'lotSegmentation'}
                        />
                      }
                    </div>
                  </Card>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      ) : (
        <div className="segmentation-card-container">
          <Card className="segmentation-card">
            <div className="segmentation-card-header segmentation-card-section">
              <h3 className="data-unavailable-header">
                Live Prepaid Data Temporarily Unavailable.
                <br />
                Feature Coming Soon!
              </h3>
              <p className="data-unavailable">
                We are working on an update that will provide you with a prepaid breakdown by lot.
                If you need number of prepaids sold, please check with your ticketing partner.
                <br />
                For redeemed prepaid numbers, Analytics will have what you need (<strong>Note:</strong> Data is 15 minute delayed in Analytics).
              </p>
            </div>
          </Card>
        </div>
      )}
    </Modal>
  );
};

export default ExpandedView;
