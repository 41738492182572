import { createContext, useContext, useState } from 'react';

const CashiersContext = createContext([{}, () => { }]);

export const CashiersContextProvider = ({ children }) => {
  const initialCashLogs = {
    cashDrops: null, // Array of objects when populated
    cashOut: null, // Object when populated
    countCashTransactions: 0,
    expectedCashOnHand: 0,
    initialBank: 0,
    totalCashCollected: 0
  };
  const [activeForm, setActiveForm] = useState('');
  const [cashLogs, setCashLogs] = useState(initialCashLogs);
  // Possible states: idle, fetching, success, loaded, failure
  const [cashLogsState, setCashLogsState] = useState('idle');
  const [cashOutNoticeConfirmed, setCashOutNoticeConfirmed] = useState(false);
  const [currentDialog, setCurrentDialog] = useState('');
  const [currentFormData, setCurrentFormData] = useState(null);
  const [selectedCashier, setSelectedCashier] = useState({});
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [expandedVisible, setExpandedVisible] = useState(false);

  const closeDialog = () => {
    setExpandedVisible(false);
    setActiveForm('');
    setCashLogs(initialCashLogs);
    setCashLogsState('idle');
    setCashOutNoticeConfirmed(false);
    setCurrentDialog(null);
    setCurrentFormData(null);
    setSelectedCashier({});
    setSelectedEvent({});
    setSelectedTransaction(null);
  };

  return (
    <CashiersContext.Provider
      value={{
        activeForm,
        cashLogs,
        cashLogsState,
        cashOutNoticeConfirmed,
        closeDialog,
        currentDialog,
        currentFormData,
        selectedCashier,
        selectedEvent,
        selectedTransaction,
        setActiveForm,
        setCashLogs,
        setCashLogsState,
        setCashOutNoticeConfirmed,
        setCurrentDialog,
        setCurrentFormData,
        setSelectedCashier,
        setSelectedEvent,
        setSelectedTransaction,
        expandedVisible,
        setExpandedVisible
      }}
    >
      {children}
    </CashiersContext.Provider>
  );
};

export const useCashiersContext = () => useContext(CashiersContext);
