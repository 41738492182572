import { useCallback, useEffect, useRef, useState } from 'react';
import { checkAuth, getSessionTime, refreshSession, signOut } from '../../utils';
import { Layout } from '../';

const App = () => {
  const checkTime = useRef(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);

          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                setIsAuthorized(false);
                signOut('?expired=true');

              } else if (minutes > 10 && minutes < 1440) {
                refreshSession();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, []
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);

      } else {
        startTimeout();
        setIsAuthorized(true);
      }
    }, [startTimeout]
  );

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  return (
    <>
      {isAuthorized &&
        <Layout />
      }
    </>
  );
};

export default App;
