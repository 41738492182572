import './ChipLoading.scss';

const ChipLoading = () => {
  return (
    <div className="filter-bar">
      <div className="chip-loading" />
      <div className="chip-loading" />
      <div className="chip-loading" />
    </div>
  );
};

export default ChipLoading;
