import moment from 'moment';
import { FontIcon } from 'react-md';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useOverviewSectionsContext, useMetricListContext } from '../../hooks';
import { limitListSize, isNotEmpty, currencyFormat } from '../../utils';
import { RefundIcon } from '../../assets/react-icons';
import cx from 'classnames';

const MetricListTransactions = ({ listLimit = 0 }) => {
  const { transactions = [] } = useOverviewSectionsContext();
  const { animationTimeout } = useMetricListContext();
  const transactionIcons = {
    failed: <FontIcon style={{ color: '#ef5350' }}>highlight_off</FontIcon>,
    pending: <FontIcon style={{ color: '#fdd835' }}>error_outline</FontIcon>,
    successful: <FontIcon style={{ color: '#00c853' }}>check_circle_outline</FontIcon>,
    refunded: <RefundIcon color="#0c07ef" width={24} />
  };

  const groupProps = {
    enter: true,
    appear: true,
    exit: false
  };

  return (
    <div className="metric-list metric-list-transactions">
      <table>
        <thead>
          <tr>
            <td />
            <td>Type</td>
            <td>Price</td>
            <td>Cashier</td>
            <td>Time</td>
          </tr>
        </thead>
        <TransitionGroup
          {...groupProps}
          component="tbody"
        >
          {isNotEmpty(transactions) && limitListSize(transactions, listLimit).map(
            ({
              status = '',
              transactionId = '',
              transactionTime = '',
              transactionType = '',
              userName = '',
              refund,
              passSource = '',
              total = 0
            }) => {
              const transactionDisplay = transactionType === 'pass' ? 'prepaid' : transactionType;
              const userDisplay = transactionType === 'pass' ? passSource : userName;

              return (
                <CSSTransition key={`transition-${transactionId}`} timeout={animationTimeout} classNames="fade">
                  <tr
                    key={`${transactionId}`}
                    className={
                      cx(
                        'metric-list-row transactions',
                        refund ? 'refunded' : status
                      )}
                  >
                    <td className="metric-list-column status-icon">{refund ? transactionIcons.refunded : transactionIcons[status]}</td>
                    <td className="metric-list-column clipped">
                      <span className="metric-list-cell-top" title={transactionType}>
                        {transactionDisplay || '—'}
                      </span>
                    </td>
                    <td className="metric-list-column clipped">
                      <span className="metric-list-cell-top">{currencyFormat(total)}</span>
                    </td>
                    <td className="metric-list-column truncate cashier-name">
                      <span className="metric-list-cell-top" title={userDisplay}>{userDisplay || '_'}</span>
                    </td>
                    <td className="metric-list-column truncate transaction-time">
                      <span className="metric-list-cell-top no-cap">{moment(transactionTime).format('h:mm a')}</span>
                    </td>
                  </tr>
                </CSSTransition>
              );
            }
          )}
        </TransitionGroup>
      </table>
    </div>
  );
};

export default MetricListTransactions;
