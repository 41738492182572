import axios from 'axios';
import { useEffect, useState } from 'react';

const useGetCashLogs = (cashierId, eventId) => {
  const [logs, setLogs] = useState({});
  const [errorResponse, setErrorResponse] = useState(null);
  const [fetchState, setFetchState] = useState('');

  useEffect(() => {
    if (cashierId && eventId) {
      const rootUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/transactions`;
      const connector = rootUrl.charAt(rootUrl.length - 1) === '/' ? '' : '/';
      const urlQuery = `cash-logs?cashierLegacyId=${cashierId}&eventId=${eventId}`;
      const fetchUrl = `${rootUrl}${connector}${urlQuery}`;
      const transport = axios.create({
        withCredentials: true
      });

      const dateToEpoch = date => new Date(date).getTime();
      const sortByDate = arr => arr.sort((a, b) => (dateToEpoch(a.created) < dateToEpoch(b.created) ? -1 : 1));

      const fetchCashLogs = async () => {
        setFetchState('fetching');

        await transport
          .get(fetchUrl)
          .then(res => res.data)
          .then(data => {
            const cashierData = data ?? {};
            const { cashDrops, cashOut, initialBank = 0 } = cashierData;
            const counts = cashOut?.counts || [];
            const sortedCounts = counts.length ? sortByDate(counts) : null;
            const cashOutItem = counts.length ? sortedCounts[0] : null;
            const recounts = counts.length > 1 ? sortedCounts.slice(1) : null;
            const sortedCashDrops = cashDrops ? sortByDate(cashDrops) : null;
            const bankAtCashOut = sortedCashDrops ? sortedCashDrops[sortedCashDrops.length - 1].remainder : initialBank;
            const cashFromDrops = cashDrops ? cashDrops.reduce((a, b) => a + b.amount, 0) : 0;

            setLogs({ ...data, bankAtCashOut, cashFromDrops, cashOutItem, recounts });
            setFetchState('success');
          })
          .catch((err = {}) => {
            const { response = {} } = err;
            const { data = {}, status = 0, statusText = '' } = response;
            console.error(err, response);
            setErrorResponse({ message: data.message, status, statusText });
            setFetchState('error');
          });
      };

      fetchCashLogs();
    }
  }, [cashierId, eventId]);

  return { logs, fetchState, errorResponse };
};

export default useGetCashLogs;
