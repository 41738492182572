import React from 'react';

const PersonIconCircled = props => (
  <svg viewBox="0 0 32 32" fill="none" {...props}>
    <circle
      cx="16"
      cy="16"
      r="16"
      fill="currentColor"
      fillOpacity=".2"/>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      clipRule="evenodd" />
  </svg>
);

export default PersonIconCircled;