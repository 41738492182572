import { DonutChart, EmptyChart } from '../';
import { isNotEmpty, percentFormat } from '../../utils';

const VipsParked = ({ data = {} }) => {
  const dataAvailable = isNotEmpty(data) && data.value > 0;
  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];

      if (data.parked) {
        formattedArray.push({
          name: 'Parked',
          y: data.parked,
          color: '#fbd648',
          selected: true
        });
      }

      if (data.expected > data.parked) {
        const expected = data.expected - data.parked;

        formattedArray.push({
          name: 'Available',
          y: expected,
          color: '#eaebec',
          selected: true
        });
      }

      return formattedArray;
    })();
  }

  return dataAvailable ? (
    <DonutChart data={formattedData} name="VIPs" subtitle="VIPs Parked" total={percentFormat(data.value, 0)} />
  ) : (
    <EmptyChart />
  );
};

export default VipsParked;
