import { useState } from 'react';
import { Lock } from '../../assets/react-icons';
import { Tooltip } from '../';
import { stringToKebabCase } from '../../utils';

const TextInput = ({
  handleChange,
  id = '',
  label = 'Label required',
  placeholder = '',
  readonly = false,
  required = false,
  tooltipText,
  type = 'text',
  value = ''
}) => {
  const [fieldState, setFieldState] = useState('');
  const uniqueId = id || stringToKebabCase(label);

  return (
    <fieldset className={`text-input-fieldset ${fieldState}`}>
      <label className="text-input-label" htmlFor={uniqueId}>
        <span className="text-input-label-text">
          {label}
          {readonly && <Lock />}
          {required && <sup aria-label="This input is required.">*</sup>}
          {tooltipText && <Tooltip id={id} text={tooltipText} />}
        </span>
        <input
          className="text-input"
          id={uniqueId}
          name={uniqueId}
          onBlur={() => setFieldState('')}
          onChange={e => handleChange(e.target.value)}
          onFocus={() => setFieldState('focused')}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          type={type}
          value={value}
        />
      </label>
    </fieldset>
  );
};

export default TextInput;
