import React, { useState, createContext, useContext } from 'react';

const OverviewSectionsContext = createContext([{}, () => { }]);

export const OverviewSectionsContextProvider = ({
  children,
  metrics,
  cashiers,
  lotOverviews,
  redeemedPrepaid,
  transactions,
  filters,
  filtersLoading,
  metricsLoading,
  cashiersLoading,
  lotOverviewsLoading,
  redeemedPrepaidLoading,
  transactionsLoading,
  selectedFilters,
  refetchTransactions
}) => {
  const [featureInDevelopmentModalConfig, setFeatureInDevelopmentModalConfig] = useState({ title: '', visible: false });

  return (
    <OverviewSectionsContext.Provider
      value={{
        events: filters?.events,
        metrics,
        transactions,
        filters,
        cashiers,
        redeemedPrepaid,
        lotOverviews,
        featureInDevelopmentModalConfig,
        setFeatureInDevelopmentModalConfig,
        filtersLoading,
        metricsLoading: [filtersLoading, metricsLoading].includes(true),
        cashiersLoading: [filtersLoading, cashiersLoading].includes(true),
        lotOverviewsLoading: [filtersLoading, lotOverviewsLoading].includes(true),
        redeemedPrepaidLoading: [filtersLoading, redeemedPrepaidLoading].includes(true),
        transactionsLoading: [filtersLoading, transactionsLoading].includes(true),
        selectedFilters,
        refetchTransactions
      }}
    >
      {children}
    </OverviewSectionsContext.Provider>
  );
};

export const useOverviewSectionsContext = () => useContext(OverviewSectionsContext);
