import moment from 'moment';
import { Modal, Table } from '../';
import { centsToCurrency, isNotEmpty } from '../../utils';
import { useCashiersReprintsContext } from '../../hooks';

const ExpandedReprints = ({ visible }) => {
  const { closeModal, goBack, cashierName: cashier, cashierReprints: reprints } = useCashiersReprintsContext();

  const fullReprints = reprints.map(reprint => {
    return {
      lot: reprint.lotName ?? '-',
      amount: reprint.amount ?? '-',
      cashierName: reprint?.userName ?? '-',
      transactionEvent: reprint?.transactionEventName ?? '-',
      printEvent: reprint?.eventName ?? '-',
      transactionTime: moment(reprint.transactionTime).format('MM/DD, h:mm A') || '-',
      reprintTime: moment(reprint.printTime).format('MM/DD, h:mm A') || '-'
    };
  });

  const body = fullReprints.map(item => {
    const { lot, amount, transactionEvent, cashierName, printEvent, transactionTime, reprintTime } = item;

    return {
      lot: {
        displayValue: lot || '-',
        sortValue: lot || '-',
        truncateValue: 8
      },
      amount: {
        displayValue: centsToCurrency(amount) || '-',
        sortValue: amount || 0
      },
      cashierName: {
        displayValue: cashierName || '-',
        sortValue: cashierName || '-',
        truncateValue: 9
      },
      transactionEvent: {
        displayValue: transactionEvent || '-',
        sortValue: transactionEvent || '-',
        truncateValue: 16
      },
      printEvent: {
        displayValue: printEvent || '-',
        sortValue: printEvent || '-',
        truncateValue: 16
      },
      transactionTime: {
        displayValue: transactionTime || '-',
        sortValue: transactionTime || '-'
      },
      reprintTime: {
        displayValue: reprintTime || '-',
        sortValue: reprintTime || '-'
      }
    };
  }).sort((a, b) => (a.lot.sortValue.toLowerCase() > b.lot.sortValue.toLowerCase() ? 1 : -1));

  const head = [{
    id: 'lot',
    label: 'Lot',
    sortable: true
  },
  {
    id: 'amount',
    label: 'Amount',
    sortable: true
  },
  {
    id: 'cashierName',
    label: 'Cashier',
    sortable: true
  },
  {
    id: 'transactionEvent',
    label: 'Transaction Event',
    sortable: true
  },
  {
    id: 'printEvent',
    label: 'Print Event',
    sortable: true
  },
  {
    id: 'transactionTime',
    label: 'Transaction Time',
    sortable: true
  },
  {
    id: 'reprintTime',
    label: 'Reprint Time',
    sortable: true
  }];

  return (
    <Modal
      id="expanded-reprints-modal"
      visible={visible}
      closeModal={closeModal}
      goBack={goBack}
      headline={`${cashier} Reprints`}
    >
      {isNotEmpty(fullReprints) ? (
        <Table
          className="reprints-table"
          head={head}
          body={body}
        />
      ) : (
        <p className="cashier-reprints-no-data">No records to show</p>
      )}
    </Modal>
  );
};

export default ExpandedReprints;
