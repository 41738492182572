import { useCashiersContext } from '../../hooks';
import { centsToCurrency, localTimeFromDate } from '../../utils';

const CashRecountItems = () => {
  const { cashLogs = {} } = useCashiersContext();
  const { cashOutItem = {}, recounts = [], totalCashCollected = 0 } = cashLogs;

  return recounts.map((count, index) => {
    const { amount = 0, comments, counterDisplayName, created } = count;
    const previousAmount = index ? recounts[index - 1].amount : cashOutItem.amount;
    const discrepancy = amount - totalCashCollected;

    const details = [
      `${centsToCurrency(previousAmount)} was the previous amount (excluding bank)`,
      `${centsToCurrency(amount)} is the new amount`,
      `${centsToCurrency(discrepancy)} difference`,
      `Comment: ${comments || 'No comment provided'}`
    ];

    return (
      <li className="cash-management-item" key={created}>
        <p className="cash-management-action">
          <b>{counterDisplayName}</b> performed a recount.
        </p>
        <time className="cash-management-time" dateTime={created}>
          {localTimeFromDate(created)}
        </time>
        <ul className="cash-management-details">
          {details.map((detail, i) => (
            <li className="cash-management-details-item" key={`recount-detail-${i + 1}}`}>
              {detail}
            </li>
          ))}
        </ul>
      </li>
    );
  });
};

export default CashRecountItems;
