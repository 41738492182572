import { gql } from '@apollo/client';

const REFUNDS_FRAGMENT = gql`
fragment RefundsFragment on TransactionWithRefunds {
    refunds {
      action
      comments
      employeeName
      performedAt
      reason
    }
  }
`;

const TRANSACTIONS_FRAGMENT = gql`
  fragment TransactionFragment on TransactionWithRefunds {
    id: transactionId
    transactionId
    expiry
    userName
    cardType
    lotId
    lotName
    eventId
    pricingId
    pricingName
    total
    qrCode
    confirmationCode
    status
    refundStatus
    type
    employeeId
    transactionTime
    transactionType
    passSource
    permitId
    exemptionReason
    vipParkerDisplayName
    refund
    refundStatus
    admitted
    subtype
    eventName
    cardholderName
    cardType
    expiry
    lastFour
    failureMessage
    offlineFailureMessage
    offlineStatus
    reason
    permitName
    externalTransaction {
      accountId,
      customerName,
      source
    }
    permit {
      affiliation
      classification
      classificationSub
    }
  }
`;

export const TRANSACTIONS_QUERY = gql`
  ${TRANSACTIONS_FRAGMENT}

  query TransactionsQuery(
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
  ) {
    transactions(
      events: $events
      lots: $lots
      landmarks: $landmarks
      keyword: $keyword
      limit: $limit,
      offset: $offset,
      order: $order,
      bypassCache: $bypassCache
    ) {
      ...TransactionFragment
    }
  }
`;


export const TRANSACTIONS_SUBSCRIPTION = gql`
  subscription TransactionsSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
  ) {
    transactions(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      id: transactionId
      transactionId
      expiry
      userName
      cardType
      lotId
      lotName
      eventId
      externalTransaction {
        accountId,
        customerName,
        source
      }
      pricingId
      pricingName
      total
      qrCode
      confirmationCode
      status
      refundStatus
      type
      employeeId
      transactionTime
      transactionType
      passSource
      permitId
      exemptionReason
      vipParkerDisplayName
      refund
      refundStatus
      admitted
      subtype
      eventName
      cardholderName
      cardType
      expiry
      lastFour
      failureMessage
      offlineFailureMessage
      offlineStatus
      reason
      permitName
      permit {
        affiliation
        classification
        classificationSub
      }
    }
  }
`;

export const TRANSACTION_BY_ID = gql`
  ${TRANSACTIONS_FRAGMENT}
  ${REFUNDS_FRAGMENT}

  query TransactionById(
    $transactionId: ID
  ) {
    transactions(
      transactionId: $transactionId
    ) {
      ...TransactionFragment
      ...RefundsFragment
    }
  }
`;
