import { Button } from 'react-md';
import cx from 'classnames';

const DialogButton = ({ label, className, ...props }) => (
  <Button
    className={cx('dialog-button', className)}
    themeType="outline"
    {...props}
  >
    {label}
  </Button>
);

export default DialogButton;
