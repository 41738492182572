export const dateFormatter = (date, options = {}) => new Intl.DateTimeFormat('default', options).format(date);

export const dateTimeReprint = date => {
  const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  return dateFormatter(date, options);
};

// export const dateCompact = date => {
//   const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };

//   return dateFormatter(date, options);
// };

export const dateTimeCompact = date => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };

  return dateFormatter(date, options);
};

export const dateTimeShort = date => {
  const options = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  };

  return dateFormatter(date, options);
};

export const isValidDate = date => {
  const timestamp = new Date(date).toString().toLowerCase();

  return timestamp !== 'invalid date';
};

// The following will be the answer once Safari adopts timeStyle and dateStyle
// export const dateTimeShort = date => dateFormatter(date, { dateStyle: 'short', timeStyle: 'short' });

export const timeShort = date => dateFormatter(date, { hour: 'numeric', minute: 'numeric' });
