import { useState } from 'react';
import Odometer from 'react-odometerjs';
import { ReprintIcon } from '../../assets/react-icons';
import ReprintsDialogs from './ReprintsDialogs';
import { ChartCard, TopMetric, EmptyChart } from '../';
import { useOverviewSectionsContext } from '../../hooks';
import ExpandedView from './ExpandedView';
import ReprintsChart from './ReprintsChart';
import { isNotEmpty } from '../../utils';
import './Reprints.scss';

const ReprintsChartCard = ({ compact }) => {
  const [expanded, setExpanded] = useState(false);
  const { metricsLoading, metrics = {} } = useOverviewSectionsContext();

  return (
    <>
      <ChartCard
        loading={metricsLoading}
        type={compact ? 'compact' : 'metric'}
        onClick={() => setExpanded(true)}
        className="indicate-focus-state reprints__pendo"
        data-id="reprints__pendo"
      >
        {!metricsLoading && metrics?.reprintCount ? (
          <>
            <TopMetric
              className={compact ? 'top-metric-compact' : ''}
              value={<Odometer duration={100} value={metrics?.reprintCount?.value} format="(,ddd)" />}
              label="Reprints"
              icon={<ReprintIcon width="25" color="#EC407A" />}
            />
            <br />
            {isNotEmpty(metrics?.reprintCount) && <ExpandedView visible={expanded} setVisible={setExpanded} />}
            {!compact && <ReprintsChart data={metrics?.reprintCount?.reprints} />}
          </>
        ) : (
          !metricsLoading && <EmptyChart small center />
        )}
      </ChartCard>
      <ReprintsDialogs />
    </>
  );
};

export default ReprintsChartCard;
