import { useEffect, useState } from 'react';
import { Button } from 'react-md';
import { CurrencyInputField, TextInput } from '../';
import { useCashiersContext } from '../../hooks';

const CashRecountForm = () => {
  const {
    cashLogs: { cashOut = {}, totalCashCollected = 0 } = {},
    currentFormData,
    setActiveForm,
    setCurrentDialog,
    setCurrentFormData
  } = useCashiersContext();

  const { bagId = '', counts = [], transactionExternalId = '' } = cashOut;
  const previousCount = counts[counts.length - 1];
  const previousAmount = previousCount.amount || 0;

  const startingEditableValues = {
    newTotal: currentFormData?.newTotal || '',
    comments: currentFormData?.comments || ''
  };

  const [formStatus, setFormStatus] = useState('active');
  const [newTotal, setNewTotal] = useState(startingEditableValues.newTotal);
  const [comments, setComments] = useState(startingEditableValues.comments);

  useEffect(() => setActiveForm('recount'), [setActiveForm]);

  const submitForm = e => {
    e.preventDefault();
    setFormStatus('submitting');
    setCurrentFormData({
      bagId,
      expectedCashRevenue: totalCashCollected,
      newTotal,
      comments,
      total: previousAmount,
      transactionExternalId
    });
    setCurrentDialog('form review');
  };

  return (
    <form className="modal-form" onSubmit={submitForm}>
      <CurrencyInputField
        cents={totalCashCollected ?? 0}
        label="Expected Cash Revenue"
        placeholder="$0"
        readonly
        tooltipText="Expected Cash Revenue is calculated based on transactions, and it doesn't include bank amount."
      />
      <CurrencyInputField
        cents={previousAmount}
        label="Total Cash Revenue Collected (excluding bank)"
        placeholder="$0"
        readonly
        tooltipText="Total cash revenue collected from cash out."
      />
      <CurrencyInputField
        allowZero={false}
        cents={newTotal ?? 0}
        handleChange={setNewTotal}
        label="New Cash Collected Amount"
        placeholder="$0"
        required
        tooltipText="New Total cash collected amount. It should include cash counts from previous cash drops and excludes initial bank amount."
      />
      <TextInput label="Bag ID" readonly value={bagId} />
      <TextInput handleChange={setComments} label="Comments" placeholder="..." value={comments} />
      <p className="modal-form-note">
        <sup>*</sup> Required fields
      </p>
      <Button
        disabled={formStatus !== 'active'}
        theme="primary"
        themeType="contained"
        className="modal-form-submit-button"
        type="submit"
      >
        Update Cash Count
      </Button>
    </form>
  );
};

export default CashRecountForm;
