import { useState } from 'react';
import { QuadIconCircled } from '../../assets/react-icons';
import { useOverviewSectionsContext } from '../../hooks';
import { ChartCard, DialogButton, EmptyChart, MetricListLots } from '../';
import ExpandedView from './ExpandedView';

const LotsGaragesCard = () => {
  const { lotOverviews = [], lotOverviewsLoading } = useOverviewSectionsContext();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ChartCard
        icon={<QuadIconCircled width="24" color="#6543bc" />}
        loading={lotOverviewsLoading}
        className="lots-card lots__pendo"
        data-id="lots__pendo"
        label="Lots/Garages"
        type="list"
      >
        {lotOverviews?.length ? (
          <>
            <MetricListLots listLimit={8} />
            <div className="chart-card-action-container">
              {lotOverviews?.length >= 1 && (
                <DialogButton label="View all lots / garages" onClick={() => setExpanded(true)} />
              )}
            </div>
            <ExpandedView
              setVisible={setExpanded}
              visible={expanded}
            />
          </>
        ) : (
          !lotOverviewsLoading && <EmptyChart center />
        )}
      </ChartCard>
    </>
  );
};

export default LotsGaragesCard;
