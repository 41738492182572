import { useState } from 'react';
import { CheckIcon } from '../../assets/react-icons';
import { useMetricListContext, useOverviewSectionsContext } from '../../hooks';
import { ChartCard, DialogButton, EmptyChart, MetricListTransactions } from '../';
import { isNotEmpty } from '../../utils';
import TransactionDialogs from './TransactionDialogs';
import ExpandedView from './ExpandedView';
import './TransactionsCard.scss';

const TransactionsCard = () => {
  const { listLimit } = useMetricListContext();
  const { transactions = [], events, transactionsLoading } = useOverviewSectionsContext();
  const [expandedVisible, setExpandedVisible] = useState(false);

  return (
    <>
      <ChartCard
        loading={transactionsLoading}
        className="list transactions-card recent-transactions__pendo"
        data-id="recent-transactions__pendo"
        label="Recent Transactions"
        type="list"
        icon={<CheckIcon width="24" color="#e7e7e7" />}
      >
        {!transactionsLoading && isNotEmpty(transactions) && !!events ? (
          <>
            <MetricListTransactions listLimit={listLimit} />
            <div className="chart-card-action-container">
              {transactions?.length >= 1 &&
                <DialogButton
                  className="view-transactions-button"
                  label="View all transactions"
                  onClick={() => setExpandedVisible(true)}
                />
              }
            </div>
          </>
        ) : (
          !transactionsLoading && <EmptyChart center />
        )}
        <ExpandedView
          transactionsLoading={transactionsLoading}
          transactions={transactions}
          events={events}
          setVisible={setExpandedVisible}
          visible={expandedVisible}
        />
      </ChartCard>
      <TransactionDialogs />
    </>
  );
};

export default TransactionsCard;
