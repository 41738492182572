import parse from 'html-react-parser';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { ArrowLeft } from '../../assets/react-icons';
import { InPortal, Overlay } from '../';
import { useClickOutside } from '../../hooks';
import './Modal.scss';

const Modal = ({
  children,
  closeButtonVisible,
  closeModal,
  closeOnOutsideClick,
  goBack,
  headline = '',
  id,
  maxWidth = '55rem',
  subhead = '',
  type,
  visible
}) => {
  const ref = useRef();

  useClickOutside(ref, closeModal, closeOnOutsideClick && visible);

  return (
    <InPortal id="dialog-portal">
      <CSSTransition
        in={visible}
        timeout={300}
        classNames="modal-fade"
        unmountOnExit
      >
        <Overlay visible={visible} />
      </CSSTransition>
      <CSSTransition
        in={visible}
        timeout={300}
        classNames="slide"
        unmountOnExit
      >
        <div id={id} className={`modal ${type}`} role="dialog">
          <section
            className="modal-content"
            ref={ref}
            style={maxWidth ? { maxWidth } : null}
          >
            <header className="modal-header">
              <h3 className="modal-headline">{headline}</h3>
              <p className="modal-subhead">{parse(subhead)}</p>
              {goBack && (
                <button aria-label="Go back" className="modal-back-button" onClick={goBack} type="button">
                  <ArrowLeft />
                </button>
              )}
              {closeButtonVisible && (
                <button aria-label="Close dialog modal" className="modal-close-button" onClick={closeModal} type="button" />
              )}
            </header>
            <div className="modal-body">{children}</div>
          </section>
        </div>
      </CSSTransition>
    </InPortal>
  );
};

Modal.defaultProps = {
  closeButtonVisible: true,
  closeOnOutsideClick: false,
  headline: '',
  subhead: '',
  type: 'tall',
  visible: false
};

Modal.propTypes = {
  children: PropTypes.node,
  closeButtonVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  closeOnOutsideClick: PropTypes.bool,
  goBack: PropTypes.func,
  headline: PropTypes.string,
  subhead: PropTypes.string,
  type: PropTypes.oneOf(['tall', 'contained']),
  visible: PropTypes.bool
};

export default Modal;
