import React from 'react';

const CodeIcon = props => (
  <svg width="132" height="83" viewBox="0 0 132 83" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M120.78 3.95238H11.22L11.22 73.7778H120.78V3.95238ZM11.22 0C9.03295 0 7.26 1.76954 7.26 3.95238V73.7778C7.26 75.9606 9.03295 77.7302 11.22 77.7302H120.78C122.967 77.7302 124.74 75.9606 124.74 73.7778V3.95238C124.74 1.76954 122.967 0 120.78 0H11.22Z" fill="currentColor"/>
    <path d="M54.019 30.0697L31.1302 40.417V43.1047L53.9292 53.452V49.6445L36.7402 41.7609L54.019 33.8772V30.0697Z" fill="currentColor"/>
    <path d="M74.3503 23.2611L71.1638 22.0964L57.3857 55.7365L60.5722 56.8115L74.3503 23.2611Z" fill="currentColor"/>
    <path d="M78.0701 33.8772L95.2591 41.7609L78.0701 49.6445V53.452L100.869 43.1047V40.417L78.0701 30.0697V33.8772Z" fill="currentColor"/>
    <path d="M0 81.0238C0 79.9324 0.886476 79.0476 1.98 79.0476H130.02C131.114 79.0476 132 79.9324 132 81.0238C132 82.1152 131.114 83 130.02 83H1.98C0.886474 83 0 82.1152 0 81.0238Z" fill="currentColor"/>
  </svg>
);

export default CodeIcon;
