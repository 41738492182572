import { Radio } from 'react-md';

const LotsViewOptions = ({ handleSegmentByChange, currentSegmentBy, handleDataTypeChange, currentDataType }) => (
  <form
    name="lot-segmentation-form"
    className="segmentation-form"
  >
    <div className="segmentation-controls">
      <span>Segmented by</span>
      <Radio
        data-id="lots-and-garages-fs-trx-type"
        id="transaction-type"
        name="segment-radios"
        label="Transaction type"
        value="transactionType"
        onChange={handleSegmentByChange}
        checked={currentSegmentBy === 'transactionType'}
      />
      <Radio
        data-id="lots-and-garages-fs-pricing"
        id="pricing"
        name="segment-radios"
        label="Pricings"
        value="pricings"
        onChange={handleSegmentByChange}
        checked={currentSegmentBy === 'pricings'}
      />
    </div>
    <div className="segmentation-controls">
      <span>Data type</span>
      <Radio
        data-id="lots-and-garages-fs-cars-parked"
        id="cars-parked"
        name="data-radios"
        label="Cars parked"
        value="parked"
        onChange={handleDataTypeChange}
        checked={currentDataType === 'parked'}
      />
      <Radio
        data-id="lots-and-garages-fs-revenue"
        id="revenue"
        name="data-radios"
        label="Revenue"
        value="revenue"
        onChange={handleDataTypeChange}
        checked={currentDataType === 'revenue'}
      />
    </div>
  </form>
);

export default LotsViewOptions;
