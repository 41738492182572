import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment RedeemedPrepaidsFragment on RedeemedPrepaids {
    value
    redeemed
    sold
    unredeemed
  }
`;

const FRAGMENTS_WITH_BREAKDOWN = gql`
  fragment RedeemedPrepaidsWithBreakdownFragment on RedeemedPrepaids {
    value
    redeemed
    sold
    unredeemed
    eventBreakdown {
      key: eventId
      name: eventName
      lotSegmentation {
        key: lotId
        name: lotName
        redeemed
        sold
        unredeemed
        subsegment: childLots {
          key: lotId
          name: lotName
          redeemed
          unredeemed
        }
      }
      sourceSegmentation {
        key: source
        name: source
        redeemed
        sold
        unredeemed
      }
    }
  }
`;

export const METRICS_REDEEMED_PREPAID_QUERY = gql`
  ${FRAGMENTS}

  query MetricsRedeemedPrepaidQuery(
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
    ) {
      redeemedPrepaid {
        ...RedeemedPrepaidsFragment
      }
    }
  }
`;

export const METRICS_REDEEMED_PREPAID_SUBSCRIPTION = gql`
  ${FRAGMENTS}

  subscription MetricsRedeemedPrepaidSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      redeemedPrepaid {
        ...RedeemedPrepaidsFragment
      }
    }
  }
`;

export const METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_QUERY = gql`
  ${FRAGMENTS_WITH_BREAKDOWN}

  query MetricsRedeemedPrepaidWithBreakdownQuery(
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
    ) {
      redeemedPrepaid {
        ...RedeemedPrepaidsWithBreakdownFragment
      }
    }
  }
`;

export const METRICS_REDEEMED_PREPAID_WITH_BREAKDOWN_SUBSCRIPTION = gql`
  ${FRAGMENTS_WITH_BREAKDOWN}

  subscription MetricsRedeemedPrepaidWithBreakdownSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      redeemedPrepaid {
        ...RedeemedPrepaidsWithBreakdownFragment
      }
    }
  }
`;
