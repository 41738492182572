import { useEffect, useState } from 'react';
import { Button } from 'react-md';
import { CurrencyInputField, TextInput } from '../';
import { useCashiersContext } from '../../hooks';

const CashCountForm = () => {
  const {
    currentFormData,
    selectedTransaction: { bagId = '', expectedCashOnHand = 0, remainder = 0, transactionKey = '' } = {},
    setActiveForm,
    setCurrentDialog,
    setCurrentFormData
  } = useCashiersContext();

  const startingEditableValues = {
    bagId: currentFormData?.bagId || bagId,
    cashCollected: currentFormData?.cashCollected || ''
  };

  const [cashCollected, setCashCollected] = useState(startingEditableValues.cashCollected);
  const [updatedBagId, setupdatedBagId] = useState(startingEditableValues.bagId);
  const [formStatus, setFormStatus] = useState('active');

  useEffect(() => setActiveForm('cash count'), [setActiveForm]);

  const submitForm = e => {
    e.preventDefault();
    setFormStatus('submitting');
    setCurrentFormData({
      bagId: updatedBagId,
      cashCollected,
      expectedCashOnHand,
      remainder,
      transactionKey
    });
    setCurrentDialog('form review');
  };

  return (
    <form className="modal-form" onSubmit={submitForm}>
      <CurrencyInputField
        cents={expectedCashOnHand ?? 0}
        label="Expected Cash on Hand"
        readonly
        required
        tooltipText="Expected Cash on Hand includes any bank amount left with the cashier."
      />
      <CurrencyInputField
        cents={remainder ?? 0}
        label="Cash Left with Cashier"
        placeholder="$0"
        readonly
        required
      />
      <CurrencyInputField
        cents={cashCollected}
        handleChange={setCashCollected}
        label="Cash Collected Amount"
        placeholder="$0"
        required
      />
      <TextInput handleChange={setupdatedBagId} label="Bag ID" value={updatedBagId} />
      <p className="modal-form-note">
        <sup>*</sup> Required fields
      </p>
      <Button
        disabled={formStatus !== 'active'}
        theme="primary"
        themeType="contained"
        className="modal-form-submit-button"
        type="submit"
      >
        Add Cash Count
      </Button>
    </form>
  );
};

export default CashCountForm;
