import { PersonIconCircled } from '../../assets/react-icons';
import { useMetricListContext, useOverviewSectionsContext, useCashiersContext } from '../../hooks';
import ChartCard from '../ChartCard/ChartCard';
import DialogButton from '../DialogButton/DialogButton';
import EmptyChart from '../EmptyChart/EmptyChart';
import CashiersDialogs from './CashiersDialogs';
import CashiersMetricList from './CashiersMetricList';
import './CashierCard.scss';

const CashiersCard = () => {
  const { setCurrentDialog, setExpandedVisible } = useCashiersContext();
  const { textLength } = useMetricListContext();
  const { cashiers = [], cashiersLoading } = useOverviewSectionsContext();

  function handleClick() {
    setCurrentDialog('expanded view');
    setExpandedVisible(true);
  }

  return (
    <>
      <ChartCard
        loading={cashiersLoading}
        className="cashier-card cashiers__pendo"
        data-id="cashiers__pendo"
        label="Cashiers"
        type="list"
        icon={<PersonIconCircled width="24" color="#2196F3" />}
      >
        {cashiers?.length ? (
          <>
            <CashiersMetricList listLimit={5} textLength={textLength} />
            <div className="chart-card-action-container">
              {cashiers.length >= 1 && (
                <DialogButton
                  label="View all cashiers"
                  onClick={handleClick}
                />
              )}
            </div>
          </>
        ) : (
          !cashiersLoading && <EmptyChart center />
        )}
        <CashiersDialogs />
      </ChartCard>
    </>
  );
};

export default CashiersCard;
