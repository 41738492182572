import { gql } from '@apollo/client';

const LOT_FOCUS_FRAGMENT = gql`
  fragment LotFocusFragment on LotFocus {
    lotTransactionTypes {
      value
      transactionTypes {
        name
        parked
        revenue
        subsegment {
          revenue
          parked
          name
        }
      }
    }
    lotPricings {
      value
      pricings {
        name
        parked
        revenue
        subsegment {
          revenue
          parked
          name
        }
      }
    }
    parkingFrom
    parkingTo
    cashRevenue
    cashiers
    eventNames
    eventsFrom
    eventsTo
    landmarkNames
    lat
    lon
    onSiteRevenue
    successfulTransactions
    value
  }
`;

const BASE_LOT_OVERVIEWS_FRAGMENT = gql`
  fragment LotOverviewsFragment on LotOverview {
    id
    name
    value
    parked
    utilization
    totalSpots
    totalRevenue
  }
`;

export const METRICS_LOT_OVERVIEWS_QUERY = gql`
  ${BASE_LOT_OVERVIEWS_FRAGMENT}

  query MetricsLotOverviewsQuery(
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $keyword: String
    $limit: Int
    $transactionId: ID
    $landmarks: [ID]
    $offset: Int
    $order: SortOrder
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
      transactionId: $transactionId
    ) {
      lotOverviews {
        ...LotOverviewsFragment
      }
    }
  }
`;

export const METRICS_LOT_OVERVIEWS_SUBSCRIPTION = gql`
  ${BASE_LOT_OVERVIEWS_FRAGMENT}

  subscription MetricsLotOverviewsSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    $changedOnly: Boolean
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
      changedOnly: $changedOnly
    ) {
      lotOverviews {
        ...LotOverviewsFragment
      }
    }
  }
`;

export const METRICS_LOT_FOCUS_QUERY = gql`
  ${BASE_LOT_OVERVIEWS_FRAGMENT}
  ${LOT_FOCUS_FRAGMENT}

  query MetricsLotFocusQuery(
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $keyword: String
    $limit: Int
    $transactionId: ID
    $landmarks: [ID]
    $offset: Int
    $order: SortOrder
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
      transactionId: $transactionId
    ) {
      lotOverviews {
        ...LotOverviewsFragment
        lotFocus {
          ...LotFocusFragment
        }
      }
    }
  }
`;

export const METRICS_LOT_FOCUS_SUBSCRIPTION = gql`
  ${BASE_LOT_OVERVIEWS_FRAGMENT}
  ${LOT_FOCUS_FRAGMENT}

  subscription MetricsLotFocusSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    $changedOnly: Boolean
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
      changedOnly: $changedOnly
    ) {
      lotOverviews {
        ...LotOverviewsFragment
        lotFocus {
          ...LotFocusFragment
        }
      }
    }
  }
`;
