import { useCashiersContext } from '../../hooks';
import { centsToCurrency, localTimeFromDate } from '../../utils';
import AddCountButton from './AddCountButton';

const CashDropItems = () => {
  const { cashLogs = {} } = useCashiersContext();
  const cashDrops = cashLogs?.cashDrops || [];
  const cashOut = cashLogs?.cashOut || {};

  return (
    cashDrops &&
    cashDrops.map((drop, index) => {
      const {
        amount = 0,
        bagId = '',
        comments = '',
        modified,
        counterDisplayName = '',
        remainder = 0,
        transactionKey = '',
        discrepancy = 0,
        expectedCashOnHand = 0
      } = drop;
      const isEditable = !amount && !cashOut;

      const comment = `Comment: ${comments || 'No comment provided'}`;

      const dropDetails = [
        `${centsToCurrency(amount)} was taken.`,
        `${centsToCurrency(expectedCashOnHand)} was expected.`,
        `${centsToCurrency(remainder)} was left with cashier.`,
        `${centsToCurrency(discrepancy)} difference.`,
        `Bag ID: ${bagId || 'No bag ID provided'}`
      ];
      const details = [...(amount ? dropDetails : ['No amount entered']), comment];

      return (
        <li className="cash-management-item" key={`${transactionKey}-${index}`}>
          <p className="cash-management-action">
            <b>{counterDisplayName}</b> performed a cash pickup.
          </p>
          <time className="cash-management-time" dateTime={modified}>
            {localTimeFromDate(modified)}
          </time>
          <ul className="cash-management-details">
            {details.map((detail, detailIndex) => (
              <li className="cash-management-details-item" key={`${transactionKey}-detail-${detailIndex}`}>
                {detail}
              </li>
            ))}
          </ul>
          {isEditable && <AddCountButton transaction={drop} />}
        </li>
      );
    })
  );
};

export default CashDropItems;
