import { useState } from 'react';
import { Modal, SearchInput } from '../';
import { useLayoutContext } from '../../hooks';
import { FUSE_OPTIONS } from '../../utils';
import MobileList from '../MobileList/MobileList';
import sortBy from 'lodash/sortBy';
import Fuse from 'fuse.js';
import './Exemptions.scss';

const ExpandedView = ({ visible, setVisible, data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEvent, setSelectedEvent] = useState();
  const [secondaryColumn, setSecondaryColumn] = useState('totalExemptionCount');
  const { windowSize } = useLayoutContext();
  const mobile = windowSize < 600;
  const [currentStep, setCurrentStep] = useState(mobile ? 'table' : 'list');

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  function handleOnClose(e) {
    e.stopPropagation();
    setSelectedEvent();
    setSearchTerm('');
    setVisible(false);
  }

  function handleRowClick(event) {
    setCurrentStep('list');
    setSelectedEvent(event);
  }

  function createEventsList() {
    const options = { ...FUSE_OPTIONS, keys: ['eventName'] };
    const fuse = new Fuse(data, options);
    const results = searchTerm.length > 0 ? fuse.search(searchTerm).map(result => result.item) : data;

    return sortBy(results, 'eventName', 'asc');
  }

  const events = selectedEvent && mobile ? data.filter(({ eventName }) => eventName === selectedEvent.eventName) : createEventsList();

  const body = events.map((event) => {
    const { eventName = '', exemptionTypes = [], totalExemptionCount = 0 } = event;

    return {
      eventName: {
        displayValue: eventName || '',
        sortValue: eventName || ''
      },
      exemptionTypes: {
        displayValue: exemptionTypes.length,
        sortValue: exemptionTypes.length
      },
      totalExemptionCount: {
        displayValue: totalExemptionCount,
        sortValue: totalExemptionCount
      },
      rowData: event
    };
  });

  const head = [
    {
      id: 'eventName',
      label: 'Event Name',
      sortable: true
    },
    {
      id: 'exemptionTypes',
      label: 'Exemption Types',
      sortable: true
    },
    {
      id: 'totalExemptionCount',
      label: 'Total Exemption Count',
      sortable: true
    }
  ];

  const menuItems = [
    { value: 'exemptionTypes', label: 'Exemption Types Count' },
    { value: 'totalExemptionCount', label: 'Total Exemption Count' }
  ];

  function handleGoBack() {
    setSelectedEvent();
    setCurrentStep('table');
  }

  const allowGoBack = mobile && currentStep === 'list' ? handleGoBack : null;
  const headline = mobile && selectedEvent ? selectedEvent?.eventName : 'Exemptions';

  return (
    <Modal
      id={currentStep === 'table' ? 'expanded-lots-view-table' : 'expanded-lots-view-modal'}
      visible={visible}
      closeModal={handleOnClose}
      headline={headline}
      subhead="Total of successful exempt transactions (includes VIP parkers) segmented by exemption reason."
      goBack={allowGoBack}
    >
      <SearchInput
        placeholder="Search events"
        onChange={handleSearch}
        value={searchTerm}
      />
      {mobile && currentStep === 'table' && (
        <MobileList
          className="mobile-lots-table"
          columns={{ mainColumn: 'eventName', secondaryColumn }}
          data={{ body, head }}
          hasNextStep
          onRowClick={handleRowClick}
          menuItems={menuItems}
          setColumn={setSecondaryColumn}
        />
      )}
      {(currentStep === 'list' || !mobile) && events.map((event, index) => {
        const { eventName = '', exemptionTypes = [], totalExemptionCount = 0 } = event;

        return (
          <div className="exemptions-event-container" key={eventName + index}>
            <p className="exemptions-event-header">{event.eventName}</p>
            <div className="exemptions-data-header">
              <p>Exemption Type</p>
              <p>Total Count</p>
            </div>
            <div className="exemptions-data-container">
              <ul>
                <li>Total</li>
                <li>{totalExemptionCount}</li>
              </ul>
              {exemptionTypes.map(({ count, type }) => (
                <ul key={type + type + index}>
                  <li>{type}</li>
                  <li>{count}</li>
                </ul>
              ))}
            </div>
          </div>
        );
      })}
    </Modal>
  );

};

export default ExpandedView;
