import { createContext, useContext, useState } from 'react';
import { useOverviewSectionsContext } from '../../hooks';
import { isNotEmpty } from '../../utils';
import './MetricList.scss';

const MetricListContext = createContext([{}, () => { }]);

export const MetricListContextProvider = ({ children }) => {
  const defaultDialog = {
    dialogTitle: '',
    pageX: null,
    pageY: null,
    dialogComponent: ''
  };
  const { cashiers = [] } = useOverviewSectionsContext();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialog, setDialog] = useState(defaultDialog);
  const listLimit = 7;
  const animationTimeout = 300;
  const textLength = 12;
  const textLengthExpanded = 100;

  const hideDialog = () => {
    setDialog(defaultDialog);
    setDialogVisible(false);
  };

  function showDialog(e, dialogComponent, dialogTitle) {
    let { pageX, pageY } = e;

    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    setDialogVisible(true);

    setDialog({
      dialogTitle,
      dialogComponent,
      pageX,
      pageY
    });
  }

  function createCashiers(cashierOverviews) {
    if (isNotEmpty(cashierOverviews)) {

      function handleCashier(cashiersList) {
        const lowBattery = cashiersList?.filter(({ battery, sledBattery }) => (battery > 0 && battery <= 30) || (sledBattery > 0 && sledBattery <= 30)) || [];
        const acceptableBattery = cashiersList?.filter(({ battery, sledBattery }) => (battery >= 30 || !battery) && (sledBattery >= 30 || !sledBattery)) || [];

        return [
          ...lowBattery.sort((a, b) => a?.sledBattery > b?.sledBattery ? 1 : -1),
          ...acceptableBattery.sort((a, b) => a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1)
        ];
      }

      return handleCashier(cashierOverviews);
    }

    return [];
  }

  const cashiersResult = createCashiers(cashiers);

  return (
    <MetricListContext.Provider
      value={{
        animationTimeout,
        cashiers: cashiersResult,
        dialogVisible,
        setDialogVisible,
        dialog,
        setDialog,
        hideDialog,
        listLimit,
        showDialog,
        textLength,
        textLengthExpanded
      }}
    >
      {children}
    </MetricListContext.Provider>
  );
};

export const useMetricListContext = () => useContext(MetricListContext);
