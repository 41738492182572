import { createContext, useContext, useState } from 'react';

const TransactionContext = createContext([{}, () => {}]);

export const TransactionContextProvider = ({ children }) => {
  // Possible current views: 'card', 'expanded', or 'single transaction'
  const [currentView, setCurrentView] = useState('card');
  const [selectedTransaction, setSelectedTransaction] = useState();

  function closeModal() {
    setCurrentView('card');
    setSelectedTransaction();
  };

  function openModal() {
    setCurrentView('expanded');
  }

  return (
    <TransactionContext.Provider
      value={{
        closeModal,
        currentView,
        setCurrentView,
        openModal,
        selectedTransaction,
        setSelectedTransaction
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransactionContext = () => useContext(TransactionContext);
