import { Dialog, Button, FontIcon } from 'react-md';
import { useOverviewSectionsContext } from '../../hooks';
import './FeatureInDevelopmentModal.scss';
import { CodeIcon } from '../../assets/react-icons';

const FeatureInDevelopmentModal = () => {
  const { featureInDevelopmentModalConfig, setFeatureInDevelopmentModalConfig } = useOverviewSectionsContext();

  function closeModal() {
    setFeatureInDevelopmentModalConfig({ visible: false, title: '', description: '' });
  }

  return (
    <Dialog
      id="feature-in-development-dialog"
      visible={featureInDevelopmentModalConfig.visible}
      onRequestClose={closeModal}
      aria-labelledby="feature-in-development-dialog"
    >
      <Button
        className="modal-close-button"
        buttonType="icon"
        aria-label="close"
        onClick={closeModal}
      >
        <FontIcon>close</FontIcon>
      </Button>
      <h3 className="modal-headline">
        {featureInDevelopmentModalConfig.title}
      </h3>
      <h4 className="modal-subheadline">
        {featureInDevelopmentModalConfig.description}
      </h4>
      <div className="feature-in-development-modal-content">
        <CodeIcon color="#bbb" />
        <p>We’re working on building this functionality, stay tuned!</p>
      </div>
    </Dialog>
  );
};

export default FeatureInDevelopmentModal;
