import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GraphBarCell, GraphBarDetails } from '../';

const calculateWidth = (width, total) => (((width >= 0 ? width : 0) / (total ? total : 1)) * 100);

const calculateTotal = (key, data) => {
  let total = 0;

  data.forEach(item => {
    total += item[key];
  });

  return total;
};

const PrepaidsGraphBar = ({
  data = [],
  formatter = ((value) => value),
  valueLeftKey = '',
  valueRightKey = '',
  valueLabel = '',
  indexKey = '',
  totalValue,
  defaultSubsegmentLabel,
  hideUnknownSegment
}) => {
  const TOTAL_SOLD = calculateTotal('sold', data);
  const TOTAL_REDEEMED = calculateTotal('redeemed', data);
  const TOTAL_UNREDEEMED = calculateTotal('unredeemed', data);

  let CELLS;

  if (data?.length <= 49) {
    CELLS = data;

  } else {
    CELLS = [{
      key: 'TOTAL-METRICS',
      name: 'Total',
      sold: TOTAL_SOLD,
      redeemed: TOTAL_REDEEMED,
      unredeemed: TOTAL_UNREDEEMED,
      subsegment: []
    }];
  }

  const cellData = useMemo(() => {
    return CELLS.map(element => {
      return {
        key: element.key,
        width: calculateWidth(element.redeemed + element.unredeemed, totalValue),
        cells: [{
          key: `${element.key}-redeemed`,
          value: element.redeemed,
          title: `${element[valueLabel]} - ${element.redeemed < 0 ? 0 : formatter(element.redeemed)} passes redeemed`,
          width: calculateWidth(element.redeemed, element.redeemed + element.unredeemed)
        }, {
          key: `${element.key}-unredeemed`,
          value: element.unredeemed,
          title: `${element[valueLabel]} - ${element.unredeemed < 0 ? 0 : formatter(element.unredeemed)} passes unredeemed`,
          width: calculateWidth(element.unredeemed, element.redeemed + element.unredeemed)
        }]
      };
    });
  }, [CELLS, totalValue, valueLabel, formatter]);

  return (
    <div className="graph-bar">
      <div className="graph-bar-chart">
        {cellData.map((item, index) => (
          <div
            key={`${item.key}-${indexKey}-${index}`}
            className="graph-bar-chart-cell-container"
            style={{
              width: `${item.width}%`
            }}
          >
            {item.cells.map((cell, idx) => (
              <Fragment key={`${cell.key}-${idx}`}>
                <GraphBarCell
                  item={cell}
                  index={index}
                  title={cell.title}
                  width={cell.width}
                  opacity={idx === 1 ? '.2' : undefined}
                  group
                />
              </Fragment>
            ))}
          </div>
        ))}
      </div>
      <GraphBarDetails
        data={data}
        valueLeftKey={valueLeftKey}
        valueLabel={valueLabel}
        valueRightKey={valueRightKey}
        formatter={formatter}
        showRightColumn
        defaultSubsegmentLabel={defaultSubsegmentLabel}
        hideUnknownSegment={hideUnknownSegment}
        debug
        header={
          <>
            <dt className="graph-bar-details-label" />
            <dd className="graph-bar-details-value graph-bar-details-header double-col">
              <div>Redeemed</div>
              <div>Sold</div>
            </dd>
            <dt className="graph-bar-details-label graph-bar-details-label-total">
              Total passes
            </dt>
            <dd className="graph-bar-details-value double-col">
              <div>
                {formatter(TOTAL_REDEEMED)}
              </div>
              <div>
                {formatter(TOTAL_SOLD)}
              </div>
            </dd>
          </>
        }
      />
    </div>
  );
};

PrepaidsGraphBar.propTypes = {
  data: PropTypes.array,
  defaultSubsegmentLabel: PropTypes.string,
  formatter: PropTypes.func,
  hideUnknownSegment: PropTypes.bool,
  valueLabel: PropTypes.string,
  valueLeftKey: PropTypes.string,
  valueRightKey: PropTypes.string
};

export default PrepaidsGraphBar;
