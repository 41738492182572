import { CashiersCard, LotsGaragesCard, TransactionsCard } from '../../';
import { CashiersContextProvider } from '../../CashiersCard/CashiersContext';
import { MetricListContextProvider } from '../../MetricList/MetricListContext';
import { TransactionContextProvider } from '../../TransactionsCard/TransactionContext';

const Section3 = () => (
  <MetricListContextProvider>
    <div className="chart-section-3">
      <CashiersContextProvider>
        <CashiersCard />
      </CashiersContextProvider>
      <LotsGaragesCard />
      <TransactionContextProvider>
        <TransactionsCard />
      </TransactionContextProvider>
    </div>
  </MetricListContextProvider>
);

export default Section3;
