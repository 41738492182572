import { useEffect, useState } from 'react';
import { useCashiersContext } from '../../hooks';
import CashOutForm from './CashOutForm';
import CashOutNotice from './CashOutNotice';

const CashOutModal = () => {
  const { cashOutNoticeConfirmed } = useCashiersContext();

  // Options: 'notice' or 'form'
  const [cashOutView, setCashOutView] = useState('notice');

  useEffect(() => (cashOutNoticeConfirmed ? setCashOutView('form') : setCashOutView('notice')), [
    cashOutNoticeConfirmed
  ]);

  const cashOutContent = {
    form: <CashOutForm />,
    notice: <CashOutNotice />
  };

  return cashOutContent[cashOutView];
};

export default CashOutModal;
