import Highcharts from 'highcharts';
import { HighchartsChart, Chart, HighchartsProvider, Title, PieSeries, Subtitle, Tooltip } from 'react-jsx-highcharts';
import { CHART_COLORS } from '../../utils';
import './DonutChart.scss';

const DonutChart = ({ height = 120, colors, data, name, subtitle, total, tooltip = {} }) => {
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart chart={{ height }} className="donut-chart">
        <Chart />
        <Tooltip
          backgroundColor="white"
          formatter={tooltip.formatter}
          useHTML={tooltip.useHTML}
          pointFormatter={tooltip.pointFormatter}
        />
        <Title
          align="center"
          className="donut-title"
          verticalAlign="middle"
          y={-5}
          style={{
            fontSize: '18px'
          }}
        >
          {total}
        </Title>
        <Subtitle
          align="center"
          className="donut-subtitle"
          verticalAlign="middle"
          y={15}
          style={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, .55)'
          }}
        >
          {subtitle || ''}
        </Subtitle>
        <PieSeries
          borderWidth={0}
          center={['50%', '50%']}
          colors={colors ? colors : CHART_COLORS}
          data={data}
          dataLabels={{ enabled: false }}
          depth={3}
          id="pie-series"
          innerSize="85%"
          name={name}
          size={height - 6}
        />
      </HighchartsChart>
    </HighchartsProvider>
  );
};

export default DonutChart;
