import { Button } from 'react-md';
import { useCashiersContext } from '../../hooks';

const AddCountButton = ({ transaction = {} }) => {
  const { setCurrentDialog, setSelectedTransaction } = useCashiersContext();

  const selectTransaction = () => {
    setSelectedTransaction(transaction);
    setCurrentDialog('cash count');
  };

  return (
    <Button
      className="button-blue-light"
      theme="primary"
      themeType="contained"
      onClick={selectTransaction}
    >
      Add Count
    </Button>
  );
};

export default AddCountButton;
