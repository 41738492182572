import _ from 'lodash';
import moment from 'moment';

export * from './authentication';
export * from './fuseOptions';
export * from './chartconfig';
export * from './currency';
export * from './currencyInput';
export * from './string';
export * from './time';

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// sort by key
export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

// truncate string with max character count
export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

export const idFormatter = str => str.toLowerCase().split(' ').join('-');

// number formatting
export const numberWithCommas = x => {
  const num = Number.isInteger(x) ? x : parseFloat(x.toFixed(1));

  return `${num.toLocaleString()}`;
};

export const currencyFormat = x => {
  const num = parseFloat(x.toFixed(0) / 100);

  return `$${num.toLocaleString()}`;
};

export function percentFormat(value, numOfDigits) {
  const digits = numOfDigits === 0 || numOfDigits ? numOfDigits : Number.isInteger(value) ? 0 : 1;

  return parseFloat(value).toFixed(digits) + '%';
}

export function ISOtoMilli(isoDate) {
  // Parse the date with explicit format for cross browser compatibility
  return moment(isoDate, 'YYYY-MM-DD HH:mm:ss Z').format('x');
};

export const userTimezoneName = new Intl.DateTimeFormat('default', { timeZoneName: 'short' })
  .format(new Date())
  .split(',')[1]
  .padStart();

export function localTimeFromDate(dateStr) {
  return new Date(dateStr).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }).toLowerCase();
}

export const minutesToMilli = (minutes = 0) => minutes * 60000;

export const userTimezoneOffsetMilli = minutesToMilli(new Date().getTimezoneOffset());

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');

  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
}

export function limitListSize(data, limit) {
  return data.length > limit ? data.slice(0, limit) : data;
}
