import { Button, ChevronRightSVGIcon } from 'react-md';
import { useCashiersReprintsContext } from '../../hooks';

const CashierSelect = ({ id, name, reprints }) => {
  const { setCurrentView, setCashierId, setCashierName, setCashierReprints } = useCashiersReprintsContext();

  function handleClick() {
    setCashierName(name);
    setCashierId(id);
    setCashierReprints(reprints);
    setCurrentView('cashier selected');
  };

  return (
    <Button
      className="icon-button"
      onClick={handleClick}
      buttonType="icon"
      theme="secondary"
      aria-label="navigate"
    >
      <ChevronRightSVGIcon />
    </Button>
  );
};

export default CashierSelect;
