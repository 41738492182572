import { getBarColor, hexToRGB } from '../../utils';
import './OnSiteRevenueGraphBar.scss';

const OnSiteRevenueGraphBar = ({ data, display }) => {
  const titleDescription = display === 'revenue' ? 'value' : 'count';

  function calculateWidth(width, total) {
    return (((width >= 0 ? width : 0) / total) * 100);
  }

  function createCells() {
    let total = display === 'revenue' ? data.value : data.count;
    let displayKey = display === 'revenue' ? 'value' : 'count';

    if (data.revenueByPricing.length >= 49) {
      return [{
        width: 100,
        key: 'TOTAL-METRICS',
        cashWidth: calculateWidth(data.cash.value, total),
        creditWidth: calculateWidth(data.credit.value, total),
        paymentWidth: calculateWidth(data.payment.value, total)
      }];
    } else {
      return data.revenueByPricing.map(element => {
        return {
          width: calculateWidth(element[displayKey], total),
          key: element.name,
          cashWidth: calculateWidth(element.cash[displayKey], element[displayKey]),
          creditWidth: calculateWidth(element.credit[displayKey], element[displayKey]),
          paymentWidth: calculateWidth(element.payment[displayKey], element[displayKey])
        };
      });
    }
  }

  const CELLS = createCells();

  return (
    <div id="on-site-revenue-graph-bar">
      {CELLS.map((cell, index) => (
        <div
          key={`${cell.key}-${index}`} className="cell-type-container"
          style={{
            width: `${cell.width}%`
          }}
        >
          <div
            className="revenue-cell cash-cell"
            title={`${cell.key} cash ${titleDescription}`}
            style={{
              flexBasis: `${cell.cashWidth}%`,
              backgroundColor: getBarColor('', index).backgroundColor,
              borderColor: getBarColor('', index).backgroundColor,
              borderWidth: cell.cashWidth < 1 && '0px'
            }}
          />
          <div
            className="revenue-cell credit-cell"
            title={`${cell.key} credit ${titleDescription}`}
            style={{
              flexBasis: `${cell.creditWidth}%`,
              backgroundColor: hexToRGB(getBarColor('', index).backgroundColor, .65),
              borderColor: getBarColor('', index).backgroundColor,
              borderWidth: cell.creditWidth < 1 && '0px'
            }}
          />
          <div
            className="revenue-cell payment-cell"
            title={`${cell.key} payment ${titleDescription}`}
            style={{
              flexBasis: `${cell.paymentWidth}%`,
              backgroundColor: hexToRGB(getBarColor('', index).backgroundColor, .25),
              borderColor: getBarColor('', index).backgroundColor,
              borderWidth: cell.paymentWidth < 1 && '0px'
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default OnSiteRevenueGraphBar;
