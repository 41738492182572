const QRcodeIcon = props => (
  <svg fill="none" viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="M5.64 8.55h.01s-.01 0 0 0Zm6.72 1.83V8.53h.92v-.95h-1.9v1.9H9.5V6.61h-.96v.98h-2.9v.96H7.6v.97h.92v.9H7.6v3.85h.93v-1.95h1v-.97h.88v-.92h.97v1.88h.97v-.95h.95v-.98h-.94Zm-.98-2.8v-.02h-.02v.01h.02Zm1.92
      3.78v.01h.01ZM20 0v6.58h-6.66V0H20Zm-.97.88H14.3V5.6h4.72V.88ZM0 13.32h6.56v6.67H.01v-6.67Zm.87 5.7H5.6V14.3H.88v4.71Zm5.7-12.44H.01V0h6.57v6.58ZM5.61.88H.89V5.6h4.72V.88Zm2.9 14.28h.02l-.01-.01Zm-.9
      4.83h1.9v-.92h-.02v-.02h-.92v-.97h.95v-.95h.85v.94h-.85v.01H9.5v.98h1.88v.92h1.9v-1.91h-.96v-2.88h-.96v.91H8.52v-.94H7.6v4.83ZM4.73 7.6H0v1.9h.86v-.93h2.93v.95h.96v.85h1.82v-.88h-.93v-.93h-.9v-.97Zm.91.97v-.01Zm5.73-6.74h.93V.9h-1.93v1.9H7.6v.95h3.78V1.82Zm6.68
      10.54h-.96v1.93h-.93v.9h.95v.94h.95v-.98h.96V13.3h-.97v-.94Zm-1.91 4.72v.02-.02Zm-1-.96v-.92h-1.82v.95h.97v.93h1.85v-.96h-1Zm1-5.7h.97v.92h.97v-.96h.94V9.5l-2.89.02v.9Zm2.88-.93v.01-.01Zm-2.9.02h.02-.02Zm-14.3
      1.85v.01ZM0 10.41v.95h1.82v-.98h.95v-.87H.87v.9H0Zm.87-.9H.86Zm15.26 0h.02-.02Zm-.98-.96v-.96h-.85v2.78h.88V9.5h.95v-.95h-.98ZM1.84 11.36l-.01.01Zm2.87.95v-.94H1.84v.94H4.7Zm13.35 4.78Zm-1.91 0Zm0
      .99h.97v.96h.95l-.01-1.94h-1.9v.98Zm-.04-4.8V11.4h-.96v.96h-.88v.93h1.84Zm-1.85-.92h.01v-.01l-.01.01Zm-2.88-5.77h.93V4.77h-1.9v.85h.96v.97Zm-.97-.97h-.02v.01h.02ZM9.48.9H7.6v.93h1.88V.9Zm1.92
      13.36h1.89v-.92h-1.9v.92ZM7.6 4.77v1.82h.94V4.77H7.6Zm.94 1.82v.02-.02ZM14.3 20h.84v-1.9h-.84V20Zm3.78-.95v-.01.01Zm.94.94v-.94h-.94v.94h.94Zm-.94-3.85v-.01.01Zm.94.95v-.95h-.94l-.01.95h.95ZM10.4
      7.56h.95V6.6h-.95v.96Zm.95-.96h.02-.02Zm7.71 5.71h.92v-.92h-.92v.92Zm-4.81.04v-.98h-.95v.98h.95Zm-.96-.98h.01Zm-7.65.95h.93v-.93h-.93v.93Zm10.5-3.79h-.02v.02h.01v-.02Zm.93-.93h-.94v.93h.94V7.6Zm1.03
      0v.93h.93V7.6h-.93Zm.93.93v.01h.01Zm.95.01h-.94l-.01.95h.95v-.95Zm-10.51 6.6v-.86h-.93l-.02.87h.95Zm-.93-.86v-.01h-.02v.01h.02Zm-.02.88v-.01h-.01.01Zm1.86-9.53h-.87v.96h.87v-.96Zm-.87.96H9.5v.01h.02Zm.87
      0v.01h.02-.02Zm7.67-4.74h-2.87v2.86h2.87V1.85Zm-16.2 16.2H4.7V15.2H1.85v2.86ZM4.7 1.87H1.85v2.86h2.86V1.86Z" />
  </svg>
);

export default QRcodeIcon;
