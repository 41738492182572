import React from 'react';

const ReprintIcon = props => (
  <svg width="18" height="16" viewBox="0 0 18 16" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8334 4.66666H3.16675C1.78341 4.66666 0.666748 5.78333 0.666748 7.16666V12.1667H4.00008V15.5H14.0001V12.1667H17.3334V7.16666C17.3334 5.78333 16.2167 4.66666 14.8334 4.66666ZM12.3334 13.8333H5.66675V9.66666H12.3334V13.8333ZM14.8334 8C14.3751 8 14.0001 7.625 14.0001 7.16667C14.0001 6.70834 14.3751 6.33334 14.8334 6.33334C15.2917 6.33334 15.6667 6.70834 15.6667 7.16667C15.6667 7.625 15.2917 8 14.8334 8ZM14.0001 0.5H4.00006V3.83333H14.0001V0.5Z"
    />
  </svg>
);

export default ReprintIcon;
