import { useEffect, useState } from 'react';
import { Button } from 'react-md';
import { CurrencyInputField, TextInput } from '../';
import { useCashiersContext } from '../../hooks';

const CashOutForm = () => {
  const {
    cashLogs: { cashDrops, cashFromDrops = 0, expectedCashOnHand = 0, initialBank = 0, totalCashCollected = 0 } = {},
    currentFormData,
    setActiveForm,
    setCurrentDialog,
    setCurrentFormData
  } = useCashiersContext();

  const startingEditableValues = {
    bagId: currentFormData?.bagId || '',
    cashToTake: currentFormData?.cashToTake || 0,
    comments: currentFormData?.comments || '',
    initialBank: currentFormData?.initialBank || initialBank
  };

  const [bagId, setBagId] = useState(startingEditableValues.bagId);
  const [cashToTake, setCashToTake] = useState(startingEditableValues.cashToTake);
  const [comments, setComments] = useState(startingEditableValues.comments);
  const [expectedCash, setExpectedCash] = useState(expectedCashOnHand);
  const [formStatus, setFormStatus] = useState('active');
  const [updatedInitialBank, setUpdatedInitialBank] = useState(startingEditableValues.initialBank);

  useEffect(() => setActiveForm('cash out'), [setActiveForm]);
  useEffect(() => {
    if (initialBank === updatedInitialBank) {
      return;
    } else {
      setExpectedCash(expectedCashOnHand + updatedInitialBank);
    }
  }, [expectedCashOnHand, initialBank, updatedInitialBank]);

  const submitForm = e => {
    e.preventDefault();
    setFormStatus('submitting');
    setCurrentFormData({
      bagId,
      bankCollected: cashToTake > updatedInitialBank ? updatedInitialBank : cashToTake,
      cashFromDrops,
      cashToTake,
      comments,
      expectedCashOnHand: expectedCash,
      initialBank: updatedInitialBank,
      totalCashCollected
    });
    setCurrentDialog('form review');
  };

  return (
    <form className="modal-form" onSubmit={submitForm}>
      <CurrencyInputField
        cents={updatedInitialBank ?? 0}
        handleChange={setUpdatedInitialBank}
        label="Initial Bank Amount"
        placeholder="$0"
        readonly={!!cashDrops}
        required={!cashDrops}
        tooltipText="Initial bank left with the cashier for this event"
      />
      <CurrencyInputField
        cents={expectedCash ?? 0}
        label="Expected Cash on Hand"
        placeholder="$0"
        readonly
        tooltipText="Expected cash on hand is sum of cash transactions since last cash drop plus any cash left with cashier in the previous drop."
      />
      <CurrencyInputField
        cents={totalCashCollected ?? 0}
        label="Expected Total Cash Revenue"
        placeholder="$0"
        readonly
        tooltipText="Expected Cash Revenue is calculated based on transactions, and it doesn't include bank amount."
      />
      <CurrencyInputField
        cents={cashFromDrops}
        label="Cash Counted from Drops"
        placeholder="$0"
        readonly
        tooltipText="Total Cash Counted amount reported for cash drops. It may not include cash counts for all cash drops. View Audit Log for more details."
      />
      <CurrencyInputField
        allowZero={false}
        cents={cashToTake}
        handleChange={setCashToTake}
        label="Total Cash for Cash Out (Includes bank amount)"
        placeholder="$0"
        required
        tooltipText="Cash to be collected. It includes cash counts from previous drops and includes initial bank amount."
      />
      <TextInput handleChange={setBagId} label="Bag ID" value={bagId} />
      <TextInput handleChange={setComments} label="Comments" placeholder="..." value={comments} />
      <p className="modal-form-note">
        <sup>*</sup> Required fields
      </p>
      <Button
        disabled={formStatus !== 'active'}
        theme="primary"
        themeType="contained"
        className="modal-form-submit-button"
        type="submit"
      >
        Add Cash Out
      </Button>
    </form>
  );
};

export default CashOutForm;
